import { FormGroup, Form, } from "reactstrap";
import React /*useState*/ from "react";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";

export const NewFicha = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="modal-sm">
      <div className="container-fluid">
        <Modal
          isOpen={true}
          contentLabel="Create News Modal"
          ariaHideApp={false}
          overlayClassName="Overlay"
        >
          <Form role="form" className="user">
            <div className="card shadow mb-4">
              <div className="btn-modal-close" onClick={closeModal}>
                <IoIosClose />{" "}
              </div>
              
              <div className="card-header py-3">
                <h5 className="m-0 font-weight-bold text-primary">
                  {" "}
                  Ficha Caminante{" "}
                </h5>
              </div>
              <div className="card-body">
                <FormGroup className="form-group">
                  <div className="row mb-4">
                    <div className="col-xs-6 col-sm-6">
                      <label htmlFor="exampleFormControlInput1">
                        Nombre Completo:
                      </label>
                      <input
                        className="form-control form-control-solid"
                        id="nombreInput"
                        type="text"
                      />
                    </div>
                    <div className="col-xs-6 col-sm-6">
                      <label htmlFor="exampleFormControlInput1">Rut:</label>
                      <input
                        className="form-control form-control-solid"
                        id="nombreInput"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-xs-4 col-sm-4">
                      <label htmlFor="exampleFormControlInput1">
                        Dirección:
                      </label>
                      <input
                        className="form-control form-control-solid"
                        id="nombreInput"
                        type="text"
                      />
                    </div>
                    <div className="col-xs-6 col-sm-4 mb-4">
                      <label htmlFor="exampleFormControlInput1">
                        Teléfono:
                      </label>
                      <input
                        className="form-control form-control-solid"
                        id="nombreInput"
                        type="number"
                      />
                    </div>

                    <div className="row mb-4 row justify-content-md ">
                      <label htmlFor="exampleFormControlInput1">
                        Usa Whatsapp:
                      </label>
                      <div className="col-xs-6 col-sm-4">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="customRadioInline1"
                            name="customRadioInline1"
                            className="custom-control-input"
                          />
                          <label
                            className="custom-control-label"
                            for="customRadioInline1"
                          >
                            Si
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="customRadioInline2"
                            name="customRadioInline1"
                            className="custom-control-input"
                          />
                          <label
                            className="custom-control-label"
                            for="customRadioInline2"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-xs-6 col-sm-6">
                      <label htmlFor="exampleFormControlInput1">
                        En caso de emergencia llamar a:
                      </label>
                      <input
                        className="form-control form-control-solid"
                        id="nombreInput"
                        type="text"
                      />
                    </div>
                    <div className="col-xs-6 col-sm-6">
                      <label htmlFor="exampleFormControlInput1">
                        Teléfono de emergencia:
                      </label>
                      <input
                        className="form-control form-control-solid"
                        id="nombreInput"
                        type="number"
                      />
                    </div>
                  </div>

                  <div className="row row justify-content-md mb-4">
                    <div className="col-xs-2 col-sm-2">
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="customRadioInline3"
                          name="customRadioInline3"
                          className="custom-control-input"
                        />
                        <label
                          className="custom-control-label"
                          for="customRadioInline3"
                        >
                          Si
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="customRadioInline4"
                          name="customRadioInline3"
                          className="custom-control-input"
                        />
                        <label
                          className="custom-control-label"
                          for="customRadioInline4"
                        >
                          No
                        </label>
                      </div>
                    </div>
                    <div className="col-xs-6-auto col-sm-4">
                      <label htmlFor="exampleFormControlInput1">Email:</label>
                      <input
                        className="form-control form-control-solid"
                        id="nombreInput"
                        type="email"
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-xs-6 col-sm-6">
                      <label htmlFor="exampleFormControlInput1">
                        Sistema de salud:
                      </label>
                      <input
                        className="form-control form-control-solid"
                        id="nombreInput"
                        type="text"
                      />
                    </div>
                    <div className="col-xs-6 col-sm-6">
                      <label htmlFor="exampleFormControlInput1">
                        Cuenta pasos/modelo:
                      </label>
                      <input
                        className="form-control form-control-solid"
                        id="nombreInput"
                        type="text"
                      />
                    </div>
                  </div>
                </FormGroup>
                <div className="col-lg-12 mb-4">
                  <button className="btn btn-primary float-right">
                    {" "}
                    Guardar{" "}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </Modal>
      </div>
    </div>
  );
};
