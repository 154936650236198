import React from "react";

export const CardWalksInfo = (props) => {
  const {info} = props

  return (
    <div className="card-body">
              <div className="row">
                {/* Pasos caminados */}
                <div className="col-xl-4 col-md-6 mb-4">
                  <div className="card border-left-primary h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            Pasos caminados por grupo
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">
                            {info?.steps_walked_per_group  || 0 }
                          </div>
                          <div className="col">
                            {/* <div className="progress progress-sm mr-2">
                              <div
                                className="progress-bar bg-info"
                                role="progressbar"
                                // style="width: 50%"
                                aria-valuenow="20"
                                aria-valuemin="0"
                                aria-valuemax="70"
                              ></div>
                            </div> */}
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-walking fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Caminatas */}
                {/* <div className="col-xl-4 col-md-6 mb-4">
                  <div className="card border-left-success h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                            Caminatas Realizadas
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">
                          {info?.walk_finish || 0}
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-shoe-prints fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* Integrante */}
                <div className="col-xl-4 col-md-6 mb-4">
                  <div className="card border-left-info h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                            Tasa de asistencia hasta la fecha:
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">
                          {info?.rate_asistence || 0} %
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-calendar-check fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  );
};
