import React, {
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle,
} from "react";
import {
  validateEmail,
  checkRut,
  dateToString,
} from "../../../../helpers/basicFunctions";
import { setLimitDateInput } from "../../../../helpers/dateHelper";
import CreatableSelect from "react-select/creatable";

import { putStoreHiker } from "../../../../services/hikerUser.service";
import { createNewGroup, getAllGroupList } from "../../../../services/group.service";
import { useSelector } from "react-redux";

export const HikerData = forwardRef((props, ref) => {
  const user = useSelector((state) => state);
  const sys_user_id = user.user.user.sys_user_id || [];
  const [data, setData] = useState(props.data);
  const [hiker_user_name, sethiker_user_name] = useState("");
  const [hiker_user_lastname, sethiker_user_lastname] = useState("");
  const [hiker_user_rut, sethiker_user_rut] = useState("");
  const created_at = dateToString(new Date());
  const [hiker_user_born_date, sethiker_user_born_date] = useState(null);
  const [direccion, setDireccion] = useState("");
  const [hiker_user_phone, sethiker_user_phone] = useState("");
  const [hiker_user_whatsapp_active, sethiker_user_whatsapp_active] =
    useState("");
  const [hiker_user_emergency_person, sethiker_user_emergency_person] =
    useState("");
  const [hiker_user_emergency_phone, sethiker_user_emergency_phone] =
    useState("");
  const [hiker_user_email_active, sethiker_user_email_active] = useState(false);
  const [hiker_user_email, sethiker_user_email] = useState("");
  const [hiker_user_health_system, sethiker_user_health_system] = useState("");
  const [group_group_id, setGroup_group_id] = useState("");
  const [hiker_bracelet_model, sethiker_bracelet_model] = useState("");
  const [options, setOptions] = useState([]);
  const [alert, setAlert] = useState(false);
  const [maxDateTime] = useState(setLimitDateInput());

  useEffect(() => {
    setData(props.data);

    sethiker_user_name(data?.hiker_user_name || "");
    sethiker_user_lastname(data?.hiker_user_lastname || "");
    sethiker_user_rut(data?.hiker_user_rut || "");
    sethiker_user_born_date(data?.hiker_user_born_date || null);
    setDireccion(data?.direccion || "");
    sethiker_user_phone(data?.hiker_user_phone || "");
    sethiker_user_whatsapp_active(data?.hiker_user_whatsapp_active || false);
    sethiker_user_emergency_person(data?.hiker_user_emergency_person || "");
    sethiker_user_emergency_phone(data?.hiker_user_emergency_phone || "");
    sethiker_user_email_active(false);
    sethiker_user_email(data?.hiker_user_email || "");
    sethiker_user_health_system(data?.hiker_user_health_system || "");
    sethiker_bracelet_model(data?.hiker_bracelet_model || "");
    setGroup_group_id(data?.group_group_id || "");
  }, [
    data?.direccion,
    data?.hiker_bracelet_model,
    data?.hiker_user_born_date,
    data?.hiker_user_email,
    data?.hiker_user_email_active,
    data?.hiker_user_emergency_person,
    data?.hiker_user_emergency_phone,
    data?.hiker_user_health_system,
    data?.hiker_user_lastname,
    data?.hiker_user_name,
    data?.hiker_user_phone,
    data?.hiker_user_rut,
    data?.hiker_user_whatsapp_active,
    props.data,
    data?.group_group_id
  ]);

  useEffect(() => {
    getGroups();
  }, []);

  const getGroups = () => {
    getAllGroupList()
      .then((resp) => {
        let option = [];
        resp.data.map((e) => {
          option.push({
            value: e.group_id,
            label: `${e.group_name}`,
          });
        });

        setOptions(option);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const createGroup = async (groupName) => {
    let data_group = {
      group_name: groupName,
      sys_user_sys_user_id: [sys_user_id],
      // group_state_program_date_start: DateInit,
      // group_state_program_date_end: DateEnd,
    };
    return await createNewGroup(data_group)
      .then((resp) => {
        if (resp.status === 201) {
          return resp.data
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setAlert({type: "danger", title: 'Atención!', message: error.response.data.errorMessage, display: true});
        }
      });

  };

  const formatEmail = (e) => {
    if (!validateEmail(e.target.value)) {
      sethiker_user_email("");
      setAlert(true);
    }
    setTimeout(() => {
      setAlert(false);
    }, 10000);
  };
  const validateUserName = (e) => {
    sethiker_user_rut(checkRut(e.target.value));
  };

  useImperativeHandle(ref, () => ({
    fieldsHandler() {
      if (
        hiker_user_name === "" ||
        hiker_user_lastname === "" ||
        hiker_user_rut === "" ||
        created_at === "" //||
        //hiker_user_born_date === "" ||
        //direccion === "" ||
        //hiker_user_phone === ""  ||
        //hiker_user_emergency_person === "" ||
        //hiker_user_emergency_phone === ""
      ) {
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 10000);
        return false;
      } else {
        return true;
      }
    },
    async saveData() {
      const payload = {
        hiker_user_rut: hiker_user_rut,
        hiker_user_name: hiker_user_name,
        hiker_user_lastname: hiker_user_lastname,
        hiker_user_email: hiker_user_email,
        hiker_user_email_active: hiker_user_email_active,
        /* hiker_user_agreement_participation: agreement_participation, */
        hiker_user_phone: hiker_user_phone,
        hiker_user_whatsapp_active: hiker_user_whatsapp_active,
        hiker_user_emergency_person: hiker_user_emergency_person,
        hiker_user_emergency_phone: hiker_user_emergency_phone,
        hiker_user_born_date: hiker_user_born_date,
        hiker_user_health_system: hiker_user_health_system,
        hiker_bracelet_model: hiker_bracelet_model,
        group_group_id: group_group_id || null,
        hiker_app_token: null,
      };
      if (data?.hiker_user_id) {
        payload.hiker_user_id = data?.hiker_user_id;
      }
      try {
        const response = await putStoreHiker(payload);
        return response;
      } catch (error) {
        return error.response;
      }
    },
  }));

  return (
    <>
      <div className="col-lg-12">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">
              Datos del caminante
            </h6>
          </div>
          <div className="card-body">
            {alert ? (
              <div className="alert alert-danger" role="alert">
                Debe rellenar los campos obligatorios
              </div>
            ) : null}
            <p>
              Los campos con <i className="fas fa-exclamation-circle"></i> Son
              obligatorios.
            </p>
            <form>
              <div className="row was-validated">
                {/* NOMBRE */}
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="validationTextarea">Nombre</label>
                    <input
                      className="form-control form-control-solid is-invalid"
                      id="nombreInput"
                      type="text"
                      placeholder="Ingrese su Nombre"
                      value={hiker_user_name}
                      onChange={(e) => sethiker_user_name(e.target.value)}
                      required
                    />
                  </div>
                </div>
                {/* APELLIDO */}
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">Apellido</label>
                    <input
                      className="form-control form-control-solid is-invalid"
                      id="apellidoInput"
                      type="text"
                      placeholder="Ingrese su Apellido"
                      value={hiker_user_lastname}
                      onChange={(e) => sethiker_user_lastname(e.target.value)}
                      required
                    />
                    {/* <small id="nameInput" className="form-text text-muted">
                          Alerta: Debe ingresar apellido
                        </small> */}
                  </div>
                </div>
                {/* Rut */}
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">Rut</label>
                    <input
                      name="rut"
                      type="rut"
                      className="form-control form-control-user is-invalid"
                      id="InputRut"
                      aria-describedby="RutHelp"
                      placeholder="Ingrese su Rut"
                      onBlur={(e) => validateUserName(e)}
                      value={hiker_user_rut}
                      onChange={(e) => sethiker_user_rut(e.target.value)}
                      required
                    />
                  </div>
                </div>
                {/* Fecha de Ingreso */}
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">
                      Fecha de Ingreso
                    </label>
                    <input
                      name="creationDate"
                      type="input"
                      className="form-control form-control-user "
                      id="InputRut"
                      aria-describedby="RutHelp"
                      placeholder="Ingrese su Rut"
                      value={created_at}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                {/* Fecha de nacimiento */}
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">
                      Fecha nacimiento
                    </label>
                    <input
                      className="form-control form-control-solid"
                      id="fechaNacimientoInput"
                      type="date"
                      value={hiker_user_born_date}
                      max={maxDateTime}
                      onChange={(e) => sethiker_user_born_date(e.target.value)}
                    />
                  </div>
                </div>
                {/* Direccion */}
                {/* <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">Dirección</label>
                    <input
                      className="form-control form-control-solid"
                      id="direccionInput"
                      type="text"
                      placeholder="Dirección de residencia"
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                  </div>
                </div> */}
                {/* Telefono */}
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">Télefono</label>
                    <div>
                      <input
                        className="form-control form-control-phone-ext "
                        id="TelefonoExtInput"
                        type="number"
                        placeholder="+56"
                        disabled
                      />
                      <input
                        className="form-control form-control-phone-number "
                        id="TelefonoNumInput"
                        type="number"
                        placeholder="Ingrese su Telefono"
                        value={hiker_user_phone}
                        onChange={(e) => sethiker_user_phone(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* Usa Whatsapp */}
                {/* <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">
                      Usa Whatsapp
                    </label>
                    <div>
                      <input
                        className="radio-form"
                        type="radio"
                        id="yesWhatsapp"
                        name="drone"
                        value="1"
                        onClick={(e) => sethiker_user_whatsapp_active(true)}
                        checked={hiker_user_whatsapp_active}
                        readOnly
                      />
                      <label className="mr-4" htmlFor="huey">
                        Si
                      </label>
                      <input
                        className="radio-form"
                        type="radio"
                        id="noWhatsapp"
                        name="drone"
                        value="0"
                        onChange={() => sethiker_user_whatsapp_active(false)}
                        checked={!hiker_user_whatsapp_active}
                      />
                      <label htmlFor="huey">No</label>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="row">
                {/* NOMBRE EMERGENCIA */}
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">
                      En caso de emergencia llamar a{" "}
                    </label>
                    <input
                      className="form-control form-control-solid"
                      id="emergencyNameInput"
                      type="text"
                      placeholder="Ingrese un Nombre"
                      value={hiker_user_emergency_person}
                      onChange={(e) =>
                        sethiker_user_emergency_person(e.target.value)
                      }
                    />
                  </div>
                </div>
                {/* Telefono Emergencia*/}
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">
                      Télefono emergencia
                    </label>
                    <div>
                      <input
                        className="form-control form-control-phone-ext"
                        id="TelefonoExtInput"
                        type="number"
                        placeholder="+56"
                        disabled
                      />
                      <input
                        className="form-control form-control-phone-number "
                        id="TelefonoNumInput"
                        type="number"
                        placeholder="Ingrese un Telefono"
                        value={hiker_user_emergency_phone}
                        onChange={(e) =>
                          sethiker_user_emergency_phone(e.target.value)
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* Usa Email */}
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">Usa Email</label>
                    <div>
                      <input
                        className="radio-form"
                        type="radio"
                        id="yesEmail"
                        name="yesEmail"
                        value={true}
                        onChange={(e) => sethiker_user_email_active(true)}
                        checked={hiker_user_email_active}
                      />
                      <label className="mr-4" htmlFor="huey">
                        Si
                      </label>
                      <input
                        className="radio-form"
                        type="radio"
                        id="noEmail"
                        name="noEmail"
                        value={false}
                        onChange={(e) => sethiker_user_email_active(false)}
                        checked={!hiker_user_email_active}
                      />
                      <label htmlFor="huey">No</label>
                    </div>
                  </div>
                </div>
                {/* Correo */}
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="CorreoInput">Correo</label>
                    <input
                      className={`form-control form-control-solid  `}
                      id="CorreoInput"
                      type="email"
                      value={hiker_user_email}
                      onChange={(e) => sethiker_user_email(e.target.value)}
                      onBlur={(e) => formatEmail(e)}
                      disabled={!hiker_user_email_active}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                {/* Sistema Salud */}
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">
                      Sistema de salud
                    </label>
                    <input
                      className="form-control form-control-solid"
                      id="healthSystemInput"
                      type="text"
                      placeholder="Ingrese un Sistema de salud"
                      value={hiker_user_health_system}
                      onChange={(e) =>
                        sethiker_user_health_system(e.target.value)
                      }
                    />
                  </div>
                </div>
                {/* cuentapasos/modelo */}
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">
                      Cuentapasos / Modelo
                    </label>
                    <input
                      className="form-control form-control-solid"
                      id="stepCountNameInput"
                      type="text"
                      placeholder=""
                      value={hiker_bracelet_model}
                      onChange={(e) => sethiker_bracelet_model(e.target.value)}
                    />
                  </div>
                </div>
                {/* groupo */}
                {/* aa{options.find(g => g.value === group_group_id) || 'a'} */}
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="grupo">Grupo</label>
                    <CreatableSelect
                      isClearable
                      id="grupo"
                      value={options.find(g => g.value === group_group_id)}
                      onChange={(newValue) => {
                        setGroup_group_id(newValue?.value)
                        console.log(newValue)}
                      }
                      onCreateOption={async (c) => {
                        // TODO: create group
                        const group = await createGroup(c)
                        console.log('group', group);
                        console.log('group', group.group_id);
                        setGroup_group_id(group.group_id)
                        // refresh
                        getGroups()
                      }}
                      options={options}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
});
