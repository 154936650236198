import React, { useState /*useState*/ } from "react";
import Modal from "react-modal";
import { IoIosClose, IoIosCheckmarkCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

import { postNews } from "../../services/news.service";

export const CreateNews = () => {
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [information, setInformation] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [createIsSuccess, setCreateIsSuccess] = useState(false);
  const [state, setState] = useState({
    loadingCreateUser: false,
    createIsSuccess: true,
  });

  const saveData = async () => {
    openModal();
    const validate_fields = await validateFields();
    if (!validate_fields) {
      return;
    }
    const payload = {
      Information_title: title,
      Information_message: information,
    };
    try {
      const response = await postNews(payload);
      if(response){
        setCreateIsSuccess(true);
        setState({
          successMessage: "Noticia creada con éxito",
        });
        setTimeout(() => {
          history.push("/user/home");
        }, 2000);
      }
    
    } catch (error) {
      setCreateIsSuccess(true);
      setState({
        errorCreateUser: true,
        errorString: `Error ${error.response}`,
      });
      return error.response;
    }
  };
  
  const validateFields = async () => {
    if (title.length === 0 || information.length === 0) {
      setCreateIsSuccess(true);

      setState({
        errorCreateUser: true,
        errorString: `Error los campos no pueden estar vacios`,
      });
      console.log("validando", title.length);
      return false;
    } else {
      return true;
    }
  };
  const openModal = async () => {
    setIsOpen(true);
    setCreateIsSuccess(false);
  };
  const closeModal = () => {
    setIsOpen(false);
    history.push("/user/home");
  };
  return (
    <div className="container-fluid">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Register Modal"
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
      >
        {!createIsSuccess ? (
          <div>
            <div className="card">
              <div className="card-header text-info"></div>
              <div className="card-body">
                <h5 className="card-title text-info">
                  <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={40}
                    width={40}
                  />
                  Procesando...
                </h5>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {state.errorCreateUser ? (
              <div className="card">
                <div className="card-header text-danger">Advertencia!</div>
                <div className="card-body">
                  <h5 className="card-title text-danger">Error</h5>
                  <p className="card-text"> {state.errorString}</p>
                  <div className="btn btn-primary" onClick={closeModal}>
                    Volver
                  </div>
                </div>
              </div>
            ) : (
              <div className="card">
                <div className="card-header text-info"></div>
                <div className="card-body">
                <IoIosCheckmarkCircleOutline className="text-info" style={{ fontSize: 50 }} />

                  <h5 className="card-title text-info">

                    {state.successMessage}!

                  </h5>
                  <div className="btn btn-info" onClick={closeModal}>
                    Volver
                  </div>
                </div>
                {/* <h3> Su contraseña es {state.passwordString} </h3> */}
              </div>
            )}
          </div>
        )}
      </Modal>
      <div role="form" className="user">
        <div className="card shadow mb-4">
          <div className="btn-modal-close">
            <Link to="/user/home">
              <IoIosClose />
            </Link>
          </div>
          <div className="card-header py-3">
            <h5 className="m-0 font-weight-bold text-primary">Crear Informativo</h5>
          </div>

          <div className="card-body">
            <div>
              <div className="headline">Titular</div>
              <input
                name="Titular"
                type="text"
                className="form-control mb-2"
                aria-describedby="HeadlineHelp"
                placeholder="Título de tu informativo"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div>
              <div className="history">Historia</div>
              <textarea
                name="Historia"
                type="textarea"
                className="form-control mb-2"
                aria-describedby="HeadlineHelp"
                rows={8}
                placeholder="Escribe la información a difundir..."
                style={{ overflow: "auto", resize: "none" }}
                onChange={(e) => setInformation(e.target.value)}
              />
            </div>
            <div className="row">
              <div className="col-md-10 justify-content-center">
                <p>
                  <strong>
                    Una vez presionado el botón de publicar el informativo se creara de inmediato...
                  </strong>
                </p>
              </div>
              <div className="col-md-2 mb-4 ">
                <button
                  className="btn btn-primary float-right"
                  onClick={saveData}
                >
                  Publicar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
