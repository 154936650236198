import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";
import Loader from "react-loader-spinner";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { recoveryPasswordService } from "../../../services/login.service";
import { validateEmail } from "../../../helpers/basicFunctions";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";

const defaultState = {
  email: "",
};

export const Recovery = () => {
  const history = useHistory();
  const [state, setState] = useState(defaultState);
  const [loading, setLoading] = useState(false);
  const [sentAlert, setSentAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const validateEmailF = (e) => {
    setState({ ...state, emal: validateEmail(e.target.value) });
  };
  const changeHandler = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (state.email === "" || state.email === undefined) {
      setState({
        emptyError: true,
        errorString: "Debe ingresar su email",
      });
      setTimeout(() => {
        setState({ emptyError: false });
      }, 10000);
    } else {
      setLoading(true);
      const body = {
        sys_user_email: state.email,
      };
      try {
        await recoveryPasswordService(body);
        setLoading(false);
        setSentAlert(true);
        setTimeout(() => {
          setSentAlert(false);
          history.push("/auth/login");
        }, 3000);
      } catch (error) {
        setState({
          emptyError: true,
          errorString: "Ingrese un correo valido",
        });
        setLoading(false);
        setTimeout(() => {
          setState({ emptyError: false });
        }, 10000);
      }
    }
  };

  const onConfirm = () => {
    setSentAlert(false);
    history.push("/auth/login");
  };

  const closeAlert = () => {
    setSentAlert(false);
    setState({ emptyError: false });
    
  };

  return (
    <Container>
      {state.emptyError && (
        // <SweetAlert>
        <SweetAlert
        danger
        confirmBtnText="Entendido"
        confirmBtnBsStyle="danger"
        title="Atención!"
        onConfirm={() => closeAlert()}
        focusCancelBtn
      >
        {state.errorString}
      </SweetAlert>
      )}
      {sentAlert ? (
        <SweetAlert
          success
          title="Actualización exitosa!"
          onConfirm={onConfirm}
          // onCancel={this.onCancel}
          timeout={3000}
        >
          Se ha enviado un correo con su nueva contraseña!
        </SweetAlert>
      ) : null}
      <Row className="row justify-content-center">
        <Col className="col-xl-10 col-lg-12 col-md-9">
          <Card className="card o-hidden border-0 shadow-lg my-5">
            <CardBody className="recovery-card card-body p-0">
              <Row className="row">
                <Col className="col-lg-6 d-none d-lg-block bg-login-image"></Col>
                <Col className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">
                        Recuperación de contraseña
                      </h1>
                    </div>
                    <Form onSubmit={submitHandler} role="form" className="user">
                      <FormGroup className="form-group">
                        <Input
                          name="email"
                          type="email"
                          className="form-control form-control-user"
                          id="InputRut"
                          aria-describedby="emailHelp"
                          placeholder="Ingrese su email"
                          onChange={changeHandler}
                          value={state.rut}
                          onBlur={(e) => validateEmailF(e)}
                        />
                      </FormGroup>
                      <div className="btn-recovery">
                        {loading ? (
                          <div className="row justify-content-center">
                            <Loader
                              type="Puff"
                              color="#00BFFF"
                              height={40}
                              width={40}
                              // timeout={3000} //3 secs
                            />
                          </div>
                        ) : (
                          <button className="btn btn-primary btn-user btn-block">
                            Recuperar contraseña
                          </button>
                        )}
                      </div>
                      <hr />
                    </Form>
                    <div className="text-center">
                      <Link to="/auth/login">
                        <div className="a small">Volver a login</div>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
