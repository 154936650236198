import React, { useRef, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

// import LOGO from '../../assets/images/icons/logo.svg';
// import classes from './authlayout.module.scss';
import routes from "../../routes/routes";

export const AuthLayout = () => {
  const inputRef = useRef(null);
  useEffect(() => {
    // Set up
    document.body.classList.add("bg-gradient-primary");

    // Clean up
    return () => {
      document.body.classList.remove("bg-gradient-primary");
    };
  }, []);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <>
      <Container className="bg-gradient-primary">
        <Row>
          <Col md="12" className="p-0">
            <div className="main-content d-md-flex vh-100" ref={inputRef}>
              <Switch>
                {getRoutes(routes)}
                {/* <Route path={"/auth/login"} component={Login} key={0} /> */}
                <Redirect from="*" to="/auth/login" />
              </Switch>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
