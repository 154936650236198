import {
  LOAD_HIKER_LIST,
  LOAD_HIKER_LIST_SUCCESS,
  LOAD_HIKER_LIST_ERROR,
  LOAD_HIKER_HEADER,
  LOAD_HIKER_HEADER_SUCCESS,
  LOAD_HIKER_HEADER_ERROR,
  LOAD_HIKER_MANUAL_REGISTER,
  LOAD_HIKER_MANUAL_REGISTER_SUCCESS,
  LOAD_HIKER_MANUAL_REGISTER_ERROR,
  LOAD_HIKER_EDIT,
  LOAD_HIKER_EDIT_SUCCESS,
  LOAD_HIKER_EDIT_ERROR,
  LOAD_HIKER_CHECK_HEALTH,
  LOAD_HIKER_CHECK_HEALTH_SUCCESS,
  LOAD_HIKER_CHECK_HEALTH_ERROR,
  LOAD_HIKER_QUALITY_LIFE,
  LOAD_HIKER_QUALITY_LIFE_SUCCESS,
  LOAD_HIKER_QUALITY_LIFE_ERROR,
  LOAD_HIKER_ENTRY_MOTIVATION,
  LOAD_HIKER_ENTRY_MOTIVATION_SUCCESS,
  LOAD_HIKER_ENTRY_MOTIVATION_ERROR,
} from '../types';

const initialState = {
  //Hiker List
  loadingHikerList: false,
  hikerList: null,
  errorHikerList: null,
  //HikerHeader
  loadingHikerHeader: false,
  hikerHeader: null,
  errorHikerHeader: null,
  //HikerManualRegister
  loadingHikerManualRegister: false,
  hikerManualRegister: null,
  errorHikerManualRegister: null,
  //HikerEdit
  loadingHikerEdit: false,
  hikerEdit: null,
  errorHikerEdit: null,
  //HikerCheckHealth
  loadingHikerCheckHealth: false,
  hikerCheckHealth: null,
  errorHikerCheckHealth: null,
  //HikerQualityLife
  loadingHikerQualityLife: false,
  hikerQualityLife: null,
  errorHikerQualityLife: null,
  //HikerEntryMotivation
  loadingHikerEntryMotivation: false,
  hikerEntryMotivation: null,
  errorHikerEntryMotivation: null,
};

export default function hikerReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_HIKER_LIST:
      return {
        ...state,
        loadingHikerList: true,
      };
    case LOAD_HIKER_LIST_SUCCESS:
      return {
        ...state,
        loadingHikerList: false,
        hikerList: action.payload,
        errorHikerList: null,
      };
    case LOAD_HIKER_LIST_ERROR:
      return {
        ...state,
        loadingHikerList: false,
        errorHikerList: action.payload,
      };
    case LOAD_HIKER_HEADER:
      return {
        ...state,
        loadingHikerHeader: true,
      };
    case LOAD_HIKER_HEADER_SUCCESS:
      return {
        ...state,
        loadingHikerHeader: false,
        hikerHeader: action.payload,
        errorHikerHeader: null,
      };
    case LOAD_HIKER_HEADER_ERROR:
      return {
        ...state,
        loadingHikerHeader: false,
        errorHikerHeader: action.payload,
      };
    case LOAD_HIKER_MANUAL_REGISTER:
      return {
        ...state,
        loadingHikerManualRegister: true,
      };
    case LOAD_HIKER_MANUAL_REGISTER_SUCCESS:
      return {
        ...state,
        loadingHikerManualRegister: false,
        hikerManualRegister: action.payload,
        errorHikerManualRegister: null,
      };
    case LOAD_HIKER_MANUAL_REGISTER_ERROR:
      return {
        ...state,
        loadingHikerManualRegister: false,
        errorHikerManualRegister: action.payload,
      };
    case LOAD_HIKER_EDIT:
      return {
        ...state,
        loadingHikerEdit: true,
      };
    case LOAD_HIKER_EDIT_SUCCESS:
      return {
        ...state,
        loadingHikerEdit: false,
        hikerEdit: action.payload,
        errorHikerEdit: null,
      };
    case LOAD_HIKER_EDIT_ERROR:
      return {
        ...state,
        loadingHikerEdit: false,
        errorHikerEdit: action.payload,
      };
    case LOAD_HIKER_CHECK_HEALTH:
      return {
        ...state,
        loadingHikerCheckHealth: true,
      };
    case LOAD_HIKER_CHECK_HEALTH_SUCCESS:
      return {
        ...state,
        loadingHikerCheckHealth: false,
        hikerCheckHealth: action.payload,
        errorHikerCheckHealth: null,
      };
    case LOAD_HIKER_CHECK_HEALTH_ERROR:
      return {
        ...state,
        loadingHikerCheckHealth: false,
        errorHikerCheckHealth: action.payload,
      };
    case LOAD_HIKER_QUALITY_LIFE:
      return {
        ...state,
        loadingHikerQualityLife: true,
      };
    case LOAD_HIKER_QUALITY_LIFE_SUCCESS:
      return {
        ...state,
        loadingHikerQualityLife: false,
        hikerQualityLife: action.payload,
        errorHikerQualityLife: null,
      };
    case LOAD_HIKER_QUALITY_LIFE_ERROR:
      return {
        ...state,
        loadingHikerQualityLife: false,
        errorHikerQualityLife: action.payload,
      };
    case LOAD_HIKER_ENTRY_MOTIVATION:
      return {
        ...state,
        loadingHikerEntryMotivation: true,
      };
    case LOAD_HIKER_ENTRY_MOTIVATION_SUCCESS:
      return {
        ...state,
        loadingHikerEntryMotivation: false,
        hikerEntryMotivation: action.payload,
        errorHikerEntryMotivation: null,
      };
    case LOAD_HIKER_ENTRY_MOTIVATION_ERROR:
      return {
        ...state,
        loadingHikerEntryMotivation: false,
        errorHikerEntryMotivation: action.payload,
      };

    default:
      return state;
  }
}
