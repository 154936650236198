import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { axiosHiker } from "../../config/axios";
import { calculateAge, formatDate } from "../../helpers/dateHelper";
import { Button } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import SweetAlert from "react-bootstrap-sweetalert";
import { ConfirmModal } from "../Modal/ConfirmModal";
import { Link } from "react-router-dom";

export const TableLastRegister = () => {
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deletingHikerStatus, setDeletingHikerStatus] = useState();
  const [hikerToDelete, setHikerToDelete] = useState();

  const getLastHikers = () => {
    return axiosHiker.get(
      `/get-userlist-sys?page=1&size=9&sortBy=updated_at&order=0`
    );
  };

  const onHikerDelete = async () => {
    try {
      await axiosHiker.delete(`/${hikerToDelete}`);
      const hikers = await getLastHikers();
      setList(hikers.data.data);
      setDeletingHikerStatus("success");
    } catch (error) {
      setDeletingHikerStatus("error");
    } finally {
      setHikerToDelete();
    }
  };

  useEffect(() => {
    getLastHikers().then((resp) => {
      setLoading(false);
      if (resp.data.data.length > 0) {
        setList(resp.data.data);
      }
    });
  }, []);
  return (
    <div>
      {loading ? (
        <div>
          <div className="card">
            <div className="card-header text-info"></div>
            <div className="card-body">
              <h5 className="card-title text-info">
                <Loader type="Puff" color="#00BFFF" height={40} width={40} />
                Cargando...
              </h5>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {list ? (
            <div>
              {list.length > 0 ? (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Edad</th>
                      <th>Grupo</th>
                      {/* <th>Fecha de registo</th> */}
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((item) => (
                      <tr key={item.hiker_user_id}>
                        <th>
                          {item.hiker_user_name} {item.hiker_user_lastname}
                        </th>
                        {calculateAge(item.hiker_user_born_date) <= 0 ? (
                          <th>No Definida</th>
                        ) : (
                          <th>
                            {calculateAge(item.hiker_user_born_date)} años
                          </th>
                        )}

                        <th>{item?.group_name || "Sin asignar"}</th>
                        {/* <th>{formatDate(item.created_at)}</th> */}
                        <th>
                          <div className="d-flex flex-nowrap">
                            <Link
                              to={{
                                pathname: `/user/hiker-details`,
                                state: { data: item },
                              }}
                              className="btn btn-primary"
                            >
                              Ver{" "}
                            </Link>
                            <Button
                              className="ml-1"
                              variant="danger"
                              onClick={() =>
                                setHikerToDelete(item.hiker_user_id)
                              }
                            >
                              <AiFillDelete />{" "}
                            </Button>
                          </div>
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div>
              <h4>No hay registros</h4>
            </div>
          )}
        </div>
      )}
      <ConfirmModal
        open={!!hikerToDelete}
        onCancel={() => setHikerToDelete()}
        onConfirm={onHikerDelete}
        title={"Eliminar caminante"}
        msg={"¿Está seguro que desea eliminar al caminante?"}
      />
      {deletingHikerStatus && (
        <SweetAlert
          type={deletingHikerStatus}
          onConfirm={() => setDeletingHikerStatus()}
          title={
            deletingHikerStatus === "success"
              ? "Usuario eliminado correctamente"
              : "Error al eliminar caminante"
          }
        />
      )}
    </div>
  );
};
