import React, { forwardRef, useState, useImperativeHandle } from "react";
import { postNewPhysicalCondition } from "../../../../services/hikerUser.service";

import TableRango from "./Tablerango";
import testsilla from "../../../../assets/images/icons/testsilla.png";
import pies from "../../../../assets/images/pies.png";
import pies2 from "../../../../assets/images/pies2.png";
import pies3 from "../../../../assets/images/pies3.png";
import pie1 from "../../../../assets/images/pie1.png";
import pie2 from "../../../../assets/images/pie2.png";

export const HikerNewPhysicalCondition = forwardRef((props, ref) => {
  const { data, option } = props;
  const [alert, setAlert] = useState(false);

  const [test_a_seconds, setTeastA] = useState(data?.test_a_seconds || null);
  const [test_b_seconds, setTeastB] = useState(data?.test_b_seconds || null);
  const [test_c_seconds, setTeastC] = useState(data?.test_c_seconds || null);
  const [test_d_seconds, setTeastD] = useState(data?.test_d_seconds || null);
  const [test_e_seconds, setTeastE] = useState(data?.test_e_seconds || null);

  const [chair_test_seconds, setChairTestSecs] = useState(
    data?.test_a_secs || null
  );
  const [chair_test_count, setChairTestCount] = useState(
    data?.chair_test_count || null
  );

  useImperativeHandle(ref, () => ({
    fieldsHandler() {
      if (
        (test_a_seconds == undefined ,
        test_b_seconds == undefined ,
        test_c_seconds == undefined ,
        test_d_seconds == undefined ,
        test_e_seconds == undefined ,
        chair_test_seconds == undefined ,
        chair_test_count == undefined )
      ) {
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 10000);
        return false;
      } else {
        return true;
      }
    },
    async saveData(hiker_user_id) {

      const data = {
        hiker_us_eval_cond_fisc_balance: {
          test_a_seconds: test_a_seconds,
          test_b_seconds: test_b_seconds,
          test_c_seconds: test_c_seconds,
          test_d_seconds: test_d_seconds,
          test_e_seconds: test_e_seconds,
          hiker_us_check_health_us_check_health_id: null,
          hiker_user_id: hiker_user_id
        },
        hiker_us_eval_cond_fisc_marcha: {
          chair_test_seconds: chair_test_seconds,
          hiker_us_check_health_us_check_health_id: null,
          hiker_user_id: hiker_user_id
        },
        hiker_user_chair_eval: {
          chair_test_count: chair_test_count,
          check_health_id: null,
          hiker_user_id: hiker_user_id
        },
      };
      try {
        let response = await postNewPhysicalCondition(data);
        return response;
      } catch (error) {
        return false;
      }
    },
  }));
  return (
    <>
      <div className="col-lg-12">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">
              Evaluación de condición física
            </h6>
          </div>
          <div className="card-body">
            {/* <!-- alert error --> */}
            {alert ? (
              <div className="alert alert-danger" role="alert">
                Debe rellenar todos los campos
              </div>
            ) : null}

            {/* <!-- Prueba de Balance --> */}
            <div className="container was-validated">
              <div className="row">
                <div className="col-md-6">
                  <b>
                    Prueba de Balance: Test de equilibrio de las 4 posiciones
                  </b>
                </div>
              </div>
              <hr></hr>
              {/* <!-- Prueba 1 --> */}
              <div className="row ">
                <div className="col-md-12">
                  <p>
                    Demostar posición y solicitar mantenerla durante 10
                    segundos, registrar el mejor tiempo
                  </p>
                </div>
                <div className="col-md-2">
                  <img src={pies} alt="Test 1" />
                </div>
                <div className="col-md-5">
                  <b> A. Pararse con los pies uno al lado del otro</b>
                </div>
                <div className="col-md-2">
                  <label> Tiempo en seg: </label>
                </div>
                <div className="col-md-3">
                  <input
                    type="number"
                    disabled={option?.disable}
                    min={0}
                    className={`form-control form-control-solid ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    value={test_a_seconds}
                    onChange={(e) => setTeastA(e.target.value)}
                  />
                </div>
              </div>
              <hr></hr>
              {/* <!-- Prueba 2 --> */}
              <div className="row">
                <div className="col-md-2">
                  <img src={pies2} alt="Smi tandem" />
                </div>
                <div className="col-md-5">
                  <b> B. Pararse en posición semi-tándem</b>
                </div>
                <div className="col-md-2">
                  <label> Tiempo en seg: </label>
                </div>
                <div className="col-md-3">
                  <input
                    disabled={option?.disable}
                    min={0}
                    type="number"
                    className={`form-control form-control-solid ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    value={test_b_seconds}
                    onChange={(e) => setTeastB(e.target.value)}
                  />
                </div>
              </div>
              <hr></hr>
              {/* <!-- Prueba 3 --> */}
              <div className="row">
                <div className="col-md-2">
                  <img src={pies3} alt="Tandem" />
                </div>
                <div className="col-md-5">
                  <b> C. Pararse en posición tándem</b>
                </div>
                <div className="col-md-2">
                  <label> Tiempo en seg: </label>
                </div>
                <div className="col-md-3">
                  <input
                    disabled={option?.disable}
                    min={0}
                    type="number"
                    className={`form-control form-control-solid ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    value={test_c_seconds}
                    onChange={(e) => setTeastC(e.target.value)}
                  />
                </div>
              </div>
              <hr></hr>
              {/* <!-- Prueba 4 --> */}
              <div className="row">
                <div className="col-md-2">
                  <img src={pie1} alt="Pie izquierdp" />
                </div>
                <div className="col-md-5">
                  <b> D. Pararse con apoyo en un solo pie (izquierdo)</b>
                </div>
                <div className="col-md-2">
                  <label> Tiempo en seg: </label>
                </div>
                <div className="col-md-3">
                  <input
                    disabled={option?.disable}
                    min={0}
                    type="number"
                    className={`form-control form-control-solid ${
                      option?.disable ? "" : "is-invalid"
                    }`}
                    value={test_d_seconds}
                    onChange={(e) => setTeastD(e.target.value)}
                  />
                </div>
              </div>
              <hr></hr>
              {/* <!-- Prueba 5 --> */}
              <div className="row ">
                <div className="col-md-2">
                  <img src={pie2} alt="Pie derecho" />
                </div>
                <div className="col-md-5">
                  <b>E. Pararse con apoyo en un solo pie (derecho)</b>
                </div>
                <div className="col-md-2">
                  <label> Tiempo en seg: </label>
                </div>
                <div className="col-md-3">
                  <input
                    disabled={option?.disable}
                    min={0}
                    type="number"
                    className={`form-control form-control-solid ${
                      option?.disable ? "" : "is-invalid"
                    }`}
                    value={test_e_seconds}
                    onChange={(e) => setTeastE(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <hr></hr>
            {/* <!--Velocidad de marcha --> */}
            <div className="container">
              <div className="row">
                <div className="col-md-6 ">
                  <b>Velocidad de Marcha: TUG - Time up and go </b>
                </div>
              </div>
              <hr></hr>
              {/* <!-- Prueba 1 --> */}
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <b>
                      Tiempo en segundos que toma una persona en pararse de una
                      silla, caminar tres metros, dar la vuelta y volver a
                      sentarse{" "}
                    </b>
                    Menor a 10 seg: Normal, 11-19 seg: Riesgo leve, Mayor a 20
                    seg: Alto riesgo
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="form-row was-validated">
                    <div className="col-md-4">Tiempo en segs:</div>
                    <div className="col-md-8 col-lg-4">
                      <div className="form-check">
                        <input
                          disabled={option?.disable}
                          min={0}
                          type="number"
                          className={`form-control ${
                            option?.disable ? "" : "is-invalid"
                          }`}
                          id="inputEmail3"
                          placeholder=""
                          value={chair_test_seconds}
                          onChange={(e) => setChairTestSecs(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
            {/* <!--Prueba de silla--> */}
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <b>Prueba de fuerza: Test de la silla</b>
                </div>
              </div>
              <hr></hr>
              {/* <!-- Prueba 1 --> */}
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      Solicitar intentar levantarse de la silla a la mayor
                      cantidad de veces en 30 segundos. (Altura de asiento 44
                      centímetros)
                    </p>
                  </div>
                  <div className="col-md-6">
                    <div className="form-row was-validated">
                      <div className="col-md-4">Cantidad de veces:</div>
                      <div className="col-sm-8 col-md-8 col-lg-4">
                        <div className="form-check">
                          <input
                            disabled={option?.disable}
                            min={0}
                            type="number"
                            className={`form-control ${
                              option?.disable ? "" : "is-invalid"
                            }`}
                            id="inputEmail3"
                            value={chair_test_count}
                            placeholder=""
                            onChange={(e) => setChairTestCount(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex justify-content-between ">
                    <div className="col-sm-4 col-md-4 ">
                      <img
                        src={testsilla}
                        className="img-fluid"
                        alt="Test de la Silla"
                      />
                    </div>
                    <div className="col-sm-8 col-md-8  ">
                      <TableRango> </TableRango>
                    </div>
                    <p>
                      *Una puntucación por debajo del promedio indica riesgo de
                      caídas
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
