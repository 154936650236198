import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// import { useConfig } from "../../redux/config";
// import classes from "./MainLayout.module.scss";

import { Sidebar } from "../../components/Sidebar/Sidebar";
import { HomeNavbar } from "../../components/Navbars/HomeNavbar";
import { Home } from "../../pages/Home/Home";
import { Profile } from "../../pages/Profile/Profile";
import { NewProffesional } from "../../pages/Proffesionals/NewProffesional/NewProffesional";
import { ProffesionalInfo } from "../../pages/Proffesionals/ProfessionalInfo/ProffesionalInfo";
import { ProffesionalList } from "../../pages/Proffesionals/ProffesionalList/ProffesionalList";
import { HikerList } from "../../pages/Hikers/HikerList/HikerList";
import { NewHiker } from "../../pages/Hikers/NewHiker/NewHiker";
import { Calendar } from "../../pages/Calendar/Calendar";
import { Reports } from "../../pages/Reports/Report";

// Perfil Kinesiologo
import { CreateNews } from "../../pages/News/CreateNews";
import { GroupManager } from "../../pages/Kinesiologo/Group/GroupManager/GroupManager";
import { HikerDetails } from "../../pages/Kinesiologo/AdministrarKiller/component/HikerDetails";
import { NewFicha } from "../../pages/Kinesiologo/AdministrarKiller/component/NewFicha";
import { StepLog } from "../../pages/Kinesiologo/AdministrarKiller/component/StepLog";
import { AdministrarHikers } from "../../pages/Kinesiologo/AdministrarKiller/AdministrarHikers";
import { NewGroup } from "../../pages/Kinesiologo/Group/NewGroup";
import { GroupDetails } from "../../pages/Kinesiologo/Group/GroupManager/component/GruoupDetails";
import { AgendarCaminata } from "../../pages/Kinesiologo/Group/GroupManager/component/AgendarCaminata";

import { ChequeoSalud } from "../../pages/Kinesiologo/AdministrarKiller/component/ChequeoSalud";
import { ChequeoCondicionFisica } from "../../pages/Kinesiologo/AdministrarKiller/component/ChequeoCondicionFisica";
import { ChequeoCondicionVida } from "../../pages/Kinesiologo/AdministrarKiller/component/ChequeoCondicionVida";
import { ViewContent } from "../../components/ViewRegistration/ViewContent";
import { NewCaminata } from "../../components/Caminatas/NewCaminata";
import ImportHikers from "../../pages/Hikers/ImportHikers/ImportHikers";
// import routes from "../../routes/routes";

export const MainLayout = () => {
  // const { showSearch } = useConfig();

  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <HomeNavbar />
            <Switch>
              {/* {this.getRoutes(routes)} */}
              <Route exact path="/user/home" key="0" render={() => <Home />} />
              <Route
                exact
                path="/user/profile"
                key="1"
                render={() => <Profile />}
              />
              <Route
                exact
                path="/user/proffesional-list"
                key="2"
                render={() => <ProffesionalList />}
              />
              <Route
                exact
                path="/user/new-proffesional"
                key="3"
                render={() => <NewProffesional />}
              />
              <Route
                exact
                path="/user/proffesional-info"
                key="4"
                render={() => <ProffesionalInfo />}
              />
              <Route
                exact
                path="/user/new-hiker"
                key="5"
                render={() => <NewHiker />}
              />

              <Route
                exact
                path="/user/hiker-list"
                key="7"
                render={() => <HikerList />}
              />
              <Route
                exact
                path="/user/calendar"
                key="8"
                render={() => <Calendar />}
              />
              <Route
                exact
                path="/user/Reports"
                key="8"
                render={() => <Reports />}
              />
      
              <Route
                exact
                path="/user/hiker-details"
                key="10"
                render={() => <HikerDetails />}
              />
              <Route
                exact
                path="/user/create-news"
                key="11"
                render={() => <CreateNews />}
              />
              <Route
                exact
                path="/user/new-ficha"
                key="12"
                render={() => <NewFicha />}
              />
              <Route
                exact
                path="/user/step-log"
                key="13"
                render={() => <StepLog />}
              />
              <Route
                exact
                path="/user/group-manager"
                key="14"
                render={() => <GroupManager />}
              />
              <Route
                exact
                path="/user/administrar-hikers"
                key="15"
                render={() => <AdministrarHikers />}
              />
              <Route
                exact
                path="/user/importar-hikers"
                key="15"
                render={() => <ImportHikers />}
              />
              <Route
                exact
                path="/user/new-group"
                key="17"
                render={() => <NewGroup />}
              />
              <Route
                exact
                path="/user/group-details"
                key="18"
                render={() => <GroupDetails />}
              />
              {/* <Route
                exact
                path="/user/agendar"
                key="19"
                render={() => <AgendarCaminata />}
              /> */}
           
              <Route
                exact
                path="/user/chequeo-salud"
                key="22"
                render={() => <ChequeoSalud />}
              />
              <Route
                exact
                path="/user/chequeo-condicion-fisica"
                key="23"
                render={() => <ChequeoCondicionFisica />}
              />
              <Route
                exact
                path="/user/chequeo-condicion-vida"
                key="24"
                render={() => <ChequeoCondicionVida />}
              />
              <Route
                exact
                path="/user/chequeos"
                key="24"
                render={() => <ViewContent />}
              />
              {/* <Route
                exact
                path="/user/new-caminata"
                key="25"
                render={() => <NewCaminata />}
              /> */}
              <Redirect from="*" to="/user/home" />
            </Switch>
            {/* <AdminFooter /> */}
          </div>
        </div>
      </div>
    </>
  );
};
