import { axiosReports } from "../config/axios";
const base_url = process.env.REACT_APP_AXIOS_LOGIN;

const getReports = (data, type, size) => {
  return axiosReports.post(
    `/generate-reports?type=${type}&page=1&size=${size}`,
    data
  );
};

const getAllReports = (data, type) =>
{
  return axiosReports.post(`/generate-all-reports?type=${type}`, data)
}

const reportHandlePage = (url, hikersSelecteds) => {
  if (url) {
    const Url = url.slice(1);
    return axiosReports.post(`${base_url}${Url}`, hikersSelecteds);
  }
};

export { getReports, getAllReports, reportHandlePage };
