import { axiosCards } from "../config/axios";

const getHikerDetailsCard = (hiker_user_id) => {
  return axiosCards.get(`/get-hiker-card-details/${hiker_user_id}`);
};
const getDashboardCard = () => {
    return axiosCards.get(`/get-dashboard-card`);
};
const getGroupDetailcard = (group_id) => {
  return axiosCards.get(`/get-group-card-details/${group_id}`);
};
const getDashboardCardKine = (kine_ine) => {
  return axiosCards.get(`/get-dashboard-card-kine/${kine_ine}`);
};
export { getHikerDetailsCard, getDashboardCard, getGroupDetailcard, getDashboardCardKine };
