import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const HandlePage = (props) => {
  const handlePaginationPrev = async () => {
    props.changePage(props.data.links.prev);
  };
  const handlePaginationNext = async () => {
    props.changePage(props.data.links.next);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-en",
        alignItems: "center",
      }}
    >
      <button
        onClick={() => handlePaginationPrev()}
        style={{
          background: "transparent",
          border: 0,
        }}
      >
        <IoIosArrowBack />
      </button>
      <div className="float-left">
        {props?.data?.currentPage} / {props?.data?.totalPages}
      </div>
      <button
        onClick={() => handlePaginationNext()}
        style={{
          background: "transparent",
          border: 0,
        }}
      >
        <IoIosArrowForward />
      </button>
    </div>
  );
};

export default HandlePage;
