import React, { forwardRef, useState, useImperativeHandle } from "react";
import { postNewLifeQuality } from "../../../../services/hikerUser.service";
export const HikerNewLifeQuality = forwardRef((props, ref) => {
  const { data, option } = props;
  const [quest_1, setquest_1] = useState(data?.quest_1 || 0);
  const [quest_2, setquest_2] = useState(data?.quest_2 || 0);
  const [quest_3, setquest_3] = useState(data?.quest_3 || 0);
  const [quest_4, setquest_4] = useState(data?.quest_4 || 0);
  const [quest_5, setquest_5] = useState(data?.quest_5 || 0);
  const [alert] = useState(false);
  useImperativeHandle(ref, () => ({
    async saveData(hiker_user_id) {
      const data = {
        quest_1: quest_1,
        quest_2: quest_2,
        quest_3: quest_3,
        quest_4: quest_4,
        quest_5: quest_5,
        hiker_user_id: hiker_user_id,
      };
      try {
        const response = await postNewLifeQuality(data);
        return response;
      } catch (error) {
        return error;
      }
    },
  }));

  return (
    <>
      {/* Evaluación calidad de vida  */}
      <div className="col-lg-12">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">
              Evaluación Calidad de vida Inicial
            </h6>
          </div>
          <div className="card-body">
            {alert ? (
              <div className="alert alert-danger" role="alert">
                Debe rellenar todos los campos
              </div>
            ) : null}
            <div className="row">
              <div className="col-md-2">
                <p>
                  <b>Frecuencia</b>
                </p>
              </div>
              <div className="col-md-2">
                <p>Nunca</p>
              </div>
              <div className="col-md-2">
                <p>Casi Nunca</p>
              </div>
              <div className="col-md-2">
                <p>Ocasionalmente</p>
              </div>
              <div className="col-md-2">
                <p>Casi todos los días</p>
              </div>
              <div className="col-md-2">
                <p>Todos los días</p>
              </div>
            </div>
            {/* <!-- Evaluación calidad de vida - pregunta 1 --> */}
            <div className="row">
              <div className="col-md-2">Toma médicamentos para el dolor</div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="ev-cal-vida-preg1-opt1"
                    type="radio"
                    name="ev-cal-vida-preg1"
                    onChange={(e) => setquest_1(0)}
                    checked={quest_1 === 0 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="ev-cal-vida-preg1-opt2"
                    type="radio"
                    name="ev-cal-vida-preg1"
                    onChange={(e) => setquest_1(1)}
                    checked={quest_1 === 1 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="ev-cal-vida-preg1-opt3"
                    type="radio"
                    name="ev-cal-vida-preg1"
                    onChange={(e) => setquest_1(2)}
                    checked={quest_1 === 2 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="ev-cal-vida-preg1-opt4"
                    type="radio"
                    name="ev-cal-vida-preg1"
                    onChange={(e) => setquest_1(3)}
                    checked={quest_1 === 3 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="ev-cal-vida-preg1-opt5"
                    type="radio"
                    name="ev-cal-vida-preg1"
                    onChange={(e) => setquest_1(4)}
                    checked={quest_1 === 4 ? true : false}
                  />
                </div>
              </div>
            </div>
            <br></br>
            {/* <!-- Evaluación calidad de vida - pregunta 2 --> */}
            <div className="row">
              <div className="col-md-2">Se siente cansado</div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg2"
                    onChange={(e) => setquest_2(0)}
                    checked={quest_2 === 0 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg2"
                    onChange={(e) => setquest_2(1)}
                    checked={quest_2 === 1 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg2"
                    onChange={(e) => setquest_2(2)}
                    checked={quest_2 === 2 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg2"
                    onChange={(e) => setquest_2(3)}
                    checked={quest_2 === 3 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg2"
                    onChange={(e) => setquest_2(4)}
                    checked={quest_2 === 4 ? true : false}
                  />
                </div>
              </div>
            </div>
            <br></br>
            {/* <!-- Evaluación calidad de vida - pregunta 3 --> */}
            <div className="row">
              <div className="col-md-2">Percibe falta de compañia</div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg3"
                    onChange={(e) => setquest_3(0)}
                    checked={quest_3 === 0 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg3"
                    onChange={(e) => setquest_3(1)}
                    checked={quest_3 === 1 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg3"
                    onChange={(e) => setquest_3(2)}
                    checked={quest_3 === 2 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg3"
                    onChange={(e) => setquest_3(3)}
                    checked={quest_3 === 3 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg3"
                    onChange={(e) => setquest_3(4)}
                    checked={quest_3 === 4 ? true : false}
                  />
                </div>
              </div>
            </div>
            <br></br>
            {/* <!-- Evaluación calidad de vida - pregunta 4 --> */}
            <div className="row">
              <div className="col-md-2">Se siente angustiado o deprimido</div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg4"
                    onChange={(e) => setquest_4(0)}
                    checked={quest_4 === 0 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg4"
                    onChange={(e) => setquest_4(1)}
                    checked={quest_4 === 1 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg4"
                    onChange={(e) => setquest_4(2)}
                    checked={quest_4 === 2 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg4"
                    onChange={(e) => setquest_4(3)}
                    checked={quest_4 === 3 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg4"
                    onChange={(e) => setquest_4(4)}
                    checked={quest_4 === 4 ? true : false}
                  />
                </div>
              </div>
            </div>
            <br></br>
            {/* <!-- Evaluación calidad de vida - pregunta 5 --> */}
            <div className="row">
              <div className="col-md-2">Duerme bien</div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg5"
                    onChange={(e) => setquest_5(0)}
                    checked={quest_5 === 0 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg5"
                    onChange={(e) => setquest_5(1)}
                    checked={quest_5 === 1 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg5"
                    onChange={(e) => setquest_5(2)}
                    checked={quest_5 === 2 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg5"
                    onChange={(e) => setquest_5(3)}
                    checked={quest_5 === 3 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    disabled={option?.disable}
                    className={`form-check-input ${
                      option?.disable ? "" : "is-invalid"
                    } `}
                    id="flexRadioDefault1"
                    type="radio"
                    name="ev-cal-vida-preg5"
                    onChange={(e) => setquest_5(4)}
                    checked={quest_5 === 4 ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
