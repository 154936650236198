import {
  LOAD_GROUP_LIST,
  LOAD_GROUP_LIST_SUCCESS,
  LOAD_GROUP_LIST_ERROR,
  LOAD_GROUP_HEADER,
  LOAD_GROUP_HEADER_SUCCESS,
  LOAD_GROUP_HEADER_ERROR,
  LOAD_GROUP_IN_CHARGE,
  LOAD_GROUP_IN_CHARGE_SUCCESS,
  LOAD_GROUP_IN_CHARGE_ERROR,
  LOAD_GROUP_NEXT_HIKES,
  LOAD_GROUP_NEXT_HIKES_SUCCESS,
  LOAD_GROUP_NEXT_HIKES_ERROR,
} from "../types";
import { axiosGroup } from "../../config/axios";


//LOAD GROUP LIST
export function getGroupListAction(sys_user_id) {
  return async (dispatch) => {
    dispatch(getGroupList());
    try {
      const response = await axiosGroup.get(`/group-list/${sys_user_id}?page=1&size=10&sortBy=group_state_program_date_start&order=0`);
      dispatch(getGroupListSuccess(response.data));
    } catch (error) {
      dispatch(getGroupListError(error));
    }
  };
}

const getGroupList = () => ({
  type: LOAD_GROUP_LIST,
  payload: true,
});

const getGroupListSuccess = (user) => ({
  type: LOAD_GROUP_LIST_SUCCESS,
  payload: user,
});

const getGroupListError = (status) => ({
  type: LOAD_GROUP_LIST_ERROR,
  payload: status,
});

//Group Header
export const loadGroupHeaderAction = (user_id) => {
  return async (dispatch) => {
    dispatch(loadGroupHeader());
    try {
      const data = await axiosGroup.post("/group-header", user_id);
      dispatch(loadGroupHeaderSuccess(data.data[0]));
    } catch (error) {
      dispatch(loadGroupHeaderError(error));
    }
  };
};

const loadGroupHeader = () => ({
  type: LOAD_GROUP_HEADER,
  payload: true,
});

const loadGroupHeaderSuccess = (data) => ({
  type: LOAD_GROUP_HEADER_SUCCESS,
  payload: data,
});

const loadGroupHeaderError = (status) => ({
  type: LOAD_GROUP_HEADER_ERROR,
  payload: status,
});

//Group in charge
export const loadGroupInChargeAction = (user_id) => {
  return async (dispatch) => {
    dispatch(loadGroupInCharge());
    try {
      const data = await axiosGroup.post("/group-in-charge", user_id);
      dispatch(loadGroupInChargeSuccess(data.data[0]));
    } catch (error) {
      dispatch(loadGroupInChargeError(error));
    }
  };
};

const loadGroupInCharge = () => ({
  type: LOAD_GROUP_IN_CHARGE,
  payload: true,
});

const loadGroupInChargeSuccess = (data) => ({
  type: LOAD_GROUP_IN_CHARGE_SUCCESS,
  payload: data,
});

const loadGroupInChargeError = (status) => ({
  type: LOAD_GROUP_IN_CHARGE_ERROR,
  payload: status,
});

//Next Hikes
export const loadGroupNextHikesAction = (user_id) => {
  return async (dispatch) => {
    dispatch(loadGroupNextHikes());
    try {
      const data = await axiosGroup.post("/group-next-hikes", user_id);
      dispatch(loadGroupNextHikesSuccess(data.data));
    } catch (error) {
      dispatch(loadGroupNextHikesError(error));
    }
  };
};

const loadGroupNextHikes = () => ({
  type: LOAD_GROUP_NEXT_HIKES,
  payload: true,
});

const loadGroupNextHikesSuccess = (data) => ({
  type: LOAD_GROUP_NEXT_HIKES_SUCCESS,
  payload: data,
});

const loadGroupNextHikesError = (status) => ({
  type: LOAD_GROUP_NEXT_HIKES_ERROR,
  payload: status,
});
