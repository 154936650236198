import React, { useState, useEffect } from "react";
import {useLocation} from "react-router-dom";
import { getGroupDetailcard } from "../../services/cards.service";
import { getHikerHeaderBack } from "../../services/hikerUser.service";
import { formatLuxon } from "../../helpers/basicFunctions";
import { calculateAge } from "../../helpers/dateHelper";
export const CardsHikerDetails = (props) => {
  const [data, setData] = useState({});
  const location = useLocation();
  console.log("location CARD DETAILS")
  console.log(location)
  const [hiker_user_id, setHikerUserId] = useState(location.state.data.hiker_user_id)
  //const hiker_user_id = useLocation().state.data.hiker_user_id;

  useEffect(() => {
    /*getGroupDetailcard(hiker_user_id)
      .then((resp) => {
        setData(resp.data);
        console.log("data", resp.data);
      })
      .catch((e) => console.log(e));*/

    getHikerHeaderBack(hiker_user_id)
      .then((resp) => {
        setData(resp.data[0][0]);
      })
      .catch((e) => console.log("Error: ",e));
  }, [hiker_user_id, hiker_user_id]);

  return (
    <div>
      <div className="row">
        {/* Grupos Activos */}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-success shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Grupo
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {data?.Grupo || "Sin grupo asignado"}
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-users fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Estado de grupo */}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Estado en grupo
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {data.Estado_en_Grupo}
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-calendar fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Pasos caminados*/}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-info shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                    Pasos totales Registrados
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-auto">
                      <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                        {data?.Pasos_Caminados || 0}
                      </div>
                    </div>
                    {/* <div className="col"> 
                      <div className="progress progress-sm mr-2">
                        <div
                          className="progress-bar bg-info"
                          role="progressbar"
                          // style="width: 50%"
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>*/}
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-walking fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Progreso programa */}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-warning shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                    Progreso programa
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {Math.trunc(data?.progress_program) || 0} %
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-percentage fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {/* Asistencia */}
        {/* <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-success shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Asistencias caminatas
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {data?.Asistencias <= 0
                      ? "Sin asistencias registradas"
                      : data?.Asistencias + "/" + data?.Asistencias_totales}
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-calendar-check fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/*  Edad */}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Edad
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {calculateAge(data.hiker_user_born_date) <= 0
                      ? "No definida"
                      : calculateAge(data.hiker_user_born_date) + " Años"}
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-calendar-day fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Meta diaria */}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-info shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                    {data?.Last_meta
                      ? "Ultima meta diaria: " + formatLuxon(data.Last_meta)
                      : "Ultima meta diaria"}
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-auto">
                      <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                        {data?.meta_diaria
                          ? data?.meta_diaria + " pasos"
                          : "No registrada"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-shoe-prints fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Menta mensual */}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-warning shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                    Meta mensual:
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {data.steps_monthly_goal || "Sin definir"}
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-flag-checkered fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
