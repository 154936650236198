import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatLuxon } from "../../helpers/basicFunctions";
import "./style.css";

export const ListManualSteps = (props) => {
  const { title, list } = props;
  return (
    <div>
      <h4>Registro de {title}</h4>
      <hr />
      <div className="cont-item m-1">
        {list?.length > 0 ? (
          <div>
            <table
              className="table table-bordered table-striped"
              id="dataTable"
              width="100%"
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th>Día</th>
                  <th>Pasos diarios</th>
                  <th>¿Salí a caminar?</th>
                  <th>Minutos</th>
                  <th>¿Solo o en grupo?</th>
                  <th>¿Cuánto me cansé?</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: 14 }}>
                {list.map((item, index) => {
                  return (
                    <tr key={item?.hiker_user_record_manual_id}>
                      <th style={{ width: 200 }}>
                        {formatLuxon(item?.hiker_user_record_manual_date)}
                      </th>
                      <th>{item?.hiker_user_record_manual_steps}</th>
                      <th>
                        {item?.hiker_user_record_manual_out_walk === 1
                          ? "Si"
                          : "No"}
                      </th>
                      <th>{item?.hiker_user_record_manual_minutes_walked}</th>
                      <th>
                        {item?.hiker_user_record_manual_in_group === 1
                          ? "Si"
                          : "No"}
                      </th>
                      <th>
                        {
                          item?.hiker_user_record_manual_tiredness
                            .hiker_user_tiredness_state
                        }
                      </th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <h5>No hay datos disponibles</h5>
          </div>
        )}
      </div>
    </div>
  );
};
export const ListCheckHealth = (props) => {
  const { title, list } = props;
  return (
    <div className="  ">
      <h4>Evaluaciones de {title}</h4>
      <hr />
      <div className="cont-item m-5">
        {list?.check_health?.length > 0 ? (
          <div>
            <table
              className="table table-bordered table-striped"
              id="dataTable"
              width="100%"
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Fecha de chequeo</th>
                  <th>Opciones</th>
                </tr>
              </thead>

              {list.check_health.map((item, index) => (
                <tbody key={index}>
                  <tr>
                    <th>{item.check_health_id}</th>
                    <th>{formatLuxon(item.created_at)}</th>
                    <th>
                      <Link
                        to={{
                          pathname: `/user/chequeos`,
                          state: { data: item, render: "HikerNewCheckHealth" },
                        }}
                        className="btn btn-primary"
                      >
                        Visualizar
                      </Link>
                    </th>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <h5>No hay datos disponibles</h5>
          </div>
        )}
      </div>
    </div>
  );
};
export const ListConditionPhysical = (props) => {
  const { title, list } = props;
  return (
    <div className="  ">
      <h4>Evaluaciones de {title}</h4>
      <hr />
      <div className="cont-item m-5">
        {list?.fisc_balance?.length > 0 ? (
          <div>
            <table
              className="table table-bordered table-striped"
              id="dataTable"
              width="100%"
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Fecha de chequeo</th>
                  <th>Opciones</th>
                </tr>
              </thead>

              {list.fisc_balance.map((item, index) => (
                <tbody key={index}>
                  <tr>
                    <th>{item._id}</th>
                    {/* Aqui poner condición para tomar fecha de creación */}
                    {item.created_at2 != null ? 
                    <th>{formatLuxon(item.created_at2)}</th>
                    :
                    <th>{formatLuxon(item.created_at)}</th>
                    }
                    
                    <th>
                      <Link
                        to={{
                          pathname: `/user/chequeos`,
                          state: {
                            data: {...list.fisc_balance[index],...list.fisc_marcha[index], ...list.fisc_chair[index]},
                            render: "HikerNewPhysicalCondition",
                          },
                        }}
                        className="btn btn-primary"
                      >
                        Visualizar
                      </Link>
                    </th>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <h5>No hay datos disponibles</h5>
          </div>
        )}
      </div>
    </div>
  );
};
export const ListLifeQuality = (props) => {
  const { title, list } = props;
  return (
    <div className="  ">
      <h4>Evaluaciones de {title}</h4>
      <hr />
      <div className="cont-item m-5">
        {list?.life_style?.length > 0 ? (
          <div>
            <table
              className="table table-bordered table-striped"
              id="dataTable"
              width="100%"
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Fecha de chequeo</th>
                  <th>Opciones</th>
                </tr>
              </thead>

              {list.life_style.map((item, index) => (
                <tbody key={index}>
                  <tr>
                    <th>{item.life_style_init_id}</th>
                    <th>{formatLuxon(item.created_at)}</th>
                    <th>
                      <Link
                        to={{
                          pathname: `/user/chequeos`,
                          state: { data: item, render: "HikerNewLifeQuality" },
                        }}
                        className="btn btn-primary"
                      >
                        Visualizar
                      </Link>
                    </th>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <h5>No hay datos disponibles</h5>
          </div>
        )}
      </div>
    </div>
  );
};
export const ViewRegistration = (props) => {
  const [data, setData] = useState(props.data);
  const [title, seTitle] = useState(props.title);
  const [type, setType] = useState(props.type);

  const [list, setList] = useState([]);
  useEffect(() => {
    setData(props.data);
    seTitle(props.title);
    setType(props.type);
    setList(data);
  }, [data, props.data, props.title, props.type]);
  return (
    <div className="container bg-white p-4" style={{ width: 800, height: 600 }}>
      {(() => {
        switch (type) {
          case "checkHealth":
            return <ListCheckHealth title={title} list={list} />;
          case "checkPhysicalCondition":
            return <ListConditionPhysical title={title} list={list} />;
          case "checkLifeQuality":
            return <ListLifeQuality title={title} list={list} />;
          case "manualSteps":
            return <ListManualSteps title={title} list={list} />;
          default:
            return null;
        }
      })()}
    </div>
  );
};
