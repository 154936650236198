import { axiosNews } from '../config/axios';

const postNews = (data) => {
    return axiosNews.post(`/create-news`, data);
  };
const getListInformation = () =>{
  return axiosNews.get(`/list-news?page=1&size=3&sortBy=Information_created_at&order=0`);
}
export{
    postNews,
    getListInformation
}