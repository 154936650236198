import {
  LOAD_HIKER_LIST,
  LOAD_HIKER_LIST_SUCCESS,
  LOAD_HIKER_LIST_ERROR,
  LOAD_HIKER_HEADER,
  LOAD_HIKER_HEADER_SUCCESS,
  LOAD_HIKER_HEADER_ERROR,
  LOAD_HIKER_MANUAL_REGISTER,
  LOAD_HIKER_MANUAL_REGISTER_SUCCESS,
  LOAD_HIKER_MANUAL_REGISTER_ERROR,
  LOAD_HIKER_EDIT,
  LOAD_HIKER_EDIT_SUCCESS,
  LOAD_HIKER_EDIT_ERROR,
  LOAD_HIKER_CHECK_HEALTH,
  LOAD_HIKER_CHECK_HEALTH_SUCCESS,
  LOAD_HIKER_CHECK_HEALTH_ERROR,
  LOAD_HIKER_QUALITY_LIFE,
  LOAD_HIKER_QUALITY_LIFE_SUCCESS,
  LOAD_HIKER_QUALITY_LIFE_ERROR,
  LOAD_HIKER_ENTRY_MOTIVATION,
  LOAD_HIKER_ENTRY_MOTIVATION_SUCCESS,
  LOAD_HIKER_ENTRY_MOTIVATION_ERROR,
} from '../types';

import {
  getHikersList,
  getHikersListSys,
  getHikerHeaderBack,
  getHikerManualRegisterBack,
  getHikerCheckHealthBack,
  getHikerQualityLifeBack,
  getHikerEntryMotivationBack,
} from '../../services/hikerUser.service';

//LOAD HIKER LIST
export function getHikerListAction(data) {
  return async (dispatch) => {
    dispatch(getHikerList());
    try {
      const response = await getHikersList(data);
      dispatch(getHikerListSuccess(response.data));
    } catch (error) {
      dispatch(getHikerListError(error));
    }
  };
}

const getHikerList = () => ({
  type: LOAD_HIKER_LIST,
  payload: true,
});

const getHikerListSuccess = (user) => ({
  type: LOAD_HIKER_LIST_SUCCESS,
  payload: user,
});

const getHikerListError = (status) => ({
  type: LOAD_HIKER_LIST_ERROR,
  payload: status,
});

//LOAD HIKER LIST AS ADMIN
export function getHikerListSysAction(options = null) {
  
  return async (dispatch) => {
    dispatch(getHikerListSys());
    try {
      const response = await getHikersListSys(options);
      dispatch(getHikerListSysSuccess(response.data));
    } catch (error) {
      dispatch(getHikerListSysError(error));
    }
  };
}

const getHikerListSys = () => ({
  type: LOAD_HIKER_LIST,
  payload: true,
});

const getHikerListSysSuccess = (user) => ({
  type: LOAD_HIKER_LIST_SUCCESS,
  payload: user,
});

const getHikerListSysError = (status) => ({
  type: LOAD_HIKER_LIST_ERROR,
  payload: status,
});

//HikerHeader
export function getHikerHeaderAction(data) {
  return async (dispatch) => {
    dispatch(getHikerHeader());
    try {
      const response = await getHikerHeaderBack(data);
      dispatch(getHikerHeaderSuccess(response.data[0][0]));
    } catch (error) {
      dispatch(getHikerHeaderError(error));
    }
  };
}

const getHikerHeader = () => ({
  type: LOAD_HIKER_HEADER,
  payload: true,
});

const getHikerHeaderSuccess = (user) => ({
  type: LOAD_HIKER_HEADER_SUCCESS,
  payload: user,
});

const getHikerHeaderError = (status) => ({
  type: LOAD_HIKER_HEADER_ERROR,
  payload: status,
});
//HikerManualRegister
export function getHikerManualRegisterAction(data) {
  return async (dispatch) => {
    dispatch(getHikerManualRegister());
    try {
      const response = getHikerManualRegisterBack(data);
      dispatch(getHikerManualRegisterSuccess(response.data[0][0]));
    } catch (error) {
      dispatch(getHikerManualRegisterError(error));
    }
  };
}

const getHikerManualRegister = () => ({
  type: LOAD_HIKER_MANUAL_REGISTER,
  payload: true,
});

const getHikerManualRegisterSuccess = (user) => ({
  type: LOAD_HIKER_MANUAL_REGISTER_SUCCESS,
  payload: user,
});

const getHikerManualRegisterError = (status) => ({
  type: LOAD_HIKER_MANUAL_REGISTER_ERROR,
  payload: status,
});
//HikerEdit
export function getHikerEditAction(data) {
  return async (dispatch) => {
    dispatch(getHikerEdit());
    try {
      dispatch(getHikerEditSuccess(data));
    } catch (error) {
      dispatch(getHikerEditError(error));
    }
  };
}

const getHikerEdit = () => ({
  type: LOAD_HIKER_EDIT,
  payload: true,
});

const getHikerEditSuccess = (user) => ({
  type: LOAD_HIKER_EDIT_SUCCESS,
  payload: user,
});

const getHikerEditError = (status) => ({
  type: LOAD_HIKER_EDIT_ERROR,
  payload: status,
});
//HikerCheckHealth
export function getHikerCheckHealthAction(data) {
  return async (dispatch) => {
    dispatch(getHikerCheckHealth());
    try {
      const response = await getHikerCheckHealthBack(data);
      dispatch(getHikerCheckHealthSuccess(response.data[0][0]));
    } catch (error) {
      dispatch(getHikerCheckHealthError(error));
    }
  };
}

const getHikerCheckHealth = () => ({
  type: LOAD_HIKER_CHECK_HEALTH,
  payload: true,
});

const getHikerCheckHealthSuccess = (user) => ({
  type: LOAD_HIKER_CHECK_HEALTH_SUCCESS,
  payload: user,
});

const getHikerCheckHealthError = (status) => ({
  type: LOAD_HIKER_CHECK_HEALTH_ERROR,
  payload: status,
});
//HikerQualityLife
export function getHikerQualityLifeAction(data) {
  return async (dispatch) => {
    dispatch(getHikerQualityLife());
    try {
      const response = await getHikerQualityLifeBack(data);
      dispatch(getHikerQualityLifeSuccess(response.data[0][0]));
    } catch (error) {
      dispatch(getHikerQualityLifeError(error));
    }
  };
}

const getHikerQualityLife = () => ({
  type: LOAD_HIKER_QUALITY_LIFE,
  payload: true,
});

const getHikerQualityLifeSuccess = (user) => ({
  type: LOAD_HIKER_QUALITY_LIFE_SUCCESS,
  payload: user,
});

const getHikerQualityLifeError = (status) => ({
  type: LOAD_HIKER_QUALITY_LIFE_ERROR,
  payload: status,
});
//HikerEntryMotivation
export function getHikerEntryMotivationAction(data) {
  return async (dispatch) => {
    dispatch(getHikerEntryMotivation());
    try {
      const response = await getHikerEntryMotivationBack(data);
      dispatch(getHikerEntryMotivationSuccess(response.data[0][0]));
    } catch (error) {
      dispatch(getHikerEntryMotivationError(error));
    }
  };
}

const getHikerEntryMotivation = () => ({
  type: LOAD_HIKER_ENTRY_MOTIVATION,
  payload: true,
});

const getHikerEntryMotivationSuccess = (user) => ({
  type: LOAD_HIKER_ENTRY_MOTIVATION_SUCCESS,
  payload: user,
});

const getHikerEntryMotivationError = (status) => ({
  type: LOAD_HIKER_ENTRY_MOTIVATION_ERROR,
  payload: status,
});
