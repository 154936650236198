import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  Alert
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginService } from "../../../services/login.service";
import { getUsersService } from "../../../services/users.service";
import { checkRut } from "../../../helpers/basicFunctions";
import "../Login/style.css";

import {
  logedUserAction,
  getUsersAction,
} from "../../../redux/actions/userActions";
import { authenticatedAction } from "../../../redux/actions/authenticatedAction";
import {
  getHikerListSysAction,
  getHikerListAction,
} from "../../../redux/actions/hikerAction";
import {
  getGroupListAction,
} from "../../../redux/actions/groupAction";
const defaultState = {
  rut: "",
  password: "",
};

export const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState(defaultState);
  const [alertMessage, setAlertMessage] = useState('');
  const [sweetAlert, setSwetAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeAlert = () => {
    setSwetAlert(false);
  };

  const validateUserName = (e) => {
    setState({ ...state, rut: checkRut(e.target.value) });
  };

  const changeHandler = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (state.rut === '' || state.password === '') {

      console.log("Ingrese rut y contraseña");
      setAlertMessage('Ingrese rut y contraseña');
      setSwetAlert(true);

    } else {
      setLoading(true);
      const credentials = {
        sys_user_rut: state.rut,
        sys_user_password: state.password,
      };
      try {
        const res = await loginService(credentials);

        console.log("response");
        console.log(res);
        
        const userList = await getUsersService();
        const userListData = userList.data;
        const userData = res.data.userData;

        if (userData.rol_sys_user_rol_sys_user_id === 5) {
          await dispatch(getHikerListSysAction());
          await dispatch(getGroupListAction(userData.sys_user_id));

        }
        if (userData.rol_sys_user_rol_sys_user_id === 15) {
          const userId = {
            sys_user_id: userData.rol_sys_user_rol_sys_user_id,
          };
          await dispatch(getHikerListAction(userId));
          await dispatch(getGroupListAction(userData.sys_user_id));

        }
        const auth = res.data.auth;
        await dispatch(getUsersAction(userListData));
        await dispatch(logedUserAction(userData));
        await dispatch(authenticatedAction(auth));

      } catch (error) 
      {
        setLoading(false);
        setAlertMessage(error.response.data.message);
        setSwetAlert(true);
        return false;
      }
      history.push("/user/home");
    }
  };




  return (
    <Container>
      {sweetAlert ? (
        <SweetAlert
        danger
        confirmBtnText="Continuar"
        confirmBtnBsStyle="danger"
        title="Atención!"
        onConfirm={() => closeAlert()}
        focusCancelBtn
      >
        {alertMessage}
      </SweetAlert>
      ) : null}
      <Row className="row justify-content-center">
        <Col className="col-xl-10 col-lg-12 col-md-9">
          <Card className="card o-hidden border-0 shadow-lg my-5">
            <CardBody className="card-body p-0">
              <Row className="row">
                <Col className="col-lg-6 d-none d-lg-block bg-login-image"></Col>
                <Col className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">
                        Ingreso Profesional
                      </h1>
                    </div>
                    <Form onSubmit={submitHandler} role="form" className="user">
                      <FormGroup className="form-group">
                        <Input
                          name="rut"
                          type="rut"
                          className="form-control form-control-user "
                          id="InputRut"
                          aria-describedby="RutHelp"
                          placeholder="Ingrese su Rut"
                          onChange={changeHandler}
                          value={state.rut}
                          onBlur={(e) => validateUserName(e)}
                        />
                      </FormGroup>
                      <FormGroup className="form-group">
                        <Input
                          name="password"
                          type="input"
                          className="form-control form-control-user"
                          id="Password"
                          placeholder="Contaseña"
                          onChange={changeHandler}
                        />
                      </FormGroup>
                      <FormGroup className="form-group">
                        <div className="custom-control custom-checkbox small">
                          <Input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck"
                          />
                          {/*
                          <Label
                            className="custom-control-label"
                            for="customCheck"
                          >
                            Recordarme
                          </Label>
                          */}
                        </div>
                      </FormGroup>
                      {loading ? (
                        <div className="row justify-content-center">
                          <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={40}
                            width={40}
                          // timeout={3000} //3 secs
                          />
                        </div>
                      ) : (
                        <button className="btn btn-primary btn-user btn-block">
                          Ingresar
                        </button>
                      )}
                      <hr />
                    </Form>

                    <div className="text-center mt-3">
                      <Link to="/auth/recovery">
                        <div className="a small">Recuperar Contraseña</div>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
