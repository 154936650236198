import React, { useState } from "react";
import {setLimitDateInput} from "../../../helpers/dateHelper"
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  validateEmail,
  checkRut,
  dateToString,
} from "../../../helpers/basicFunctions";
import { createProfileService } from "../../../services/users.service";

export const NewProffesional = () => {
  const history = useHistory();

  const [rut, setRut] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [mail, setMail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [tipo, setTipo] = useState("");
  const [bornDate, setBornDate] = useState(dateToString(new Date()));
  const [button_disabled, setBtnDisabled] = useState(false);
  const [maxDate] = useState(setLimitDateInput());

  const [alert, setAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");

  const [updateAlert, setUpdateAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const formatEmail = (e) => {
    if (!validateEmail(e.target.value)) {
      setMail("");
      setMessageAlert("Debe ingresar un correo valido");
      setAlert(true);
    }
    setTimeout(() => {
      setAlert(false);
    }, 10000);
  };
  const validateUserName = (e) => {
    setRut(checkRut(e.target.value));
  };

  const submitHandler = () => {
    const tipoInt = tipo === "Kinesiologo" ? 15 : 5;

    const data = {
      sys_user_rut: rut,
      sys_user_name: name,
      sys_user_lastname: lastname,
      rol_sys_user_rol_sys_user_id: tipoInt,
      sys_user_email: mail,
      sys_user_phone: telefono,
      sys_user_born_date: bornDate,
    };
    if (
      rut === "" ||
      name === "" ||
      lastname === "" ||
      mail === "" //||
      //telefono === "" ||
      //tipo === "" ||
      //bornDate === ""
    ) {
      setMessageAlert("Debe rellenar todos los campos requeridos (*)");
      setAlert(true);

      setTimeout(() => {
        setAlert(false);
      }, 10000);
    } else {
      setBtnDisabled(true);
      createProfileService(data)
        .then((res) => {
          setBtnDisabled(false);
          setUpdateAlert(true);
          setTimeout(() => {
            setUpdateAlert(false);
            history.push("/user/home");
          }, 3000);
        })
        .catch((err) => {
          if (err.response.data.message) {
            setBtnDisabled(false);
            setErrorMessage(err.response.data.message);
          } else {
            setBtnDisabled(false);
            setErrorMessage("Hubo un error al crear usuario!");
          }
          setBtnDisabled(false);
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 3000);
        });
    }
  };

  const onConfirm = () => {
    setUpdateAlert(false);
    setErrorAlert(false);
    history.push("/proffesional-list/");
  };

  const onConfirmError = () => {
    setUpdateAlert(false);
    setErrorAlert(false);
  };

  const closeAlert = () => {
    setAlert(false);
  }

  return (
    <>
      {errorAlert ? (
        <SweetAlert
          warning
          title="Ocurrio un problema!"
          onConfirm={onConfirmError}
          // onCancel={this.onCancel}
          timeout={3000}
        >
          {errorMessage}
        </SweetAlert>
      ) : null}
      {updateAlert ? (
        <SweetAlert
          success
          title="Creación exitosa!"
          onConfirm={onConfirm}
          // onCancel={this.onCancel}
          timeout={3000}
        >
          Se ha creado el usuario correctamente!
        </SweetAlert>
      ) : null}
      {alert ? (
        <SweetAlert
          danger
          title="Atención!"
          onConfirm={closeAlert}
          color="danger"
        >
          {messageAlert}
          </SweetAlert>
      ) : null}
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800">Nuevo Profesional</h1>
        <div className="row">
          <div className="col-lg-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Datos</h6>
              </div>
              <div className="card-body">
                <p>Los campos con * son obligatorios</p>
                <form>
                  <div className="row">
                    {/* NOMBRE */}
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1">Rut*</label>
                        <input
                          name="rut"
                          type="rut"
                          className="form-control form-control-user"
                          id="InputRut"
                          aria-describedby="RutHelp"
                          placeholder="Ingrese su Rut"
                          onBlur={(e) => validateUserName(e)}
                          value={rut}
                          onChange={(e) => setRut(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* NOMBRE */}
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1">Nombre*</label>
                        <input
                          className="form-control form-control-solid"
                          id="nombreInput"
                          type="text"
                          placeholder="Ingrese su Nombre"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* APELLIDO */}
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1">
                          Apellido*
                        </label>
                        <input
                          className="form-control form-control-solid"
                          id="apellidoInput"
                          type="text"
                          placeholder="Ingrese su Apellido"
                          value={lastname}
                          onChange={(e) => setLastname(e.target.value)}
                        />
                        {/* <small id="nameInput" className="form-text text-muted">
                          Alerta: Debe ingresar apellido
                        </small> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* TIPO USUARIO */}
                    <div className="col-md-3">
                      <label htmlFor="exampleFormControlInput1">
                        Tipo usuario*
                      </label>
                      <select
                        name=""
                        id=""
                        className="form-control"
                        onChange={(e) => setTipo(e.target.value)}
                      >
                        <option value="Administrador">Administrador</option>
                        <option value="Kinesiologo">Kinesiólogo</option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1">Correo*</label>
                        <input
                          className="form-control form-control-solid"
                          id="CorreoInput"
                          type="text"
                          placeholder="Ingrese su Correo"
                          value={mail}
                          onChange={(e) => setMail(e.target.value)}
                          onBlur={(e) => formatEmail(e)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1">
                          Télefono
                        </label>
                        <div>
                          <input
                            className="form-control form-control-phone-ext"
                            id="TelefonoExtInput"
                            type="number"
                            placeholder="+569"
                            disabled
                          />
                          <input
                            className="form-control form-control-phone-number"
                            id="TelefonoNumInput"
                            type="number"
                            placeholder="Ingrese su Telefono"
                            value={telefono}
                            onChange={(e) => setTelefono(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1">
                          Fecha nacimiento
                        </label>
                        <input
                          className="form-control form-control-solid"
                          id="fechaNacimientoInput"
                          type="date"
                          value={bornDate}
                          max={maxDate}
                          onChange={(e) => setBornDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row float-right mx-auto">
                    <div className="col-md-12">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={submitHandler}
                        disabled={button_disabled}
                      >
                        Crear
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
