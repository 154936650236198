import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { HikerNewCheckHealth } from "../../pages/Hikers/NewHiker/components/HikerNewCheckHealth";
import { HikerNewPhysicalCondition } from "../../pages/Hikers/NewHiker/components/HikerNewPhysicalCondition";
import { HikerNewLifeQuality } from "../../pages/Hikers/NewHiker/components/HikerNewLifeQuality";
import { HikerNewEntryMotivation } from "../../pages/Hikers/NewHiker/components/HikerNewEntryMotivation";
import "./style.css";

export const ViewContent = () => {
  const location = useLocation();
  const [data, setData] = useState(location.state.data);
  const [render, setRender] = useState(location.state.render);

  useEffect(() => {
    setData(location.state.data);
    setRender(location.state.render);
  }, [location.state.data, location.state.render, setData, setRender]);

  return (
    <div className="container">
      {(() => {
        switch (render) {
          case "HikerNewCheckHealth":
            return (
              <HikerNewCheckHealth data={data} option={{ disable: true }} />
            );
          case "HikerNewPhysicalCondition":
            return (
              <HikerNewPhysicalCondition
                data={data}
                option={{ disable: true }}
              />
            );
          case "HikerNewLifeQuality":
            return (
              <HikerNewLifeQuality data={data} option={{ disable: true }} />
            );
          case "HikerNewEntryMotivation":
            return (
              <HikerNewEntryMotivation
                data={data.motivation}
                option={{ disable: true }}
              />
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};
