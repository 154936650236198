import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducer from "../redux/reducers";
import { persistStore } from "redux-persist";

export const store =
  reducer &&
  createStore(
    reducer,
    compose(
      applyMiddleware(thunk),
      typeof window === "object" &&
        typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== "undefined"
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  );

export const persistor = persistStore(store);
