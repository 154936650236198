import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { createNewGroup } from "../../../services/group.service";
import { getAllHikersListSys, getHikersByUser, getHikersListSys } from "../../../services/hikerUser.service";

export const NewGroup = () => {
  const history = useHistory();
  const [groupName, setGroupName] = useState("");
  const [Members, setMembers] = useState("");
  const [leaders, setLeaders] = useState([]);
  const [optionSysUsers, setOptionSysUsers] = useState([]);
  const [option, setOption] = useState([]);
  const [DateInit, setDateInit] = useState("");
  const [alert, setAlert] = useState([{type: "warning", title: null, message: null, display: false}]);
  const user = useSelector((state) => state.user.user);
  const maxSySValuesAllowed = 3 // change this if necessary
  const added_days = 30 // change this

  useEffect(() => {
    getHikersByUser()
      .then((resp) => {
        let option = [];
        resp.data.map((e) => {
          option.push({
            value: e.hiker_user_id,
            label: `${e.hiker_user_name} ${e.hiker_user_lastname}  ${e.hiker_user_rut}`,
          });
        });

        setOption(option);
      })
      .catch((e) => {
        console.log(e);
      });

      getAllHikersListSys()
      .then((resp) => {
        console.log('resp', resp);
        let option = [];
        resp.data.map((e) => {
          option.push({
            value: e.sys_user_id,
            label: `${e.sys_user_name} ${e.sys_user_lastname}`,
          });
        });

        setOptionSysUsers(option);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const selectMembers = async (select) => {
    let data = [];
    select.map((e) => {
      data = [...data, e.value];
    });
    setMembers(data);
  };

  const selectLeaders = async (select) => {
    if (select && select.length <= maxSySValuesAllowed) {
      let data = [];
      select.map((e) => {
        data = [...data, e.value];
      });
      setLeaders(data);
    }
  };
  const registerBtn = async () => {
    let data_group = {
      group_name: groupName,
      sys_user_sys_user_id: leaders,
      group_state_program_date_start: DateInit,
      added_days: added_days,
      members: Members,
    };
    await createNewGroup(data_group)
      .then((resp) => {
        if (resp.status === 201) {

          setAlert({type: "success", title: 'Listo!', message: 'Grupo creado con exito', display: true});

          setTimeout(() => {
            history.push("/user/group-manager");
          }, 2000);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setAlert({type: "danger", title: 'Atención!', message: error.response.data.errorMessage, display: true});
        }
      });

  };
  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">Nuevo Grupo</h1>

      <div className="col-lg-12">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">
              Datos del nuevo grupo
            </h6>
          </div>
          <div className="card-body">
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1">
                Nombre del grupo:
              </label>
              <input
                type="text"
                className="form-control form-control-solid"
                placeholder="Ingrese nombre del grupo"
                onChange={(e) => setGroupName(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1">
                Seleciona los lideres del grupo:
              </label>
              <Select
                isMulti
                options={optionSysUsers}
                closeMenuOnSelect={false}
                placeholder="Selecciona aquí"
                va
                onChange={(selected) => selectLeaders(selected)}
                noOptionsMessage={() =>  <a href='/user/new-hiker'> Añadir nuevos participantes </a>}
                //noOptionsMessage={() => "No hay participantes sin grupo"}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1">
                Seleciona los participantes del grupo:
              </label>
              <Select
                isMulti
                options={option}
                closeMenuOnSelect={false}
                placeholder="Selecciona aquí"
                onChange={(selected) => selectMembers(selected)}
                noOptionsMessage={() =>  <a href='/user/new-hiker'> Añadir nuevos participantes </a>}
                //noOptionsMessage={() => "No hay participantes sin grupo"}
              />
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1">
                    Fecha de inicio de grupo:
                  </label>
                  <input
                    type="date"
                    className="form-control form-control-solid"
                    onChange={(e) => setDateInit(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="row float-right mx-auto">
              {alert.display ?
                <SweetAlert
                type={alert.type}
                title={alert.title}
                onConfirm={param => setAlert({'type': 'danger', 'title': null, 'message' : null, 'display': false})}
              >
                {alert.message}
              </SweetAlert>
              : null
              }
              <div className="col-md-12">
                <button
                  onClick={registerBtn}
                  className={`btn btn-primary`}
                >
                  Guardar cambios
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
