import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import { IoIosClose } from "react-icons/io";

import { CardsHikerDetails } from "../../../../components/CardsDetails/CardsHikerDetails";
import { InfoHiker } from "./InfoHiker";
import { ViewRegistration } from "../../../../components/ViewRegistration/ViewRegistration";
import { ChequeoCondicionVida } from "./ChequeoCondicionVida";
import { ChequeoCondicionFisica } from "./ChequeoCondicionFisica";
import { ChequeoSalud } from "./ChequeoSalud";
import {
  getHealthExaminations,
  getHikerManualRegisterBack,
  getHikerInformation,
} from "../../../../services/hikerUser.service";

export const HikerDetails = () => {
  const location = useLocation();
  const [data, setData] = useState(location.state.data || {});

  const [modalIsOpen, setIsOpen] = useState(false);
  const [render, setRender] = useState(null);
  const [info, setInfo] = useState({});

  const [dataCheks, setDataCheks] = useState(null);
  const [manualSteps, SetManualSteps] = useState(null);
  const [titleView, setTitleView] = useState("Salud");

  const [typeExamination, setTypeExamination] = useState(null);
  function capitalize(word) {
    if (word) {
      return word[0].toUpperCase() + word.slice(1);
    }
  }
  useEffect(() => {
    if (data?.hiker_user_id) {
      callsBackend(data?.hiker_user_id);
    }
  }, [data?.hiker_user_id]);

  const callsBackend = (hiker_user_id) => {

    getHikerManualRegisterBack(hiker_user_id)
      .then((resp) => {
        SetManualSteps(resp.data);
      })
      .catch((e) => console.log(e));

    getHealthExaminations(hiker_user_id)
      .then((resp) => {
        setDataCheks(resp.data);
      })
      .catch((e) => console.log(e));

    getHikerInformation(hiker_user_id)
      .then((resp) => {
        setInfo(resp.data);
      })
      .catch((e) => console.log(e));
  };
  const openModal = (component) => {
    setIsOpen(true);
    setRender(component);
  };
  const openModalExaminations = (component, examination, title) => {
    setIsOpen(true);
    setRender(component);
    setTitleView(title);
    setTypeExamination(examination);
  };
  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="container-fluid">
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
      >
        {(() => {
          switch (render) {
            case "ChequeoCondicionVida":
              return <ChequeoCondicionVida data={info} />;
            case "ChequeoCondicionFisica":
              return <ChequeoCondicionFisica data={info} />;
            case "ChequeoSalud":
              return <ChequeoSalud data={info} />;
            case "ViewRegistration":
              return (
                <ViewRegistration
                  data={dataCheks}
                  title={titleView}
                  type={typeExamination}
                />
              );
            case "ViewManualSteps":
              return (
                <ViewRegistration
                  data={manualSteps}
                  title={titleView}
                  type={typeExamination}
                />
              );
            default:
              return null;
          }
        })()}
      </Modal>

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">
          Caminante: {capitalize(info?.hiker_user_name)}{" "}
          {capitalize(info?.hiker_user_lastname)}
        </h1>
      </div>
      
        <CardsHikerDetails hiker_user_id={info?.hiker_user_id} />
  
      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3 mb-4">
              <h6 className="m-0 font-weight-bold text-primary ">
                Registro de pasos manual
              </h6>
            </div>
            <div className="col-lg-12 mb-4">
              <Link
                className="btn btn-primary col-md-3 offset-md-1"
                to={{
                  pathname: `/user/step-log`,
                  state: { data: info },
                }}
              >
                Nuevo Registro
              </Link>

              <button
                className="btn btn-primary col-md-3 offset-md-1"
                onClick={() =>
                  openModalExaminations(
                    "ViewManualSteps",
                    "manualSteps",
                    "pasos"
                  )
                }
              >
                Ver Registros
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Ficha de Caminante
              </h6>
            </div>
            <InfoHiker data={info} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3 mb-4">
              <h6 className="m-0 font-weight-bold text-primary ">
                Chequeo de salud
              </h6>
            </div>
            <div className="col-lg-12 mb-4">
              <button
                className="btn btn-primary col-md-3 offset-md-1"
                onClick={() => openModal("ChequeoSalud")}
              >
                Nuevo chequeo de salud
              </button>
              <button
                className="btn btn-primary col-md-3 offset-md-1"
                onClick={() =>
                  openModalExaminations(
                    "ViewRegistration",
                    "checkHealth",
                    "salud"
                  )
                }
              >
                Ver chequeo de salud
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3 mb-4">
              <h6 className="m-0 font-weight-bold text-primary ">
                Evaluaciones de condición física
              </h6>
            </div>
            <div className="col-lg-12 mb-4">
              <button
                className="btn btn-primary col-md-3 offset-md-1"
                onClick={() => openModal("ChequeoCondicionFisica")}
              >
                Nueva Evaluación
              </button>
              <button
                className="btn btn-primary col-md-3 offset-md-1"
                onClick={() =>
                  openModalExaminations(
                    "ViewRegistration",
                    "checkPhysicalCondition",
                    "condición física"
                  )
                }
              >
                Ver Evaluaciones
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3 mb-4">
              <h6 className="m-0 font-weight-bold text-primary ">
                Evaluaciones de condición de vida
              </h6>
            </div>
            <div className="col-lg-12 mb-4">
              <button
                className="btn btn-primary col-md-3 offset-md-1"
                onClick={() => openModal("ChequeoCondicionVida")}
              >
                Nuevo chequeo de calidad de vida
              </button>

              <button
                className="btn btn-primary col-md-3 offset-md-1"
                onClick={() =>
                  openModalExaminations(
                    "ViewRegistration",
                    "checkLifeQuality",
                    "condición de vida"
                  )
                }
              >
                Ver Chequeos
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3 mb-4">
              <h6 className="m-0 font-weight-bold text-primary ">
                Motivación de entrada
              </h6>
            </div>
            <div className="col-lg-12 mb-4">
              <Link
                to={{
                  pathname: `/user/chequeos`,
                  state: { data: dataCheks, render: "HikerNewEntryMotivation" },
                }}
                className="btn btn-primary col-md-6 offset-md-1"
              >
                Ver Motivación de entrada
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
