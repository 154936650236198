import React, { useState, useEffect } from "react";

import { getDashboardCard } from "../../services/cards.service";

export const CardsDashboard = (props) => {
  const [data, setData] = useState({});
  const [formattedTotalSteps, setFormattedTotalSteps] = useState();


  useEffect(() => {
    getDashboardCard()
      .then((resp) => {
        resp.data.total_steps.toLocaleString('cl-CL', { style: 'decimal', maximumFractionDigits: 2 })
        setData(resp.data);
        setFormattedTotalSteps(data.total_steps.toLocaleString('cl-CL', { style: 'decimal', maximumFractionDigits: 2 }));
      })
      .catch((e) => console.log(e));
  }, [data.total_steps]);

  return (
    <div>
      <div className="row">
        {/* Caminantes Inscritos */}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Caminantes Inscritos
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {data.registered_hikers}
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-shoe-prints fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Grupos Activos */}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-success shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Grupos registrados
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {data.active_groups}
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-users fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Media de pasos por día */}
        {/*
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-info shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                    Media de pasos por día
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-auto">
                      <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                        {data.media_daily_steps}
                      </div>
                    </div>
                    <div className="col">
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-walking fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        */}
        {/* Pasos totales caminados */}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-warning shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                    Pasos totales registrados
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {formattedTotalSteps}
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-street-view fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
