import React, { useState, useEffect } from "react";
import { getDashboardCardKine } from "../../services/cards.service";
import { formatLuxon } from "../../helpers/basicFunctions";
export const CardDashboardKine = (props) => {
  const kine_id = props.sys_user_id;
  const [data, setData] = useState({});

  useEffect(() => {
    getDashboardCardKine(kine_id)
      .then((resp) => {
        setData(resp.data);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div className="card-body">
      <div className="row">
        {/*Mis grupos activos*/}
        <div className="col-xl-3 col-md-3 mb-4">
          <div className="card border-left-primary h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Mis grupos activos
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {data?.active_groups || 0}
                  </div>
                  <div className="col">
                    <div className="progress progress-sm mr-2">
                      <div
                        className="progress-bar bg-info"
                        role="progressbar"
                        // style="width: 50%"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="70"
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-walking fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Proxima Caminata*/}

        {/* <div className="col-xl-3 col-md-3 mb-4">
          <div className="card border-left-success h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Proxima Caminata
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {formatLuxon(data?.next_hike?.walks_date_ini) || 0}
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-shoe-prints fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* Media de pasos por día: */}
        {/* <div className="col-xl-3 col-md-3 mb-4">
          <div className="card border-left-info h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                    Media de pasos por día
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {data?.media_daily_steps || 0} pasos
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-calendar-check fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>*/}
        {/* Porcentaje de asistenci */}
        <div className="col-xl-3 col-md-3 mb-4">
          <div className="card border-left-info h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                    Porcentaje de asistencia
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {data?.rate_asistence || 0} %
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-calendar-check fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
