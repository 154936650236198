import { axiosGroup } from "../config/axios";

const createNewGroup = (data) => {
  return axiosGroup.post(`/group-create`, data);
};
const stateGroup = (grup_id, data) => {
  return axiosGroup.post(`/group-update-status/${grup_id}`, data);
};
const getGroup = (grup_id) => {
  return axiosGroup.get(`/group/${grup_id}`);
};
const getGroupName = (grup_id) => {
  return axiosGroup.get(`/group/name/${grup_id}`);
};
const RateAttendence = (sys_user_id) => {
  return axiosGroup.get(`/rate-attendence/${sys_user_id}`);
};
const getGroupList = (sys_user_id) => {
  return axiosGroup.get(`/group-list/${sys_user_id}?sortBy=group_state_program_date_start&order=0`);
};

const getAllGroupList = () => {
  return axiosGroup.get(`/group-list-all`);
}
const getGroupListMembers = (grup_id) => {
  return axiosGroup.get(`/group-list-members/${grup_id}`);
};

const updateGroupDetailsService = (data) => {
  return axiosGroup.put(`/update-group-details`, data);
  }

export {
  createNewGroup,
  getGroupListMembers,
  getGroupList,
  getAllGroupList,
  stateGroup,
  RateAttendence,
  getGroup,
  getGroupName,
  updateGroupDetailsService,
};
