import React, { useState, useRef, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { Container, Row, Col, } from "reactstrap";
import Select from "react-select";
import ReactExport from "react-export-excel";
import { Grid } from "react-loading-icons";
import DataTable from "react-data-table-component";
import HandlePage from "../../components/pagination/HandlePage";
import { useSelector } from "react-redux";
import "./reports.css";
import { useDispatch } from "react-redux";

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import {
  getGroupList,
  getAllGroupList,
  getGroupListMembers,
} from "../../services/group.service";
import { getReports, getAllReports, reportHandlePage } from "../../services/reports.service";
import { formatLuxon } from "../../helpers/basicFunctions";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const Reports = () => {
  const user = useSelector((state) => state.user.user);
  const [groups, setGroups] = useState([]);
  //Groups
  useEffect(() => {
    // admin
    if (user.rol_sys_user_rol_sys_user_id === 5) {
      getAllGroupList()
        .then((resp) => {
          const group = resp.data;
          const newFormat = group.map((item) => {
            return {
              value: item.group_id,
              label: item.group_name,
            };
          });
          setGroups(newFormat);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      getGroupList(user.sys_user_id)
        .then((resp) => {
          const group = resp.data;
          const newFormat = group.map((item) => {
            return {
              value: item.group_id,
              label: item.group_name,
            };
          });
          setGroups(newFormat);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  //Users
  const selectUsersRef = useRef();
  const [users, setUsers] = useState();
  const [fillUsers, setFillUsers] = useState();
  const [usersDisabled, setUsersDisabled] = useState(true);
  const [hikersSelecteds, setHikersSelecteds] = useState(null);
  //Reports
  const [reportsDisabled, setReportsDisabled] = useState(true);
  const [reportType] = useState([
    { value: 1, label: "Reporte de pasos caminados" },
    { value: 2, label: "Reporte de metas" },
  ]);
  const [selectedReport, setSelectedReport] = useState(0);

  // Steps walked
  const [steps_data, setStepsData] = useState({});
  const [all_steps_data, setAllStepsData] = useState({});
  const [report_goals, setReportGoals] = useState([]);
  const [all_report_goals, setAllReportGoals] = useState([]);
    
  const dispatch = useDispatch();
  // UI elements

  //datatable columns
  const columns_report_type_1 = [
    {
      name: "Usuario",
      selector: (row) => row.user_name,
      sortable: true,
    },
    {
      name: "Fecha",
      selector: (row) => row.user_date,
      sortable: true,
    },
    {
      name: "Pasos caminados",
      selector: (row) => row.steps_walked,
      sortable: true,
    },
    {
      name: "Meta Diaria",
      selector: (row) => row.daily_goal,
      sortable: true,
    },
  ];

  const columns_report_type_2 = [
    {
      name: "Usuario",
      selector: (row) => row.user_name,
      sortable: true,
    },
    {
      name: "Mes Actual",
      selector: (row) => row.current_month,
      sortable: true,
    },
    {
      name: "Meta mes pasado",
      selector: (row) => row.past_month_goal,
      sortable: true,
    },
    {
      name: "Meta pasada superada",
      selector: (row) => row.past_month_success,
      sortable: true,
    },
    {
      name: "Meta mensual presente",
      selector: (row) => row.current_month_goal,
      sortable: true,
    },
    {
      name: "Recomendación nueva meta mensual",
      selector: (row) => row.month_goal_recomnd,
      sortable: true,
    },
  ];

  //loading
  const [showLoading, setShowLoading] = useState(false);

  //tables
  const [showTablePasos, setShowTablePasos] = useState(false);
  const [showTableMetas, setShowTableMetas] = useState(false);

  const handleGroups = (selected) => {
    clearUsersSelected();
    if (!selected.value) {
      setUsersDisabled(true);
    }
    getGroupListMembers(selected.value)
      .then((resp) => {
        const newFormat = resp.data.map((item) => {
          return {
            value: item.hiker_user_id,
            label: `${selected.label} - ${item.hiker_user_name} ${item.hiker_user_lastname}`,
          };
        });
        setUsersDisabled(false);
        setFillUsers(newFormat);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const clearUsersSelected = () => {
    selectUsersRef.current.clearValue();
  };

  const handleUsers = (selected) => {
    if (selected.length > 0) {
      const selecteds = selected.map((item) => item.value);
      const data = { hikers: selecteds };
      setHikersSelecteds(data);
      setReportsDisabled(false);
    } else {
      setReportsDisabled(true);
    }
  };

  const generateReport = (type) => {
    //Limpiamos
    setStepsData({});
    setShowTablePasos(false);
    setShowTableMetas(false);

    // reporte Pasos
    if (selectedReport.value === 1) {
      getReports(hikersSelecteds, 1, 10)
        .then((resp) => {
          let hikers = resp.data;
          let newFormat = stepMaps(hikers);
          hikers = { ...hikers, data: newFormat };
          setStepsData(hikers);

        })
        .catch((e) => {
          console.log(e);
        });
      getAllReports(hikersSelecteds, 1)
        .then((resp) => {
          let all_hikers = resp;
          let newFormatAll = stepMaps(all_hikers);
          all_hikers = {...all_hikers, data: newFormatAll}
          setAllStepsData(all_hikers);
        })
        .catch((e) => {
          console.log(e);
        })

      setShowTablePasos(true);
    // reporte Metas
    } else if (selectedReport.value === 2) {
      getReports(hikersSelecteds, 2, 10)
        .then((resp) => {
          let hikers = resp.data;
          let newFormat = goalMaps(hikers);
          hikers = { ...hikers, data: newFormat };
          setReportGoals(hikers);
        })
        .catch((e) => {
          console.log(e);
        });
      setShowTableMetas(true);
    }
  };
  const changePage = async (url) => {
    const resp = await reportHandlePage(url, hikersSelecteds);
    if (resp) {
      let hikers = resp.data;
      let newFormat = stepMaps(hikers);
      hikers = { ...hikers, data: newFormat };
      if (selectedReport.value === 1) {
        setStepsData(hikers);
      }
      if (selectedReport.value === 2) {
        setReportGoals(hikers);
      }
    }
  };

  //Paginacion
  const handlePaginationNext = async(data) =>{
    if( data.currentPage < data.totalPages){      
      await data.currentPage++
      let page = data.currentPage;      
      let options = {
        page,
        size:2
      }
    dispatch(steps_data(options));

    }
  }

  const handlePaginationPrev = async(data) =>{
    if( data.currentPage > 1){
      await data.currentPage--
      let page = data.currentPage;  
      let options = {
        page,
        size:2
      }
     await dispatch(setStepsData(options));
    }
  }

  return (
    <>
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800">Reportes</h1>
        <div className="row">
          <div className="col-12">
            <div className="card shadow mb-4 pb-4">
              {/* <Form> */}
              <div className="card-body">
                {/* selects row */}
                <div className="row">
                  <div className="col">
                    {/* Groups select */}
                    <Form.Group className="mb-3" controlId="formSelectGroup">
                      {user.rol_sys_user_rol_sys_user_id === 5 ?
                      <Form.Label>Grupos:</Form.Label>
                      :
                      <Form.Label>Mis Grupo:</Form.Label>
                      }
                      
                      <Select
                        XcloseMenuOnSelect={false}
                        placeholder="Selecciona grupo"
                        noOptionsMessage={(text) => "Completo!"}
                        onChange={(selected) => handleGroups(selected)}
                        options={groups}
                      />
                    </Form.Group>
                  </div>
                  {/* Users Select */}
                  <div className="col">
                    <Form.Group className="mb-3" controlId="formSelectUsers">
                      <Form.Label>Caminantes: </Form.Label>
                      <Select
                        isDisabled={usersDisabled}
                        isMulti
                        closeMenuOnSelect={false}
                        ref={selectUsersRef}
                        defaultValue={users}
                        XcloseMenuOnSelect={false}
                        placeholder="Selecciona usuarios"
                        noOptionsMessage={(text) => "Completo!"}
                        onChange={(selected, value) => handleUsers(selected)}
                        options={fillUsers}
                      />
                    </Form.Group>
                  </div>
                  {/* Users Select */}
                  <div className="col">
                    <Form.Group
                      className="mb-3"
                      controlId="formSelectReportType"
                    >
                      <Form.Label>Tipo reporte: </Form.Label>
                      <Select
                        isDisabled={reportsDisabled}
                        defaultValue={selectedReport}
                        XcloseMenuOnSelect={false}
                        placeholder="Selecciona un tipo de reporte"
                        noOptionsMessage={(text) => "Completo!"}
                        onChange={(selected) => setSelectedReport(selected)}
                        options={reportType}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="divider"></div>
                <Form.Group className="d-flex flex-row-reverse">
                  <Button
                    variant={selectedReport ? "primary" : "secondary"}
                    disabled={selectedReport ? false : true}
                    type="submit"
                    onClick={generateReport}
                  >
                    Generar reporte
                  </Button>
                </Form.Group>
              </div>
              <div className="divider"></div>
              {/* Loading */}
              {showLoading ? (
                <div>
                  <Form.Group>
                    <Grid
                      fill="#0c3795"
                      fillOpacity={1}
                      height="3em"
                      speed={1}
                      stroke="transparent"
                      strokeOpacity={1}
                      width=""
                    />
                  </Form.Group>
                </div>
              ) : null}
              <div className="divider"></div>

              {/* Table pasos*/}
              {showTablePasos ? (
                <div>
                  <div className="mb-3 p-3">
                    <div className="text-right">
                      {(steps_data?.data ? steps_data?.data.length : 0) > 0 ? (
                        <ExcelFile
                          element={
                            <button
                              type="button"
                              className="btn btn-outline-primary"
                            >
                              Descargar
                            </button>
                          }
                          filename="Reporte"
                        >
                          <ExcelSheet
                          
                            data={all_steps_data?.data}
                            name="Reporte pasos caminados"
                          >
                            <ExcelColumn label="Rut" value="user_rut"></ExcelColumn>
                            <ExcelColumn label="Usuario" value="user_name" />
                            <ExcelColumn label="Fecha" value="user_date" />
                            <ExcelColumn
                              label="Pasos caminados"
                              value="steps_walked"
                            />
                            <ExcelColumn
                              label="Meta diaria"
                              value="daily_goal"
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {selectedReport.value === 1 && (
                    <div>
                      <DataTable
                        columns={columns_report_type_1}
                        data={steps_data?.data}
                        noDataComponent="No existen registros"
                      />
                      <HandlePage
                        data={steps_data.meta}
                        changePage={changePage}
                      />
                    </div>
                  )}
                </div>
              ) : null}
              {/* Table metas*/}
              {showTableMetas ? (
                <div>
                  <div className="mb-3 p-3">
                    <div className="text-right">
                      {(report_goals?.data ? report_goals?.data.length : 0) >
                      0 ? (
                        <ExcelFile
                          element={
                            <button
                              type="button"
                              className="btn btn-outline-primary"
                            >
                              Descargar
                            </button>
                          }
                          filename="Reporte"
                        >
                          <ExcelSheet
                            data={report_goals?.data}
                            name="Reporte de metas"
                          >
                            <ExcelColumn label="Usuario" value="user_name" />
                            <ExcelColumn
                              label="Mes Actual"
                              value="current_month"
                            />
                            <ExcelColumn
                              label="Meta mes pasado"
                              value="past_month_goal"
                            />
                            <ExcelColumn
                              label="Meta pasada superada"
                              value="past_month_success"
                            />
                            <ExcelColumn
                              label="Meta mensual presente"
                              value="current_month_goal"
                            />
                            <ExcelColumn
                              label="Recomendación nueva meta mensual"
                              value="month_goal_recomnd"
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {selectedReport.value === 2 && (
                    <div>
                      <DataTable
                        columns={columns_report_type_2}
                        data={report_goals?.data}
                        noDataComponent="No existen registros"
                      />
                      <HandlePage
                        data={report_goals.meta}
                        changePage={changePage}
                      />
                    </div>
                  )}
                </div>
              ) : null}
              {/* </Form>   */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
function goalMaps(hikers) {
  return hikers.data.map((item, index) => {
    return {
      id: index,
      user_name: item.user_name,
      current_month: formatLuxon(item.current_month),
      past_month_goal: item.prev_goal,
      past_month_success: item.prev_steps,
      current_month_goal: item.current_goal,
      month_goal_recomnd: item.recommendation_new_goal,
    };
  });
}

function stepMaps(hikers) {
  return hikers.data.map((item, index) => {
    return {
      id: index,
      user_rut: item["hiker_user.hiker_user_rut"],
      user_name: `${item["hiker_user.hiker_user_name"]} ${item["hiker_user.hiker_user_lastname"]}`,
      user_date: item.hiker_user_steps_date,
      steps_walked: item.hiker_user_steps_walked,
      daily_goal: item.hiker_user_steps_daily_goal,
    };
  });
}
