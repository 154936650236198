import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./assets/css/index.css";
import "./assets/css/sb-admin-2.min.css";
import "./assets/vendor/fontawesome-free/css/all.min.css";

// import "./assets/scss/index.scss";
// import * as serviceWorker from './serviceWorker';

import { AuthenticatedRoute } from "./pages/Auth/AuthenticatedRoute/AuthenticatedRoute";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./app/store";
import { MainLayout } from "./layout/MainLayout/MainLayout";
import { AuthLayout } from "./layout/AuthLayout/AuthLayout";

const App = () => (
  <BrowserRouter forceRefresh={false}>
    <Provider store={store}>
      <Switch>
        <PersistGate persistor={persistor}>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <AuthenticatedRoute path="/user" component={MainLayout} />
          {/* <Redirect exact from='/' to='/auth/login' /> */}
          <Route exact path="/" render={() => <Redirect to="/auth/login" />} />
        </PersistGate>
      </Switch>
    </Provider>
  </BrowserRouter>
);

ReactDOM.render(<App />, document.getElementById("root"));
