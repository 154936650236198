import React, { useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { importHikers } from "../../../services/hikerUser.service";
import SweetAlert from "react-bootstrap-sweetalert";
import { DataTableComponent } from "../../../components/ListTable/DataTableComponent";
import { calculateAge } from "../../../helpers/dateHelper";
import Loader from "react-loader-spinner";
import "./importHikers.scss";

const ImportHikers = () => {
  const [validUsers, setValidUsers] = useState([]);
  const [invalidUsers, setInvalidUsers] = useState([]);
  const [importStatus, setImportStatus] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const fileInputRef = useRef(null);

  const handleImport = async (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const clearInput = () => {
    setSelectedFile(null);
    fileInputRef.current.value = null;
  };

  const handleUploadFile = async (e) => {
    try {
      setValidUsers([]);
      setInvalidUsers([]);
      setLoading(true);
      e.preventDefault();
      const formData = new FormData();
      formData.append("file", selectedFile);
      // send formdata
      const data = await importHikers(formData);
      console.log("data", data.data);
      setValidUsers(data.data.validHikers);
      setInvalidUsers(data.data.invalidHikers);
      setImportStatus("success");
      // Reset the file input
      fileInputRef.current.value = null;
      setSelectedFile(null);
    } catch (error) {
      setImportStatus("error");
      console.error(error);
    } finally {
      setShowAlert(true);
      setLoading(false);
    }
  };

  const table_columns = [
    {
      name: "Rut",
      selector: (row) => row.hiker_user_rut,
      sortable: true,
      grow: 2,
    },
    {
      name: "Nombre",
      selector: (row) => row.hiker_user_name,
      sortable: true,
    },
    {
      name: "Apellido",
      selector: (row) => row.hiker_user_lastname,
      sortable: true,
      hide: "md",
    },
    {
      name: "Teléfono",
      selector: (row) =>
        row.hiker_user_phone ? row.hiker_user_phone : "Sin dato ingresado",
      sortable: true,
      hide: "md",
    },
    {
      name: "Email",
      selector: (row) =>
        row.hiker_user_email ? row.hiker_user_email : "Sin dato ingresado",
      sortable: true,
      hide: "md",
    },
    {
      name: "Edad",
      selector: (row) =>
        calculateAge(row.hiker_user_born_date) >= 2
          ? calculateAge(row.hiker_user_born_date) + " Años"
          : "Edad no registrada",
      sortable: true,
      hide: "md",
    },
  ];

  const table_columns_invalid = [
    {
      name: "Excel página y fila",
      selector: (row) => row.hiker_row_num,
      sortable: true,
      grow: 2,
    },
    {
      name: "Rut*",
      selector: (row) =>
        row.hiker_user_rut ? row.hiker_user_rut : "Campo requerido",
      sortable: true,
      grow: 2,
    },
    {
      name: "Nombre*",
      selector: (row) =>
        row.hiker_user_name ? row.hiker_user_name : "Campo requerido",
      sortable: true,
    },
    {
      name: "Apellido*",
      selector: (row) =>
        row.hiker_user_lastname ? row.hiker_user_lastname : "Campo requerido",
      sortable: true,
      hide: "md",
    },
    {
      name: "Teléfono*",
      selector: (row) =>
        row.hiker_user_phone ? row.hiker_user_phone : "Campo requerido",
      sortable: true,
      hide: "md",
    },
    {
      name: "Email",
      selector: (row) =>
        row.hiker_user_email ? row.hiker_user_email : "No ingresado",
      sortable: true,
      hide: "md",
    },
    {
      name: "Edad",
      selector: (row) =>
        calculateAge(row.hiker_user_born_date) >= 2
          ? calculateAge(row.hiker_user_born_date) + " Años"
          : "Edad no registrada",
      sortable: true,
      hide: "md",
    },
  ];

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">Importar Caminantes</h1>
      <div className="d-flex  flex-column">
        <Form className="d-flex" onSubmit={handleUploadFile}>
          <Form.File
            label="Seleccionar"
            onChange={handleImport}
            custom
            ref={fileInputRef}
            style={{ width: "300px" }}
            accept=".xlsx, .xls"
          />
          <Button
            type="submit"
            className="ml-2"
            disabled={!selectedFile || loading}
          >
            Subir
          </Button>
        </Form>
        {selectedFile && (
          <>
            <div className="selectedFile-container">
              <span className="mt-1 text-small"> {selectedFile.name}</span>
              <Button
                className="selectedFile-clear"
                variant="primary"
                onClick={clearInput}
              >
                X
              </Button>
            </div>
          </>
        )}
        {loading && (
          <>
            <Loader type="Puff" color="#00BFFF" height={40} width={40} />
            Cargando, por favor espere y no cierre la página...
          </>
        )}
      </div>

      {!loading && importStatus && (
        <>
          <div>
            <div className="card shadow mb-4 p-4 mt-4">
              <h6 className="m-0 font-weight-bold text-primary">
                Caminantes importados
              </h6>
              <DataTableComponent data={validUsers} columns={table_columns} />
            </div>
            <div className="card shadow mb-4 p-4">
              <h6 className="m-0 font-weight-bold text-primary">
                Caminantes con errores
              </h6>
              <p>Los campos con * Son obligatorios.</p>
              <DataTableComponent
                data={invalidUsers}
                columns={table_columns_invalid}
              />
            </div>
          </div>
          {showAlert && (
            <SweetAlert
              type={importStatus}
              onConfirm={() => setShowAlert(false)}
              title={
                importStatus === "success"
                  ? "Importación finalizada"
                  : "Error al importar"
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default ImportHikers;
