const clearString = (text) =>
  text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[¿?]/g, "")
    .toLowerCase();

const toPath = (text) => clearString(text).replace(/ /g, "-");

const removeDashAndUnderscore = (text) => text.replace(/[-_]/g, " ");

const getPathparams = ({ pathname, index }) => pathname.split("/")[index];

const capitalize = (str) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

const prettyText = (text) =>
  capitalize(removeDashAndUnderscore(clearString(text)));

export {
  clearString,
  getPathparams,
  prettyText,
  removeDashAndUnderscore,
  capitalize,
  toPath,
};
