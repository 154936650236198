import { axiosUser } from "../config/axios";

const getUsersService = () => {
  return axiosUser.get("/findall");
};

const createProfileService = (data) => {
  return axiosUser.post("/create", data);
};

const updateProfileService = (data) => {
  return axiosUser.put("/update", data);
};

const updateKineService = (data) => {
  return axiosUser.put("/update-kine", data);
};

export {
  createProfileService,
  getUsersService,
  updateProfileService,
  updateKineService,
};
