import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

export const AuthenticatedRoute = ({
  component: AdminLayout,
  appProps,
  ...rest
}) => {
  const authenthicated = useSelector(
    (state) => state.authenticated.authenticated
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        authenthicated ? (
          <AdminLayout {...props} />
        ) : (
          <Redirect to="/auth/login" />
        )
      }
    />
  );
};
