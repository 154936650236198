import React, { useRef, useState } from "react";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import Loader from "react-loader-spinner";

import { HikerNewPhysicalCondition } from "../../../Hikers/NewHiker/components/HikerNewPhysicalCondition";
import "animate.css";

export const ChequeoCondicionFisica = (props) => {

  const history = useHistory();
  const [open, setIsOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const ref = useRef();
  const location = useLocation();
  const { data } = location.state;
  const [dataRef, setDataRef] = useState(true);

  const save = async () => {
    const newCheckHealth = await ref.current.fieldsHandler();
    if (!newCheckHealth) {
      setDataRef(newCheckHealth);
      setTimeout(() => {
        setDataRef(true);
      }, 800);
      return;
    }
    setProcessing(true);
    const resp = await ref.current.saveData(data.hiker_user_id);
    if (resp) {
      setSuccess(true);
      setTimeout(() => {
        setIsOpen(false);
        setSuccess(false);
        window.location.reload();
      }, 3000);
    }
  };
  const back = () => {
    window.location.reload();
  };

  const openModal = (render) => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div
      className="bg-white text-justify p-4"
      style={{ width: 800, height: 600, overflow: "overlay" }}
    >
      <Modal
        isOpen={open}
        onRequestClose={closeModal}
        contentLabel="Register Modal"
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
      >
        <div role="form" className="user card shadow">
          {success ? (
            <div className="card">
              <div className="card-header text-info"></div>
              <div className="card-body">
                <IoIosCheckmarkCircleOutline
                  className="text-info"
                  style={{ fontSize: 50 }}
                />
                <h5 className="card-title text-info">
                  Datos guardados con éxito{" "}
                </h5>
                {/* <div className="btn btn-info" onClick={closeModal}>
                  Volver{" "}
                </div> */}
              </div>
            </div>
          ) : (
            <div style={{ width: 400, overflow: "overlay" }}>
              {!processing ? (
                <div className="mb-4 p-3">
                  <div className="py-3 mb-4" style={{width:300}}>
                    <h4 className="m-0 text-primary ">
                      ¿Estas seguro que desea guardar los cambios?
                    </h4>
                  </div>
                  <div className="col-lg-12">
                    <button
                      className="btn btn-outline-primary m-2"
                      onClick={() => save()}
                    >
                      Si
                    </button>
                    <button
                      className="btn btn-outline-danger "
                      onClick={() => closeModal()}
                    >
                      No
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="card">
                    <div className="card-header text-info"></div>
                    <div className="card-body">
                      <h5 className="card-title text-info">
                        <Loader
                          type="Puff"
                          color="#00BFFF"
                          height={40}
                          width={40}
                        />
                        Procesando...
                      </h5>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
      <HikerNewPhysicalCondition ref={ref} />
      <div className="text-right col-12 mb-4">
        <button className="btn btn-danger m-2 " onClick={back}>
          Cancelar
        </button>
        <button
          className={
            dataRef
              ? `btn btn-primary m-2`
              : `btn btn-danger  m-2 animate__animated animate__headShake`
          }
          onClick={() => {
            openModal(true);
          }}
        >
          Guardar
        </button>
      </div>
    </div>
  );
};
