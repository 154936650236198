import {
  LOAD_USER_DATA,
  LOAD_USER_DATA_SUCCESS,
  LOAD_USER_DATA_ERROR,
  CLEAN_USER_DATA,
  CLEAN_USER_DATA_SUCCESS,
  CLEAN_USER_DATA_ERROR,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  LOAD_USER_EDIT_INFO,
  LOAD_USER_EDIT_INFO_SUCCESS,
  LOAD_USER_EDIT_INFO_ERROR,
} from "../types";

//Load User
export function logedUserAction(user) {
  return (dispatch) => {
    dispatch(addlogedUser());
    try {
      dispatch(addlogedUserSuccess(user));
    } catch (error) {
      dispatch(addlogedUserError(error));
    }
  };
}

const addlogedUser = () => ({
  type: LOAD_USER_DATA,
  payload: true,
});

const addlogedUserSuccess = (user) => ({
  type: LOAD_USER_DATA_SUCCESS,
  payload: user,
});

const addlogedUserError = (status) => ({
  type: LOAD_USER_DATA_ERROR,
  payload: status,
});

//CLEAN USER AND SESSION
export function cleanUserAction() {
  return (dispatch) => {
    dispatch(cleanUser());
    try {
      dispatch(cleanUserSuccess());
    } catch (error) {
      dispatch(cleanUserError(error));
    }
  };
}

const cleanUser = () => ({
  type: CLEAN_USER_DATA,
  payload: true,
});

const cleanUserSuccess = (user) => ({
  type: CLEAN_USER_DATA_SUCCESS,
  payload: null,
});

const cleanUserError = (status) => ({
  type: CLEAN_USER_DATA_ERROR,
  payload: status,
});

//GET USERS LIST
export function getUsersAction(users) {
  return (dispatch) => {
    dispatch(getUsers());
    try {
      dispatch(getUsersSuccess(users));
    } catch (error) {
      dispatch(getUsersError(error));
    }
  };
}

const getUsers = () => ({
  type: GET_USERS,
  payload: true,
});

const getUsersSuccess = (users) => ({
  type: GET_USERS_SUCCESS,
  payload: users,
});

const getUsersError = (status) => ({
  type: GET_USERS_ERROR,
  payload: status,
});

//Load User edition in group info
export function loadUserToEditAction(user) {
  return (dispatch) => {
    dispatch(loadUserToEdit());
    try {
      dispatch(loadUserToEditSuccess(user));
    } catch (error) {
      dispatch(loadUserToEditError(error));
    }
  };
}

const loadUserToEdit = () => ({
  type: LOAD_USER_EDIT_INFO,
  payload: true,
});

const loadUserToEditSuccess = (user) => ({
  type: LOAD_USER_EDIT_INFO_SUCCESS,
  payload: user,
});

const loadUserToEditError = (status) => ({
  type: LOAD_USER_EDIT_INFO_ERROR,
  payload: status,
});
