import React from "react";
import styled from "styled-components";
import Button from 'react-bootstrap/Button';

export const FilterComponent = ({ filterText, onFilter, onClear }) => {

   const TextField = styled.input`
   	height: 37px;
   	width: 450px;
   	border-radius: 5px;
   	border: 1px solid #e5e5e5;
   	padding: 0 32px 0 16px
   	&:hover {
   		cursor: pointer;
   	}
    &:focus{
      outline: none;
    }
    `;

  return (
    <>
    <TextField
			id="search"
			type="text"
			placeholder="Buscar datos"
			aria-label="Buscador"
			defaultValue={filterText}
			onChange={onFilter}
		/>
    <Button variant="primary" onClick={onClear}>X</Button>
  </>

  )
  };
