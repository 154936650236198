//User Actions
export const LOAD_USER_DATA = 'LOAD_USER_DATA';
export const LOAD_USER_DATA_SUCCESS = 'LOAD_USER_DATA_SUCCESS';
export const LOAD_USER_DATA_ERROR = 'LOAD_USER_DATA_ERROR';

export const CLEAN_USER_DATA = 'CLEAN_USER_DATA';
export const CLEAN_USER_DATA_SUCCESS = 'CLEAN_USER_DATA_SUCCESS';
export const CLEAN_USER_DATA_ERROR = 'CLEAN_USER_DATA_ERROR';

export const AUTHENTICATED = 'AUTHENTICATED';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_ERROR = 'AUTHENTICATED_ERROR';
export const START_TIMEOUT = 'START_TIMEOUT';
export const START_TIMEOUT_SUCCESS = 'START_TIMEOUT_SUCCESS';
export const START_TIMEOUT_ERROR = 'START_TIMEOUT_ERROR';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

//Carga de datos de grupos
export const LOAD_GROUP_LIST = 'LOAD_GROUP_LIST';
export const LOAD_GROUP_LIST_SUCCESS = 'LOAD_GROUP_LIST_SUCCESS';
export const LOAD_GROUP_LIST_ERROR = 'LOAD_GROUP_LIST_ERROR';

export const LOAD_GROUP_HEADER = 'LOAD_GROUP_HEADER';
export const LOAD_GROUP_HEADER_SUCCESS = 'LOAD_GROUP_HEADER_SUCCESS';
export const LOAD_GROUP_HEADER_ERROR = 'LOAD_GROUP_HEADER_ERROR';

export const LOAD_GROUP_IN_CHARGE = 'LOAD_GROUP_IN_CHARGE';
export const LOAD_GROUP_IN_CHARGE_SUCCESS = 'LOAD_GROUP_IN_CHARGE_SUCCESS';
export const LOAD_GROUP_IN_CHARGE_ERROR = 'LOAD_GROUP_IN_CHARGE_ERROR';

export const LOAD_GROUP_NEXT_HIKES = 'LOAD_GROUP_NEXT_HIKES';
export const LOAD_GROUP_NEXT_HIKES_SUCCESS = 'LOAD_GROUP_NEXT_HIKES_SUCCESS';
export const LOAD_GROUP_NEXT_HIKES_ERROR = 'LOAD_GROUP_NEXT_HIKES_ERROR';

export const LOAD_USER_EDIT_INFO = 'LOAD_USER_EDIT_INFO';
export const LOAD_USER_EDIT_INFO_SUCCESS = 'LOAD_USER_EDIT_INFO_SUCCESS';
export const LOAD_USER_EDIT_INFO_ERROR = 'LOAD_USER_EDIT_INFO_ERROR';

//Hikers
export const LOAD_HIKER_LIST = 'LOAD_HIKER_LIST';
export const LOAD_HIKER_LIST_SUCCESS = 'LOAD_HIKER_LIST_SUCCESS';
export const LOAD_HIKER_LIST_ERROR = 'LOAD_HIKER_LIST_ERROR';

//HikerHeader
export const LOAD_HIKER_HEADER = 'LOAD_HIKER_HEADER';
export const LOAD_HIKER_HEADER_SUCCESS = 'LOAD_HIKER_HEADER_SUCCESS';
export const LOAD_HIKER_HEADER_ERROR = 'LOAD_HIKER_HEADER_ERROR';
//HikerManualRegister
export const LOAD_HIKER_MANUAL_REGISTER = 'LOAD_HIKER_MANUAL_REGISTER';
export const LOAD_HIKER_MANUAL_REGISTER_SUCCESS =
  'LOAD_HIKER_MANUAL_REGISTER_SUCCESS';
export const LOAD_HIKER_MANUAL_REGISTER_ERROR =
  'LOAD_HIKER_MANUAL_REGISTER_ERROR';
//HikerEdit
export const LOAD_HIKER_EDIT = 'LOAD_HIKER_EDIT';
export const LOAD_HIKER_EDIT_SUCCESS = 'LOAD_HIKER_EDIT_SUCCESS';
export const LOAD_HIKER_EDIT_ERROR = 'LOAD_HIKER_EDIT_ERROR';
//HikerCheckHealth
export const LOAD_HIKER_CHECK_HEALTH = 'LOAD_HIKER_CHECK_HEALTH';
export const LOAD_HIKER_CHECK_HEALTH_SUCCESS =
  'LOAD_HIKER_CHECK_HEALTH_SUCCESS';
export const LOAD_HIKER_CHECK_HEALTH_ERROR = 'LOAD_HIKER_CHECK_HEALTH_ERROR';
//HikerQualityLife
export const LOAD_HIKER_QUALITY_LIFE = 'LOAD_HIKER_QUALITY_LIFE';
export const LOAD_HIKER_QUALITY_LIFE_SUCCESS =
  'LOAD_HIKER_QUALITY_LIFE_SUCCESS';
export const LOAD_HIKER_QUALITY_LIFE_ERROR = 'LOAD_HIKER_QUALITY_LIFE_ERROR';
//HikerEntryMotivation
export const LOAD_HIKER_ENTRY_MOTIVATION = 'LOAD_HIKER_ENTRY_MOTIVATION';
export const LOAD_HIKER_ENTRY_MOTIVATION_SUCCESS =
  'LOAD_HIKER_ENTRY_MOTIVATION_SUCCESS';
export const LOAD_HIKER_ENTRY_MOTIVATION_ERROR =
  'LOAD_HIKER_ENTRY_MOTIVATION_ERROR';
