// import React from "react";
// import { Redirect } from 'react-router-dom';
// import { lazyComponent } from '../common/utils';
// import { AuthLayout } from '../layout/AuthLayout/AuthLayout';
// import { MainLayout } from '../layout/MainLayout/';
// import { PrivateRoute } from './PrivateRoute/PrivateRoute';

import { Login } from "../pages/Auth/Login/Login";
import { Recovery } from "../pages/Auth/Recovery/Recovery";
import { Home } from "../pages/Home/Home";
import { Profile } from "../pages/Profile/Profile";
import { CreateNews } from "../pages/News/CreateNews";
import { NewProffesional } from "../pages/Proffesionals/NewProffesional/NewProffesional";
import { ProffesionalList } from "../pages/Proffesionals/ProffesionalList/ProffesionalList";
import { ProffesionalInfo } from "../pages/Proffesionals/ProfessionalInfo/ProffesionalInfo";
import { NewHiker } from "../pages/Hikers/NewHiker/NewHiker";
import { HikerList } from "../pages/Hikers/HikerList/HikerList";
import { Calendar } from "../pages/Calendar/Calendar";
import { Reports } from "../pages/Reports/Report";
//Perfil Kinesiologo
import { GroupManager } from "../pages/Kinesiologo/Group/GroupManager/GroupManager";
import { HikerDetails } from "../pages/Kinesiologo/AdministrarKiller/component/HikerDetails";
import { NewFicha } from "../pages/Kinesiologo/AdministrarKiller/component/NewFicha"
import { StepLog } from "../pages/Kinesiologo/AdministrarKiller/component/StepLog"
import { AdministrarHikers } from "../pages/Kinesiologo/AdministrarKiller/AdministrarHikers"
import { NewGroup } from "../pages/Kinesiologo/Group/NewGroup";
import { GroupDetails } from "../pages/Kinesiologo/Group/GroupManager/component/GruoupDetails";
import { AgendarCaminata } from "../pages/Kinesiologo/Group/GroupManager/component/AgendarCaminata";

import { ChequeoSalud } from "../pages/Kinesiologo/AdministrarKiller/component/ChequeoSalud";
import { ChequeoCondicionFisica } from "../pages/Kinesiologo/AdministrarKiller/component/ChequeoCondicionFisica";
import { ChequeoCondicionVida } from "../pages/Kinesiologo/AdministrarKiller/component/ChequeoCondicionVida";

//components
import { ViewContent } from "../components/ViewRegistration/ViewContent"
import { NewCaminata } from "../components/Caminatas/NewCaminata";
import ImportHikers from "../pages/Hikers/ImportHikers/ImportHikers";
const routes = [
  {
    collapse: true,
    name: "Auth",
    icon: "ni ni-ungroup text-orange",
    state: "Auth",
    views: [
      {
        path: "/login",
        name: "Login",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/recovery",
        name: "Recovery",
        component: Recovery,
        layout: "/auth",
      },
    ],
  },
  {
    collapse: true,
    name: "Dashboards",
    icon: "ni ni-shop text-primary",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/home",
        name: "Home",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: Home,
        layout: "/home",
      },
      {
        path: "/profile",
        name: "Profile",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: Profile,
        layout: "/user",
      },
      {
        path: "/new-proffesional",
        name: "NewProfessional",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: NewProffesional,
        layout: "/user",
      },
      {
        path: "/proffesional-list",
        name: "ProffesionalList",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: ProffesionalList,
        layout: "/user",
      },
      {
        path: "/proffesional-info",
        name: "Proffesionalnfo",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: ProffesionalInfo,
        layout: "/user",
      },
      {
        path: "/new-hiker",
        name: "NewHiker",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: NewHiker,
        layout: "/user",
      },
      {
        path: "/hiker-list",
        name: "HikerList",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: HikerList,
        layout: "/user",
      },
      {
        path: "/calendar",
        name: "Calendar",
        icon: "ni ni-calendar text-primary",
        miniName: "C",
        component: Calendar,
        layout: "/user",
      },
      {
        path: "/reports",
        name: "Calendar",
        icon: "ni ni-calendar text-primary",
        miniName: "R",
        layout: "/user"
      },
      {
        path: "/create-news",
        name: "CreateNews",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: CreateNews,
        layout: "/user",
      },    
      {
        path: "/group-manager",
        name: "GroupManaer",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: GroupManager, 
        layout: "/user",
      },
      {
        path: "/hiker-details",
        name: "HikerDetails",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: HikerDetails, 
        layout: "/user",
      },
      {
        path: "/new-ficha",
        name: "NewFicha",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: NewFicha , 
        layout: "/user",
      },
      {
        path: "/step-log",
        name: "StepLog",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: StepLog, 
        layout: "/user",
      },   
      {
        path: "/administrar-hikers",
        name: "AdministrarHikers",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: AdministrarHikers , 
        layout: "/user",
      },
      {
        path: "/importar-hikers",
        name: "ImportarHikers",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: ImportHikers, 
        layout: "/user",
      },
      {
        path: "/new-group",
        name: "NewGruop",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: NewGroup , 
        layout: "/user",
      },
      {
        path: "/group-details",
        name: "GruopDetails",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: GroupDetails , 
        layout: "/user",
      },
      // {
      //   path: "/agendar",
      //   name: "AgendarCaminatas",
      //   icon: "ni ni-shop text-primary",
      //   miniName: "D",
      //   component: AgendarCaminata , 
      //   layout: "/user",
      // },
     
      {
        path: "/chequeo-salud",
        name: "ChequeoSalud",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: ChequeoSalud , 
        layout: "/user",
      },
      {
        path: "/chequeo-condicion-fisica",
        name: "ChequeoCondicionFisica",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: ChequeoCondicionFisica , 
        layout: "/user",
      },
      {
        path: "/chequeo-condicion-vida",
        name: "ChequeoCondicionVida",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: ChequeoCondicionVida , 
        layout: "/user",
      },
      {
        path: "/chequeos",
        name: "ViewContent",
        icon: "ni ni-shop text-primary",
        miniName: "D",
        component: ViewContent , 
        layout: "/user",
      },
      // {
      //   path: "/new-caminata",
      //   name: "NewCaminanta",
      //   icon: "ni ni-shop text-primary",
      //   miniName: "D",
      //   component: NewCaminata , 
      //   layout: "/user",
      // },
    ],
  },
];

export default routes;
