import React, { useState  } from "react";
import { useSelector  } from "react-redux";
import { Link, NavLink  } from "react-router-dom";
import { Collapse, Nav, NavBar, NarbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import logo from "../../assets/images/icons/solo_logo.svg";
// import { render } from "@testing-library/react";

const defaultState = {
  caminantes: false,
  grupos: false,
  profesionales: false,
};
export const Sidebar = () => {

  const user = useSelector( state => state.user);

  const [state, setState] = useState(defaultState);
  // const history = useHistory();

  const toggleCaminantes = () => {
    setState({
      caminantes: !state.caminantes,
    });
  };
  const toggleGrupos = () => {
    setState({
      grupos: !state.grupos,
    });
  };
  const toggleProfesionales = () => {
    setState({
      profesionales: !state.profesionales,
    });
  };

  // const redirect = (route) => {
  //   history.push(`/user/${route}`);
  // };


  return (
    <div>
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        <Link
          className="sidebar-brand d-flex align-items-center justify-content-center"
          to="/user/home"
        >
          <img
            src={logo}
            className="logo"
            // style=""
            alt="Logo Caminatas paso a paso"
          />
          <div className="sidebar-brand-text mx-3">Caminatas paso a paso</div>
        </Link>

        <hr className="sidebar-divider my-0"></hr>

        <li className="nav-item">
          <NavLink className="nav-link" to="/user/home">
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </NavLink>
        </li>

        <hr className="sidebar-divider"></hr>

        <li className="nav-item">
          
          <div
            className="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
            onClick={toggleCaminantes}
            style={{cursor:'pointer'}}
          >
            <i className="fas fa-fw fa-user"></i>
            <span>Caminantes</span>
          </div>
          <div
            id="collapseOne"
            className={"collapse" + (state.caminantes ? " in" : "")}
            aria-labelledby="headingOne"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Opciones:</h6>
              <NavLink className="collapse-item" to="/user/new-hiker" activeClassName="active">
                Nuevo caminante
              </NavLink>
              <NavLink className="collapse-item" to="/user/administrar-hikers" activeClassName="active">
                Gestionar caminantes
              </NavLink>
              <NavLink className="collapse-item" to="/user/importar-hikers" activeClassName="active">
                Importar caminantes
              </NavLink>
            </div>
          </div>
        </li>

        <li className="nav-item">
          <div
            className="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
            onClick={toggleGrupos}
            style={{cursor:'pointer'}}
          >
            <i className="fas fa-fw fa-users"></i>
            <span>Grupos</span>
          </div>
          <div
            id="collapseTwo"
            className={"collapse" + (state.grupos ? " in" : "")}
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Opciones:</h6>
              <NavLink className="collapse-item" to="/user/new-group">
                Nuevo grupo
              </NavLink>
              <NavLink className="collapse-item" to="/user/group-manager">
                Gestionar grupos
              </NavLink>
            </div>
          </div>
        </li>
        {user.user.rol_sys_user_rol_sys_user_id === 5 ?
        <li className="nav-item">
          <div
            className="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseThree"
            aria-expanded="true"
            aria-controls="collapseThree"
            onClick={toggleProfesionales}
            style={{cursor:'pointer'}}
          >
            <i className="fas fa-fw fa-user-nurse"></i>
            <span>Profesionales</span>
          </div>
          <div
            id="collapseThree"
            className={"collapse" + (state.profesionales ? " in" : "")}
            aria-labelledby="headingThree"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Opciones:</h6>
              <NavLink className="collapse-item" to="/user/new-proffesional/">
                Nuevo profesional
              </NavLink>
              <NavLink className="collapse-item" to="/user/proffesional-list/">
                Gestionar profesionales
              </NavLink>
            </div>
          </div>
        </li>
        : null}
        {/* <li className="nav-item">
          <NavLink
            className="nav-link collapsed"
            to="/user/new-caminata"
          >
            <i className="fas fa-running"></i>
            <span>Caminatas</span>
          </NavLink>
        </li> */}
        
        <li className="nav-item">
          <NavLink
            className="nav-link collapsed"
            to="/user/calendar/"
          >
            <i className="far fa-calendar-alt"></i>
            <span>Calendario</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <Link
            className="nav-link collapsed"
            to="/user/reports/"
          >
            <i className="far fa-file-alt"></i>
            <span>Reportes</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};
