import {
  LOAD_GROUP_LIST,
  LOAD_GROUP_LIST_SUCCESS,
  LOAD_GROUP_LIST_ERROR,
  LOAD_GROUP_HEADER,
  LOAD_GROUP_HEADER_SUCCESS,
  LOAD_GROUP_HEADER_ERROR,
  LOAD_GROUP_IN_CHARGE,
  LOAD_GROUP_IN_CHARGE_SUCCESS,
  LOAD_GROUP_IN_CHARGE_ERROR,
  LOAD_GROUP_NEXT_HIKES,
  LOAD_GROUP_NEXT_HIKES_SUCCESS,
  LOAD_GROUP_NEXT_HIKES_ERROR,
} from "../types";

const initialState = {

  loadingGroupList: false,
  groupList: null,
  errorGroupList: null,

  loadingGroupHeaders: false,
  groupHeaders: null,
  errorGroupHeaders: null,

  loadingGroupInCharge: false,
  groupGroupInCharge: null,
  errorGroupInCharge: null,

  loadingGroupNextHikes: false,
  groupNextHikes: null,
  errorGroupNextHikes: null,
};

export default function groupReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_GROUP_LIST:
      return {
        ...state,
        loadingGroupList: true,
      };
    case LOAD_GROUP_LIST_SUCCESS:
      return {
        ...state,
        loadingGroupList: false,
        groupList: action.payload,
        errorGroupList: null,
      };
    case LOAD_GROUP_LIST_ERROR:
      return {
        ...state,
        loadingGroupList: false,
        errorGroupList: action.payload,
      };
    case LOAD_GROUP_HEADER:
      return {
        ...state,
        loadingGroupHeaders: true,
      };
    case LOAD_GROUP_IN_CHARGE:
      return {
        ...state,
        loadingGroupInCharge: true,
      };
    case LOAD_GROUP_NEXT_HIKES:
      return {
        ...state,
        loadingGroupNextHikes: true,
      };

    case LOAD_GROUP_HEADER_SUCCESS:
      return {
        ...state,
        loadingGroupHeaders: false,
        groupHeaders: action.payload,
        errorGroupHeaders: null,
      };
    case LOAD_GROUP_IN_CHARGE_SUCCESS:
      return {
        ...state,
        loadingGroupInCharge: false,
        groupGroupInCharge: action.payload,
        errorGroupInCharge: null,
      };
    case LOAD_GROUP_NEXT_HIKES_SUCCESS:
      return {
        ...state,
        loadingGroupNextHikes: false,
        groupNextHikes: action.payload,
        errorGroupNextHikes: null,
      };

    case LOAD_GROUP_HEADER_ERROR:
      return {
        ...state,
        loadingGroupHeaders: false,
        errorGroupHeaders: action.payload,
      };
    case LOAD_GROUP_IN_CHARGE_ERROR:
      return {
        ...state,
        loadingGroupInCharge: false,
        errorGroupInCharge: action.payload,
      };
    case LOAD_GROUP_NEXT_HIKES_ERROR:
      return {
        ...state,
        loadingGroupNextHikes: false,
        errorGroupNextHikes: action.payload,
      };

    default:
      return state;
  }
}
