import React , {Component} from "react";

class TableRango extends Component { 
    render() {
        return (
         <div>
              <div className="table-responsive">
                <table
                  className="table table-sm table-bordered table-striped"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0">
                  <thead>
                    <tr>
                      <th>Edad</th>
                      <th>Hombre</th>
                      <th>Mujer</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>60-64</th>
                      <th> 14 </th>
                      <th> 12</th>
                    </tr>
                    <tr>
                      <th>65-69</th>
                      <th> 12</th>
                      <th> 11 </th>
                    </tr>
                    <tr>
                      <th>70-74</th>
                      <th> 12 </th>
                      <th> 10</th>
                    </tr>
                    <tr>
                      <th>75-79</th>
                      <th> 11 </th>
                      <th> 10</th>
                    </tr>
                    <tr>
                      <th>80-84</th>
                      <th> 10 </th>
                      <th> 9</th>
                    </tr>
                    <tr>
                      <th>85-89</th>
                      <th> 8 </th>
                      <th> 8</th>
                    </tr>
                    <tr>
                      <th>90-94</th>
                      <th> 7 </th>
                      <th> 4</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
    );
  }
}
    

export default TableRango;