import { axiosHiker } from "../config/axios";

const getHikerInformation = (hiker_user_id) => {
  return axiosHiker.get(`/getInformation/${hiker_user_id}`);
};
const getHikersList = () => {
  return axiosHiker.get("/get-userlist");
};
const getHikersListSys = (req = null) => {
  let page = req?.params?.page || 1;
  let size = req?.params?.size || 10;
  return axiosHiker.get(
    `/get-userlist-sys?page=${page}&size=${size}&sortBy=updated_at&order=0`
  );
};

const getAllHikersListSys = () => {
  return axiosHiker.get(
    `/get-all-userlist-sys`
  );
};
const getHikersByUser = () => {
  return axiosHiker.get(`/get-all-hilers`);
};

const getAllHikersUsers = () => {
  return axiosHiker.get(`/get-all-hikersUsers`);
}

const getAllUsers = () => {
  return axiosHiker.get(`/get-all-users`);
}
//Hiker Information
const getHikerHeaderBack = (hiker_user_id) => {
  return axiosHiker.get(`/get-hiker-header/${hiker_user_id}`);
};
const getHikerManualRegisterBack = (hiker_user_id) => {
  return axiosHiker.get(`/get-hiker-manual-register/${hiker_user_id}`);
};
const getHikerCheckHealthBack = (hiker_user_id) => {
  return axiosHiker.get(`/get-hiker-check-health/${hiker_user_id}`);
};
const getHikerQualityLifeBack = (hiker_user_id) => {
  return axiosHiker.get(`/get-hiker-quality-life/${hiker_user_id}`);
};
const getHikerEntryMotivationBack = (hiker_user_id) => {
  return axiosHiker.get(`/get-hiker-entry-motivation/${hiker_user_id}`);
};
const getHealthExaminations = (hiker_user_id) => {
  return axiosHiker.get(`/get-hikers-examinations/${hiker_user_id}`);
};
const getTirednessSlugs = (data) => {
  return axiosHiker.get(`get-hiker-tiredness-slugs`, data);
};
//create hiker
const putStoreHiker = (data) => {
  return axiosHiker.put(`hikers`, data);
};


const updateHikerGroup = (data) => {
  return axiosHiker.put(`/updategroup`, data);
}

const postNewCheckHealth = (data) => {
  return axiosHiker.post(`create-new-check-health`, data);
};
const postNewPhysicalCondition = (data) => {
  return axiosHiker.post(`create-new-physical-condition`, data);
};
const postNewLifeQuality = (data) => {
  return axiosHiker.post(`create-new-life-quality`, data);
};
const postNewEntryMotivation = (data) => {
  return axiosHiker.post(`create-new-entry-motivation`, data);
};
const importHikers = (data) => {
  return axiosHiker.post(`import`, data);
};
const postRecordManual = (data) => {
  return axiosHiker.post(`create-record-manual`, data);
};
export {
  getHikerInformation,
  getHikersList,
  getHikersListSys,
  //get hiker data
  getHikerHeaderBack,
  getHikerManualRegisterBack,
  getHikerCheckHealthBack,
  getHikerQualityLifeBack,
  getHikerEntryMotivationBack,
  getHealthExaminations,
  getTirednessSlugs,
  getHikersByUser,
  getAllHikersUsers,
  //new hiker
  putStoreHiker,
  postNewCheckHealth,
  postNewPhysicalCondition,
  postNewLifeQuality,
  postNewEntryMotivation,
  postRecordManual,
  //update hiker data
  updateHikerGroup,
  importHikers,
  getAllHikersListSys
};
