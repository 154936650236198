import {
  AUTHENTICATED,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_ERROR,
  START_TIMEOUT,
  START_TIMEOUT_SUCCESS,
  START_TIMEOUT_ERROR,
} from "../types";

const initialState = {
  authenticated: false,
  timeOutOver: false,
  loading: false,
  error: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case START_TIMEOUT:
      return {
        ...state,
        timeOutOver: false,
      };
    case AUTHENTICATED:
      return {
        ...state,
        loading: action.payload,
      };
    case AUTHENTICATED_SUCCESS:
      return {
        ...state,
        loading: false,
        authenticated: action.payload,
      };
    case START_TIMEOUT_SUCCESS:
      return {
        ...state,
        timeOutOver: action.payload,
      };
    case START_TIMEOUT_ERROR:
    case AUTHENTICATED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
