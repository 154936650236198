import {
  LOAD_USER_DATA,
  LOAD_USER_DATA_SUCCESS,
  LOAD_USER_DATA_ERROR,
  CLEAN_USER_DATA,
  CLEAN_USER_DATA_SUCCESS,
  CLEAN_USER_DATA_ERROR,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  LOAD_USER_EDIT_INFO,
  LOAD_USER_EDIT_INFO_SUCCESS,
  LOAD_USER_EDIT_INFO_ERROR,
} from "../types";

const initialState = {
  user: null,
  userList: null,
  userToEdit: null,
  loading: false,
  error: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_USER_DATA:
    case GET_USERS:
    case CLEAN_USER_DATA:
    case LOAD_USER_EDIT_INFO:
      return {
        ...state,
        loading: action.payload,
      };
    case LOAD_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { ...action.payload },
      };
    case CLEAN_USER_DATA_SUCCESS:
      return {
        user: null,
        loading: false,
        error: null,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        userList: action.payload,
        loading: false,
        error: null,
      };
    case LOAD_USER_EDIT_INFO_SUCCESS:
      return {
        ...state,
        userToEdit: action.payload,
        loading: false,
        error: null,
      };
    case LOAD_USER_DATA_ERROR:
    case CLEAN_USER_DATA_ERROR:
    case GET_USERS_ERROR:
    case LOAD_USER_EDIT_INFO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
