import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import { DataTableComponent } from '../../../../components/ListTable/DataTableComponent';
import { useSelector } from "react-redux";
import {
  getGroupList,
  getAllGroupList,
} from "../../../../services/group.service";
import { formatLuxon } from "../../../../helpers/basicFunctions";

function ListGroup(props) {
  const [table_columns, setTableColumns] = useState([]);
  const groupList = props.groupList;
  const user = useSelector((state) => state.user.user);
  const [groups, setGroups] = useState(null);

  const getState = (id) => {
    const state = {
      undefined: 'No definido',
      null: "No iniciado",
      1: "En progreso",
      2: "Finalizado",
    };
    return state[id];
  };

  const joinName = (name, lastname) => {

    if(name == null) {
      name = ""
    }

    if(lastname == null) {
      lastname = ""
    }

    return name + " " + lastname;
  }

  useEffect(() => {

    // admin
    if (user.rol_sys_user_rol_sys_user_id === 5) {
      getAllGroupList()
        .then((resp) => {
          setGroups(resp.data);
          setTableColumns([
            {
              name: "Nombre de grupo",
              selector: row => (row.group_name),
              sortable: true,
              grow: 2
            },
            {
              name: "Líder",
              selector: row => (row.sys_users.map(u => `${u.sys_user_name} ${u.sys_user_lastname}` ).join(',')),
              sortable: true,
            },
            {
              name: "Inicio de grupo",
              selector: row => (row.group_state_programs[0]?.group_state_program_date_start 
                                ? formatLuxon(row.group_state_programs[0]?.group_state_program_date_start) 
                                : "No iniciado"),
              sortable: true,
              hide: "md"
            },
            {
              name: "Finalización de grupo",
              selector: row => (row.group_state_programs[0]?.group_state_program_date_end 
                                ? formatLuxon(row.group_state_programs[0]?.group_state_program_date_end) 
                                : "No agendada"),
              sortable: true,
              hide: "sm"
            },
            {
              name: "Estado de grupo",
              selector: row => (getState(row.group_state_programs[0]?.group_state_state)),
              sortable: true,
              hide: "sm"
            },
            {
              name: "Acciones",
              button: true,
              cell: row =>
                  <>
                    <Link
                            to={{
                              pathname: "/user/group-details",
                              state: {groupInfo: row}
                            }}
                            className="btn btn-primary"
                          >
                            Ver{" "}
                          </Link>
                  </>
            }
          ]);

        })
        .catch((e) => {
          console.log(e);
        });

    } else {
      // kine
      getGroupList(user.sys_user_id)
        .then((resp) => {
          setGroups(resp.data);

          setTableColumns([
            {
              name: "Mis grupos",
              selector: row => (row.group_name),
              sortable: true,
              grow: 2
            },
            {
              name: "Inicio de grupo",
              selector: row => (row.group_state_programs[0]?.group_state_program_date_start 
                                ? formatLuxon(row.group_state_programs[0]?.group_state_program_date_start) 
                                : "No iniciado"),
              sortable: true,
              hide: "md"
            },
            {
              name: "Finalización de grupo",
              selector: row => (row.group_state_programs[0]?.group_state_program_date_end 
                                ? formatLuxon(row.group_state_programs[0]?.group_state_program_date_end) 
                                : "No agendada"),
              sortable: true,
              hide: "sm"
            },
            {
              name: "Estado de grupo",
              selector: row => (getState(row.group_state_programs[0]?.group_state_state)),
              sortable: true,
              hide: "sm"
            },
            {
              name: "Acciones",
              button: true,
              cell: row =>
                  <>
                    <Link
                            to={{
                              pathname: "/user/group-details",
                              state: {groupInfo: row}
                            }}
                            className="btn btn-primary"
                          >
                            Ver{" "}
                          </Link>
                  </>
            }
          ]);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  return (
    <>
    {groups ? (
    <DataTableComponent data={groups} columns={table_columns}/>
    ) :
    (
      <div>
      <div className="card">
        <div className="card-header text-info"></div>
        <div className="card-body">
          <h5 className="card-title text-info">
            <Loader
              type="Puff"
              color="#00BFFF"
              height={40}
              width={40}
            />
            Cargando...
          </h5>
        </div>
      </div>
    </div>
    )}

    {/* 
      ***** OLD TABLE *****
    
    <table
      className="table table-bordered table-striped"
      id="dataTable"
      width="100%"
      cellSpacing="0"
    >
      <thead>
        {user.rol_sys_user_rol_sys_user_id === 15 ? (
          <tr>
            <th>Mis grupos</th>
            <th>Inicio de grupo</th>
            <th>Finalización de grupo</th>
            <th>Estado grupo</th>
            <th>Acciones</th>
          </tr>
        ) : (
          <tr>
            <th>Nombre de Grupo</th>
            <th>Líder</th>
            <th>Inicio de grupo</th>
            <th>Finalización de grupo</th>
            <th>Estado grupo</th>
            <th>Acciones</th>
          </tr>
        )}
      </thead>
      {groups?.map((item, index) => (
        <tbody key={index}>
          <tr>
            <th>{item?.group_name}</th>
            {user.rol_sys_user_rol_sys_user_id === 5 ? (
              <th>
                {item?.sys_user.sys_user_name} {item.sys_user.sys_user_lastname}
              </th>
            ) : null}
            <th>
              {item.group_state_programs[0]?.group_state_program_date_start
                ? formatLuxon(item.group_state_programs[0]?.group_state_program_date_start)
                : "No iniciado"}
            </th>
            <th>
              {item.group_state_programs[0]?.group_state_program_date_end
                ? formatLuxon(item.group_state_programs[0]?.group_state_program_date_end)
                : "No agendado"}
            </th>
            <th>{getState(item.group_state_programs[0]?.group_state_state)}</th>
            <th>
              <Link
                to={{
                  pathname: "/user/group-details",
                  state: { groupInfo: item },
                }}
                className="btn btn-primary"
              >
                Ver{" "}
              </Link>
            </th>
          </tr>
        </tbody>
      ))}
    </table> */}
    </>
  );
}
export const GroupManager = () => {
  const groupList = useSelector((state) => state.group.groupList);

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Grupos</h1>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            Grupos registrados
          </h6>
        </div>
        <div className="card-body">
          <div className="table-responsive"></div>
          <ListGroup groupList={groupList} />
        </div>
      </div>
    </div>
  );
};
