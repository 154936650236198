import React, { useState, useEffect } from "react";
import { getListInformation } from "../../services/news.service";
import { formatDate } from "../../helpers/dateHelper";
import Loader from "react-loader-spinner";

export const TableInformations = () => {
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getListInformation()
      .then((resp) => {
        setLoading(false);
        if (resp.data.data.length > 0) {
          setList(resp.data.data);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div className="card-body overflow-auto" style={{ height: 530 }}>
      {loading ? (
        <div>
          <div className="card">
            <div className="card-header text-info"></div>
            <div className="card-body">
              <h5 className="card-title text-info">
                <Loader type="Puff" color="#00BFFF" height={40} width={40} />
                Cargando...
              </h5>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {list ? (
            <div>
              {list.length > 0 ? (
                <div>
                  {list.map((item) => (
                    <div key={item.Information_id}>
                      <h5 className="font-weight-bold">
                        {item.Information_title}
                      </h5>

                      <p className="small font-weight-bold ">
                        {formatDate(item.Information_created_at)}
                      </p>
                      <p className="card-text">{item.Information_message}</p>
                      <hr className="sidebar-divider"></hr>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div>
              <h4>No hay registros</h4>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
