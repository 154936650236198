import React, { useState, useEffect, createRef } from "react";
import { DateTime } from "luxon";
import "animate.css";

import { postRecordManual } from "../../../../services/hikerUser.service";
import { getTirednessSlugs } from "../../../../services/hikerUser.service";
import { getDaysOfMonth } from "../../../../helpers/dateHelper";
import { useLocation } from "react-router-dom";
import { capitalize } from "../../../../common/text-formater";
import { dateToString } from "../../../../helpers/basicFunctions";
import { ModalSuccesfull } from "../../../../components/Modal/ModalSuccesfull";
import { useHistory } from "react-router-dom";
export const StepLog = () => {

  const location = useLocation();
  const { data } = location.state;
  const history = useHistory();
  const [month, setMonth] = useState(DateTime.now().month);
  const [shedule, setShedule] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [signError, setSignError] = useState(false);
  const [daysOfMonth, setDaysOfMonth] = useState([]);
  const [arrayPayload, setArrayPayload] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [optionsTiredness, setOptionsTiredness] = useState([]);

  useEffect(() => {
    setDaysOfMonth(getDaysOfMonth(year, month));

    getTirednessSlugs()
      .then((resp) => {
        setOptionsTiredness(resp.data);
        console.log(resp.data);
      })
      .catch((e) => console.log(e));
  }, []);

  const saveData = async () => {
    if (arrayPayload.length > 0) {
      setOpen(true);
      const resp = await postRecordManual(arrayPayload);
      if (resp.status === 201) {
        setSuccess(true);
        setTimeout(() => {
          setOpen(false);
        }, 1000);

        setTimeout(() => {
          history.goBack();
        }, 2000);
      }
    } else {
      setSignError(true);
      setTimeout(() => {
        setSignError(false);
      }, 1000);
    }
  };

  const pushDaysOfMonth = async (month, year) => {
    console.log(month);
    setMonth(month);
    setYear(year);
    setDaysOfMonth(getDaysOfMonth(year, month, year));
  };

  const getDropList = () => {
    const year = new Date().getFullYear();
    return Array.from(new Array(50), (v, i) => (
      <option key={i} value={year + i}>
        {year + i}
      </option>
    ));
  };

  const handleChange = async (index, event, field, date) => {
    const copy_shedule = [...shedule];
    const dateJs = new Date(`${date.year}-${date.month}-${date.day}`);
    console.log("============///", data);
    if (copy_shedule[index] === undefined) {
      copy_shedule[index] = {
        hiker_user_record_manual_date: dateToString(dateJs),
        hiker_user_record_manual_out_walk: 0,
        hiker_user_record_manual_in_group: 0,
        hiker_user_record_manual_steps: 0,
        hiker_user_record_manual_minutes_walked: 0,
        hiker_us_tiredness_tiredness_id: 1,
        hiker_user_user_id: data?.hiker_user_id,
        sys_user_sys_user_id: data?.sys_user_id,
        group_group_id: data?.group_id,
      };
    }

    if (field === "hiker_user_record_manual_out_walk") {
      copy_shedule[index] = Object.assign(copy_shedule[index], {
        [field]:
          copy_shedule[index].hiker_user_record_manual_out_walk === 0 ? 1 : 0,
      });
      return setShedule(copy_shedule);
    }
    if (field === "hiker_user_record_manual_in_group") {
      copy_shedule[index] = Object.assign(copy_shedule[index], {
        [field]:
          copy_shedule[index].hiker_user_record_manual_in_group === 0 ? 1 : 0,
      });
      return setShedule(copy_shedule);
    }
    copy_shedule[index] = Object.assign(copy_shedule[index], {
      [field]: event.target.value,
    });
    setShedule(copy_shedule);

    if (
      copy_shedule[index]?.hiker_user_record_manual_steps.length > 0
    ) {
      let filter = copy_shedule.filter((item) => item !== undefined);
      setArrayPayload(filter);
    }
  };
  
  console.log("data")
  console.log(data)

  var groupLead = "";

    if(data.sys_user_name != null)
    {
      groupLead = data.sys_user_name + " " + data.sys_user_lastname;
  
    }else 
    {
      groupLead = "Sin lider de grupo asignado"
    }



  
  return (
    <div>
      <div className="container-fluid ">
        <ModalSuccesfull open={open} success={success} />

        <h1 className="h3 mb-2 text-gray-800 mb-4 ">Nuevo registro de pasos</h1>

        <h5 className="h5 mb-2 text-gray-800 mb-4">
          Caminante: {capitalize(data?.hiker_user_name)}{" "}
          {capitalize(data?.hiker_user_lastname)}
        </h5>
        <div className="row">
          <div className="col-lg-6">
            <h5 className="h5 mb-8 text-gray-800">
              Grupo asignado: 
              {data?.group_name ?
                data.group_name
                :
                " Sin asignar"
              }
            </h5>
          </div>
          <div className="col-lg-6">
            <h5 className="h5 mb-8 text-gray-800">
              Líder Grupo: {groupLead}
            </h5>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <h5 className="h5 mb-8 text-gray-800"> Mes: </h5>
            <select
              value={month}
              className="custom-select"
              onChange={(e) => pushDaysOfMonth(e.target.value, year)}
            >
              <option value="1">Enero</option>
              <option value="2">Febrero</option>
              <option value="3">Marzo</option>
              <option value="4">Abril</option>
              <option value="5">Mayo</option>
              <option value="6">Junio</option>
              <option value="7">Julio</option>
              <option value="8">Agosto</option>
              <option value="9">Septiembre</option>
              <option value="10">Octubre</option>
              <option value="11">Noviembre</option>
              <option value="12">Diciembre</option>
            </select>
          </div>
          <div className="col-md-3 mb-4">
            <h5 className="h5 mb-8 text-gray-800"> Año: </h5>
            <select
              className="custom-select"
              onChange={(e) => pushDaysOfMonth(month, e.target.value)}
            >
              {getDropList()}
            </select>
          </div>
        </div>
        {/* TABLA */}
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-body">
              <div className="table-responsive">
                <table
                  className="table table-bordered table-striped"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th>Días</th>
                      <th>Pasos diarios</th>
                      <th>¿Salí a caminar?</th>
                      <th>Minutos</th>
                      <th>¿Solo o en grupo?</th>
                      <th>¿Cuánto me cansé?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {daysOfMonth.map((item, index) => {
                      return (
                        <tr key={item.day}>
                          <th>
                            <p>{item.day_name}</p>
                            <p>{item.day}</p>
                          </th>
                          <th>
                            <input
                              type="number"
                              min={0}
                              className={`form-control form-control-solid `}
                              onChange={(event) =>
                                handleChange(
                                  index,
                                  event,
                                  "hiker_user_record_manual_steps",
                                  item
                                )
                              }
                            />
                          </th>
                          <th>
                            <input
                              type="checkbox"
                              className={`${item.day}`}
                              aria-label="Checkbox for following text input"
                              checked={
                                shedule[index]
                                  ?.hiker_user_record_manual_out_walk
                                  ? true
                                  : false
                              }
                              onClick={(event) =>
                                handleChange(
                                  index,
                                  event,
                                  "hiker_user_record_manual_out_walk",
                                  item
                                )
                              }
                              readOnly={true}
                            />
                          </th>
                          <th>
                            <input
                              type="number"
                              min={0}
                              className={`form-control form-control-solid  ${item.day}`}
                              onChange={(event) =>
                                handleChange(
                                  index,
                                  event,
                                  "hiker_user_record_manual_minutes_walked",
                                  item
                                )
                              }
                            />
                          </th>
                          <th>
                            <input
                              type="checkbox"
                              className={`${item.day}`}
                              aria-label="Checkbox for following text input"
                              onClick={(event) =>
                                handleChange(
                                  index,
                                  event,
                                  "hiker_user_record_manual_in_group",
                                  item
                                )
                              }
                              readOnly={true}
                            />
                          </th>
                          <th>
                            <select
                              className="custom-select"
                              onChange={(event) =>
                                handleChange(
                                  index,
                                  event,
                                  "hiker_us_tiredness_tiredness_id",
                                  item
                                )
                              }
                            >
                              {optionsTiredness.map((item) => (
                                <option
                                  key={item.hiker_user_tiredness_id}
                                  value={item.hiker_user_tiredness_id}
                                >
                                  {item.hiker_user_tiredness_state}
                                </option>
                              ))}
                            </select>
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-12 ">
              {/* COMENTARIO
              <div className="card-header py-3 mb-4">
                <h6 className="m-0 font-weight-bold text-primary">
                  Comentarios
                </h6>
              </div>
              <div className="col-lg-8 mb-4">
                <textarea
                  className="form-control form-control-solid"
                  placeholder="Ingrese su comentario aquí"
                >
                  
                </textarea>
              </div> */}
              <div className="row float-right mx-auto mb-4">
                <button
                  className={
                    !signError
                      ? `btn btn-primary mb-5`
                      : `btn btn-danger animate__animated animate__headShake`
                  }
                  type="button"
                  onClick={saveData}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
