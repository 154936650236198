import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { axiosUser } from "../../config/axios";
import { useSelector } from "react-redux";
import { calculateAge, formatDate } from "../../helpers/dateHelper";
import { Button } from "react-bootstrap";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { ConfirmModal } from "../Modal/ConfirmModal";
import SweetAlert from "react-bootstrap-sweetalert";
import EditProfessionalModal from "../../pages/Proffesionals/EditProfessionalModal/EditProfessionalModal";

export const TableListKinesiologos = () => {
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [professionalToDelete, setProfessionalToDelete] = useState();
  const [deletingProfessionalStatus, setDeletingProfessionalStatus] = useState();
  const [professionalToEdit, setProfessionalToEdit] = useState();
  const sys_user_id = useSelector((state) => state.user.user.sys_user_id);

  const getListKine = async () => {
    const professionalsResponse = await axiosUser.get(`/findall-kine?ignore_sys_id=`+sys_user_id);
    setList(professionalsResponse.data);
    setLoading(false);
  };

  useEffect(() => {
    getListKine();
  }, []);


  const onProfessionalDelete = async () => {
    try {
      await axiosUser.delete(
        `/${professionalToDelete}`
      );
      await getListKine()
      setDeletingProfessionalStatus('success')
    } catch (error) {
      setDeletingProfessionalStatus('error')
    } finally {
      setProfessionalToDelete()
    }
  }

  const onHideEditProfessional = async () => {
    setProfessionalToEdit()
  }
  
  const onUpdatedEditProfessional = async () => {
    setProfessionalToEdit()
    getListKine()
  }
  
  return (
    <div>
      {loading ? (
        <div>
          <div className="card">
            <div className="card-header text-info"></div>
            <div className="card-body">
              <h5 className="card-title text-info">
                <Loader type="Puff" color="#00BFFF" height={40} width={40} />
                Cargando...
              </h5>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {list ? (
            <div>
              {list.length > 0 ? (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Rut</th>
                      <th>Nombre</th>
                      <th>Edad</th>
                      <th>Correo</th>
                      <th>Rol</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((item) => (
                      <tr key={item?.sys_user_id}>
                        <th>{item?.sys_user_rut}</th>
                        <th>
                          {item?.sys_user_name} {item?.sys_user_lastname}
                        </th>
                        {calculateAge(item?.sys_user_born_date) <= 0 ? (
                          <th>Edad no definida</th>
                        ) : (
                          <th>
                            {item?.sys_user_born_date
                              ? calculateAge(item?.sys_user_born_date)
                              : "--"}
                          </th>
                        )}
                        <th>{item?.sys_user_email || "No posee"}</th>
                        <th>
                          {item?.rol_sys_user?.rol_sys_name || "No se encontro"}
                        </th>
                        <th>
                          <div>
                            <Button
                              className="ml-1"
                              variant="danger"
                              onClick={() =>
                                setProfessionalToDelete(item.sys_user_id)
                              }
                            >
                              <AiFillDelete />{" "}
                            </Button>
                            <Button
                              className="ml-1"
                              onClick={() =>
                                setProfessionalToEdit(item)
                              }
                            >
                              <AiFillEdit />{" "}
                            </Button>
                          </div>
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div>
              <h4>No hay registros</h4>
            </div>
          )}
        </div>
      )}
      <ConfirmModal
        open={!!professionalToDelete}
        onCancel={() => setProfessionalToDelete()}
        onConfirm={onProfessionalDelete}
        title={"Eliminar Profesional"}
        msg={"¿Está seguro que desea eliminar al profesional?"}
      />
      {deletingProfessionalStatus && (
        <SweetAlert
          type={deletingProfessionalStatus}
          onConfirm={() => setDeletingProfessionalStatus()}
          title={
            deletingProfessionalStatus === "success"
              ? "Profesional eliminado correctamente"
              : "Error al eliminar profesional"
          }
        />
      )}
      {professionalToEdit &&
        <EditProfessionalModal
          open={!!professionalToEdit}
          professional={professionalToEdit}
          onHide={onHideEditProfessional}
          onUpdated={onUpdatedEditProfessional}
        />
      }
    </div>
  );
};
