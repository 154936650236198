import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Line } from "react-chartjs-2";
export const GroupInCharge = () => {
  //Data para grafico camiantes
  const hikerChartData = {
    labels: ["1", "2", "3", "4", "5", "6"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        fill: false,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 0.2)",
        yAxisID: "y-axis-1",
      },
    ],
  };

  const hikerChartOptions = {
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
        },
      ],
    },
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">
              Los Caminantes:
            </h6>
          </div>
          <div className="card-body">
            <div className="row">
              {/* Pasos caminados Grupo */}
              <div className="col-xl-4 col-md-6 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Pasos caminados Grupo
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          44.230
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-shoe-prints fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Finalización programa */}
              <div className="col-xl-4 col-md-6 mb-4">
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                          Finalización programa:
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          31-09-2021
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="far fa-calendar-alt fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  Asistencia */}
              <div className="col-xl-4 col-md-6 mb-4">
                <div className="card border-left-info shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                          Asistencia
                        </div>
                        <div className="row no-gutters align-items-center">
                          <div className="col-auto">
                            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                              78%
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="far fa-calendar-check fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* graficos */}
            <div className="row">
              <div className="col-xl-4 col-md-6 mb-4">
                <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                  Progreso Programa:
                </div>
                <ProgressBar striped variant="success" now={60} />
              </div>
              <div className="col-xl-8 col-md-6 mb-4">
                <Line data={hikerChartData} options={hikerChartOptions} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
