import { axiosCalendar } from "../config/axios";

const postNewMessage = (data) => {
  return axiosCalendar.post(`/create-message`, data);
};

const editMessage = (data, id) => {
  return axiosCalendar.put(`/edit-message/${id}`, data);
};

const getCalendarEvents = (sys_user_id) => {
  return axiosCalendar.get(`/get-events/${sys_user_id}`)
}

const getEventsReceivers = (message_id) => {
  return axiosCalendar.get(`/get-receivers/${message_id}`)
}

const getRepeatWeekDay = (repeat_week_id) => {
  return axiosCalendar.get(`/get-repeat-week-days/${repeat_week_id}`)
}

const getRepeatMonthDay = (repeat_month_id) => {
  return axiosCalendar.get(`/get-repeat-month-day/${repeat_month_id}`)
}

const deleteEvent = (message_id) => {
  return axiosCalendar.delete(`/get-events/${message_id}`)
}


export{
  postNewMessage,
  getCalendarEvents,
  getEventsReceivers,
  getRepeatWeekDay,
  getRepeatMonthDay,
  deleteEvent,
  editMessage
}



