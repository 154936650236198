export function formatDate(date) {
  const d = new Date(date);

    return (
    [
      padTo2Digits(d.getUTCDate()),
      padTo2Digits(d.getUTCMonth() + 1),
      d.getFullYear(),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(d.getUTCHours()),
      padTo2Digits(d.getUTCMinutes()),
      padTo2Digits(d.getUTCSeconds()),
    ].join(':')
  );
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function calculateAge(birthday) {
  const d = new Date(birthday);
  const ageDifMs = new Date().getFullYear() - d.getFullYear();
  
  if(isNaN(ageDifMs) || ageDifMs <= 0 || ageDifMs == null || ageDifMs == "" || ageDifMs >= 150){
    return 0;
  }
  return ageDifMs;
}
export function timeDuration(DateA, DateB) {
  const date1 = new Date(DateA);
  const date2 = new Date(DateB);

  var diff = Math.abs(date1.getTime() - date2.getTime()) / 3600000;
  return diff;
}

export function getDaysOfMonth(year, month) {
  const daysOfMonth = new Date(year, month, 0).getDate();
  const diasSemana = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  let days = [];
  for (let day = 1; day <= daysOfMonth; day++) {
    const indice = new Date(year, month - 1, day).getDay();
    days.push({
      day: day,
      day_name: diasSemana[indice],
      month: month,
      year: year,
    });
  }
  return days;
}

export function setLimitDateInput() {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
  var yyyy = today.getFullYear();

  if (dd < 10) {
      dd = '0' + dd
  }

  if (mm < 10) {
      mm = '0' + mm
  }

  today = yyyy + '-' + mm + '-' + dd;

  return today;
}

export function calculateEndRecurDate(date, repeat_cicle){

}