import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { getGroupList } from "../../services/group.service";
//import { getHikersListSys } from "../../services/hikerUser.service";
import { axiosHiker } from "../../config/axios";
import { TableLastRegister } from "../../components/ListTable/TableLastRegister";
import { TableInformations } from "../../components/ListTable/TableInfomations";
import { TableListKinesiologos } from "../../components/ListTable/TableListKinesiologos";
import { TableUpcomingWalks } from "../../components/ListTable/TableUpcomingWalks";
import { CardsDashboard } from "../../components/CardsDetails/CardsDashboard";
import { CardDashboardKine } from "../../components/CardsDetails/CardKinesiologos";
import { TableUpcommingFinishGroup } from "../../components/ListTable/TableUpcommingFinishGroup";
import { PieChart } from "../../components/ChartJs/PieChart";
import { RateAttendence}  from "../../services/group.service"
export const Home = () => {
  const user = useSelector((state) => state);

  const sys_user_id = user.user.user.sys_user_id || [];

  const [listHikers, setListHikers] = useState(null);
  const [listKine, setListKine] = useState(null);
  const [countHikers, setCountHikers] = useState(0);
  const [countGroups, setCountGroups] = useState(0);
  const [rate_attendance, setRateAttendance] =  useState(0);
  const sys_rol_user = user.user.user.rol_sys_user_rol_sys_user_id;
  useEffect(() => {
    scroll.scrollToTop();
    RateAttendence(sys_user_id).then(resp =>{
      setRateAttendance(resp.data)
    })
    //console.log("dashboard", sys_user_id);
  }, []);
  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
      </div>{" "}
      {sys_rol_user === 5 ? <CardsDashboard /> : <CardDashboardKine sys_user_id={sys_user_id}/>}
      <div className="row">
        <div className="col-lg-6 mb-4">
          <div className="card shadow mb-4">
            <div className="card-header py-3 " style={{ height: 70 }}>
              <div className="row d-sm-flex justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Tablero Informativo
                </h6>
                <button className="btn btn-primary mr-2" type="button">
                  <Link
                    className="collapse-item text-light"
                    to="/user/create-news"
                  >
                    Nuevo Informativo
                  </Link>
                </button>
              </div>
            </div>
            <TableInformations />
          </div>
        </div>
        <div className="col-lg-6 mb-4">
          {sys_rol_user === 5 ? (
            <div className="card shadow mb-4">
              <div className="card-header" style={{ height: 70 }}>
                <h6 className="m-0 font-weight-bold text-primary">
                  Últimos caminantes registrados
                </h6>
              </div>
              <div
                className="card-body overflow-auto"
                style={{ height: 530 }}
              >
                <div className="table-responsive">
                  <TableLastRegister dataList={listHikers} />
                </div>
              </div>
            </div>
          ) : (
            <>
              {/* <div className="card shadow mb-4">
                <div className="card-header" style={{ height: 70 }}>
                  <h6 className="m-0 font-weight-bold text-primary">
                    Porcentaje asistencia última caminata
                  </h6>
                </div>
                <div
                  className="card-body"
                >
                  <PieChart value={rate_attendance}/>
                </div>
              </div> */}
            </>
          )}
        </div>

        <div className="col-lg-12">
          {sys_rol_user === 5 && (
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Usuarios del sistema
                </h6>
              </div>
              <div
                className="card-body overflow-auto "
                style={{ maxHeight: 300 }}
              >
                <div className="table-responsive">
                  <TableListKinesiologos />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Próximas Caminatas
              </h6>
            </div>
            <div
              className="card-body overflow-auto"
              style={{ maxHeight: 300 }}
            >
              <div className="table-responsive">
                <TableUpcomingWalks sys_user_id={sys_user_id} />
              </div>
            </div>
          </div>
        </div> */}

        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Próxima finalización de programa grupos
              </h6>
            </div>
            <div
              className="card-body overflow-auto "
              style={{ maxHeight: 300 }}
            >
              <div className="table-responsive">
                <TableUpcommingFinishGroup sys_user_id={sys_user_id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
