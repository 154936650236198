import React, { useState, useEffect } from "react";
import { axiosWalks } from "../../config/axios";
import { formatDate, timeDuration } from "../../helpers/dateHelper";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";

export const TableUpcommingFinishGroup = (props) => {
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(true);
  const sys_user_id = props.sys_user_id;

  const getListUpcomingWalks = () => {
    return axiosWalks.get(
      `/walks-list-next/${sys_user_id}?incomming=next_finish`
    );
  };

  useEffect(() => {
    console.log("sys_user_id ====", sys_user_id);
    getListUpcomingWalks().then((resp) => {
      setLoading(false);
      if (resp.data.length > 0) {
        setList(resp.data);
      }
    });
  }, []);

  return (
    <div>
      {loading ? (
        <div>
          <div className="card">
            <div className="card-header text-info"></div>
            <div className="card-body">
              <h5 className="card-title text-info">
                <Loader type="Puff" color="#00BFFF" height={40} width={40} />
                Cargando...
              </h5>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {list ? (
            <div>
              {list.length > 0 ? (
                <table
                  className="table table-bordered table-striped"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Grupo Líder </th>
                      <th>Estado</th>
                      <th>Próxima Caminata</th>
                      <th>Duración</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((item, index) => (
                      <tr key={index}>
                        <th>
                          {item.group ? (
                            <p>{item.group.group_name}</p>
                          ) : (
                            <p>Ninguno</p>
                          )}
                        </th>
                        <th>
                          {item.sys_user ? (
                            <p>
                              {item.sys_user.sys_user_name}{" "}
                              {item.sys_user.sys_user_lastname}
                            </p>
                          ) : (
                            <p>Sin datos</p>
                          )}
                        </th>
                        <th>{item.walks_state.walks_state_name}</th>
                        <th>{formatDate(item.walks_date_end)}</th>
                        <th>
                          {timeDuration(
                            item.walks_date_ini,
                            item.walks_date_end
                          )}{" "}
                          Horas
                        </th>
                        <th>
                          <Link
                            to={{
                              pathname: "/user/group-details",
                              state: { groupInfo: item.group },
                            }}
                            className="btn btn-primary"
                          >
                            Más Información
                          </Link>
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div>
              <h4>No hay registros</h4>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
