import React, { useState } from "react";
import Modal from "react-modal";
import { NewProffesional } from "../NewProffesional/NewProffesional";
import {
  checkRut,
  dateToString,
  utcDateToString,
  validateEmail,
} from "../../../helpers/basicFunctions";
import { setLimitDateInput } from "../../../helpers/dateHelper";
import SweetAlert from "react-bootstrap-sweetalert";
import { updateProfileService } from "../../../services/users.service";

const EditProfessionalModal = ({ open, professional, onHide, onUpdated }) => {
  const [rut, setRut] = useState(professional?.sys_user_rut);
  const [name, setName] = useState(professional?.sys_user_name);
  const [lastname, setLastname] = useState(professional?.sys_user_lastname);
  const [mail, setMail] = useState(professional?.sys_user_email);
  const [telefono, setTelefono] = useState(
    parseInt(professional?.sys_user_phone)
  );
  const [tipo, setTipo] = useState(professional?.rol_sys_user_rol_sys_user_id);
  const [bornDate, setBornDate] = useState(
    utcDateToString(professional?.sys_user_born_date)
  );
  const [maxDate] = useState(setLimitDateInput());
  const [messageAlert, setMessageAlert] = useState("");
  const [alert, setAlert] = useState(false);
  const [editingProfessionalStatus, setEditingProfessionalStatus] = useState();

  const validateUserName = (e) => {
    setRut(checkRut(e.target.value));
  };

  const formatEmail = (e) => {
    if (!validateEmail(e.target.value)) {
      setMail("");
      setMessageAlert("Debe ingresar un correo valido");
      setAlert(true);
    }
    setTimeout(() => {
      setAlert(false);
    }, 10000);
  };

  const closeAlert = () => {
    setAlert(false);
  };

  const submitHandler = async () => {
    const data = {
      sys_user_rut: rut,
      sys_user_name: name,
      sys_user_lastname: lastname,
      rol_sys_user_rol_sys_user_id: parseInt(tipo),
      sys_user_email: mail,
      sys_user_phone: telefono,
      sys_user_born_date: bornDate,
    };
    if (
      rut === "" ||
      name === "" ||
      lastname === "" ||
      mail === "" //||
      //telefono === "" ||
      //tipo === "" ||
      //bornDate === ""
    ) {
      setMessageAlert("Debe rellenar todos los campos requeridos (*)");
      setAlert(true);

      setTimeout(() => {
        setAlert(false);
      }, 10000);
    } else {
      updateProfileService(data)
        .then((res) => {
          setEditingProfessionalStatus('success')
        })
        .catch((err) => {
          setEditingProfessionalStatus('error')
          if (err.response.data.message) {
            setMessageAlert(err.response.data.message);
          } else {
            setMessageAlert("Hubo un error al actualizar al profesional!");
          }
          setAlert(true);
          setTimeout(() => {
            setAlert(false);
          }, 3000);
        });
    }
  };

  return (
    <>
      <Modal
        isOpen={open}
        contentLabel={"Editar profesional"}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
      >
        <div>
          <div className="card">
            <div className="card-body">
              <div>
                <div>
                  <h1 className="h3 mb-4 text-gray-800">Editar Profesional</h1>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-4">
                        <div className="py-3">
                          <h6 className="font-weight-bold text-primary">
                            Datos
                          </h6>
                        </div>
                        <div className="card-body">
                          <div className="d-flex">Los campos con * son obligatorios</div>
                          <form>
                            <div className="row">
                              {/* NOMBRE */}
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label htmlFor="exampleFormControlInput1">
                                    Rut*
                                  </label>
                                  <input
                                    name="rut"
                                    disabled
                                    type="rut"
                                    className="form-control form-control-user"
                                    id="InputRut"
                                    aria-describedby="RutHelp"
                                    placeholder="Ingrese su Rut"
                                    onBlur={(e) => validateUserName(e)}
                                    value={rut}
                                    onChange={(e) => setRut(e.target.value)}
                                  />
                                </div>
                              </div>
                              {/* NOMBRE */}
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label htmlFor="exampleFormControlInput1">
                                    Nombre*
                                  </label>
                                  <input
                                    className="form-control form-control-solid"
                                    id="nombreInput"
                                    type="text"
                                    placeholder="Ingrese su Nombre"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                              </div>
                              {/* APELLIDO */}
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label htmlFor="exampleFormControlInput1">
                                    Apellido*
                                  </label>
                                  <input
                                    className="form-control form-control-solid"
                                    id="apellidoInput"
                                    type="text"
                                    placeholder="Ingrese su Apellido"
                                    value={lastname}
                                    onChange={(e) =>
                                      setLastname(e.target.value)
                                    }
                                  />
                                  {/* <small id="nameInput" className="form-text text-muted">
                          Alerta: Debe ingresar apellido
                        </small> */}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              {/* TIPO USUARIO */}
                              <div className="col-md-4">
                                <label htmlFor="exampleFormControlInput1">
                                  Tipo usuario*
                                </label>
                                <select
                                  name=""
                                  id=""
                                  value={tipo}
                                  className="form-control"
                                  onChange={(e) => setTipo(e.target.value)}
                                >
                                  <option value="5">Administrador</option>
                                  <option value="15">Kinesiólogo</option>
                                </select>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label htmlFor="exampleFormControlInput1">
                                    Correo*
                                  </label>
                                  <input
                                    className="form-control form-control-solid"
                                    id="CorreoInput"
                                    type="text"
                                    placeholder="Ingrese su Correo"
                                    value={mail}
                                    onChange={(e) => setMail(e.target.value)}
                                    onBlur={(e) => formatEmail(e)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label htmlFor="exampleFormControlInput1">
                                    Télefono
                                  </label>
                                  <div>
                                    <input
                                      className="form-control form-control-phone-ext"
                                      id="TelefonoExtInput"
                                      type="number"
                                      placeholder="+569"
                                      disabled
                                    />
                                    <input
                                      className="form-control form-control-phone-number"
                                      id="TelefonoNumInput"
                                      type="number"
                                      placeholder="Ingrese su Telefono"
                                      value={telefono}
                                      onChange={(e) =>
                                        setTelefono(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label htmlFor="exampleFormControlInput1">
                                    Fecha nacimiento
                                  </label>
                                  <input
                                    className="form-control form-control-solid"
                                    id="fechaNacimientoInput"
                                    type="date"
                                    value={bornDate}
                                    max={maxDate}
                                    onChange={(e) =>
                                      setBornDate(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button
                    className="btn btn-outline-primary m-2"
                    onClick={submitHandler}
                  >
                    Guardar
                  </button>
                  <button className="btn btn-outline-danger " onClick={onHide}>
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {alert && (
        <SweetAlert
          danger
          title="Atención!"
          onConfirm={closeAlert}
          color="danger"
        >
          {messageAlert}
        </SweetAlert>
      )}
      {editingProfessionalStatus && (
        <SweetAlert
          type={editingProfessionalStatus}
          onConfirm={() => onUpdated()}
          title={
            editingProfessionalStatus === "success"
              ? "Profesional editado correctamente"
              : "Error al editar profesional"
          }
        />
      )}
    </>
  );
};

export default EditProfessionalModal;
