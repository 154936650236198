import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './userReducer';
import authReducer from './authenticatedReducer';
import groupReducer from './groupReducer';
import hikerReducer from './hikerReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'authenticated', 'group', 'hiker'],
};

const appReducer = combineReducers({
  user: userReducer,
  authenticated: authReducer,
  group: groupReducer,
  hiker: hikerReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'CLEAN_USER_DATA_SUCCESS') {
    localStorage.removeItem('token');
    state = undefined;
  }

  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
