import React from "react";
import Modal from "react-modal";
import Loader from "react-loader-spinner";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

export const ModalSuccesfull = (props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const { open, success } = props;

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <Modal
      isOpen={open}
      onRequestClose={closeModal}
      contentLabel="Register Modal"
      className="Modal"
      overlayClassName="Overlay"
      ariaHideApp={false}
    >
      {!success ? (
        <div>
          <div className="card">
            <div className="card-body">
              <h5 className="text-info">
                <Loader
                  type="Puff"
                  color="#00BFFF"
                  height={40}
                  width={40}
                  // timeout={3000} //3 secs
                />
                Procesando...
              </h5>
            </div>
          </div>
        </div>
      ) : (
        <div className="card">
             <div className="card-header text-info"></div>
              <div className="card-body">
               <IoIosCheckmarkCircleOutline className="text-info" style={{ fontSize: 50 }} />
               <h5 className="card-title text-info">Datos guardados con éxito </h5>
           </div>
         </div>
      )}
    </Modal>
  );
};
