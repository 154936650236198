const { DateTime } = require("luxon");

export const checkRut = (rut) => {
  const firstchar = rut.slice(0, -1);
  const rutFormat = firstchar.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
  const validator = rut.substr(rut.length - 1);
  const count = (rutFormat.match(/-/g) || []).length;

  if (count === 1) {
    const newRut = rutFormat.concat(validator);
    return newRut;
  }

  const replaceRut = rutFormat.replaceAll("-", "");
  const newRut = replaceRut.concat("-", validator);
  return newRut;
};

export const validateEmail = (email) => {
  return /([\w.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/gim.test(email);
};

export const stringToDate = (str) => {
  const date = new Date(str);
  date.setDate(date.getDate() + 1);
  return date;
};

export const calculateAge = (date) => {
  var diff_ms = Date.now() - date.getTime();
  var age_dt = new Date(diff_ms);
  return Math.abs(age_dt.getUTCFullYear() - 1970);
};

export const dateToString = (str) => {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
};

export const utcDateToString = (str) => {
  var date = new Date(str),
    mnth = ("0" + (date.getUTCMonth() + 1)).slice(-2),
    day = ("0" + date.getUTCDate()).slice(-2);
  return [date.getUTCFullYear(), mnth, day].join("-");
};

export const getPhone = (phone) => {
  if (phone) {
    const newPhone = phone.trim().substr(phone.length - 8);
    return `+569 ${newPhone}`;
  } else {
    return "Sin Telefono.";
  }
};
export const getAge = (fechaNacimiento) => {
  if (fechaNacimiento) {
    const age = calculateAge(stringToDate(fechaNacimiento));
    return age;
  } else {
    return "Sin fecha de nacimiento.";
  }
};

export const stringNumberWithDots = (number) => {
  var parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
};

export const stringDateToFullDate = (date) => {
  const day = date.split("-")[0];
  const month = date.split("-")[1];
  const year = date.split("-")[2];

  var monthString;
  switch (month) {
    case "01":
      monthString = "Enero";
      break;
    case "02":
      monthString = "Febrero";
      break;
    case "03":
      monthString = "Marzo";
      break;
    case "04":
      monthString = "Abril";
      break;
    case "05":
      monthString = "Mayo";
      break;
    case "06":
      monthString = "Junio";
      break;
    case "07":
      monthString = "Julio";
      break;
    case "08":
      monthString = "Agosto";
      break;
    case "09":
      monthString = "Septiembre";
      break;
    case "10":
      monthString = "Octubre";
      break;
    case "11":
      monthString = "Noviembre";
      break;
    case "12":
      monthString = "Diciembre";
      break;
    default:
      return "";
  }
  const fullDateString = `${day} de ${monthString} del ${year}`;
  return fullDateString;
};

export const stringDateToFullDateWithHour = (date) => {
  if (date) {
    const fecha = date.split("T")[0];
    const hora_prev = date.split("T")[1];

    // const year = fecha.split("-")[0];
    const month = fecha.split("-")[1];
    const day = fecha.split("-")[2];

    const hora = hora_prev.split(":")[0];
    const minutos = hora_prev.split(":")[1];

    var monthString;
    switch (month) {
      case "01":
        monthString = "Enero";
        break;
      case "02":
        monthString = "Febrero";
        break;
      case "03":
        monthString = "Marzo";
        break;
      case "04":
        monthString = "Abril";
        break;
      case "05":
        monthString = "Mayo";
        break;
      case "06":
        monthString = "Junio";
        break;
      case "07":
        monthString = "Julio";
        break;
      case "08":
        monthString = "Agosto";
        break;
      case "09":
        monthString = "Septiembre";
        break;
      case "10":
        monthString = "Octubre";
        break;
      case "11":
        monthString = "Noviembre";
        break;
      case "12":
        monthString = "Diciembre";
        break;
      default:
        return "";
    }
    const fullDateString = `${day} de ${monthString} a las ${hora}:${minutos}`;
    return fullDateString;
  } else {
    return "Sin Fecha";
  }
};

export const stringDateToTablesFormat = (date) => {
  const fecha = date.split("T")[0];
  const hora_prev = date.split("T")[1];

  const year = fecha.split("-")[0];
  const month = fecha.split("-")[1];
  const day = fecha.split("-")[2];

  const hora = hora_prev.split(":")[0];
  const minutos = hora_prev.split(":")[1];

  var horaSalida = "";
  if (hora > 12) {
    horaSalida = `${hora - 12}:${minutos} PM`;
  } else {
    horaSalida = `${hora}:${minutos} AM`;
  }

  const fullDateString = `${day}-${month}-${year} ${horaSalida}`;
  return fullDateString;
};

export const round = (num, decimals) => {
  const factorOfTen = Math.pow(10, decimals);
  return Math.round(num * factorOfTen) / factorOfTen;
};

export const calcularPorcentaje = (number) => {
  const res = number * 100;
  return `${res}%`;
};

export const formatLuxon = (date) => {
  const format = "dd/MM/yyyy";
  const newDate = DateTime.fromISO(date).toUTC().toFormat(format).toString();
  if (newDate === "Invalid DateTime") {
    return null;
  }
  return newDate;
};

export const formatLuxonTime = (date) => {

  const format = "hh:m:ss";
  const newDate = DateTime.fromISO(date).toUTC().toFormat(format).toString();
  if (newDate === "Invalid DateTime") {
    return null;
  }
  return newDate;
}