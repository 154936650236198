import { axiosLogin } from "../config/axios";

const loginService = (credentials) => {
  return axiosLogin.post("/login", credentials);
};

const recoveryPasswordService = (email) => {
  return axiosLogin.post("/recovery-password", email);
};

export { loginService, recoveryPasswordService };
