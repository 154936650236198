import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getAge, getPhone } from '../../../helpers/basicFunctions';
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import {
  getHikerHeaderAction,
  getHikerManualRegisterAction,
  getHikerEditAction,
  getHikerCheckHealthAction,
  getHikerQualityLifeAction,
  getHikerEntryMotivationAction,
} from '../../../redux/actions/hikerAction';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { getHikerListSysAction } from '../../../redux/actions/hikerAction';


export const HikerList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const hikerList = useSelector((state) => state.hiker.hikerList);
  const hikerlInfo = async (hiker) => {
    const hiker_user_id = hiker.hiker_user_id;

    //HikerHeader
    dispatch(getHikerHeaderAction(hiker_user_id));
    //HikerManualRegister
    dispatch(getHikerManualRegisterAction(hiker_user_id));
    //HikerEdit
    dispatch(getHikerEditAction(hiker));
    //HikerCheckHealth
    dispatch(getHikerCheckHealthAction(hiker_user_id));
    //HikerQualityLife
    dispatch(getHikerQualityLifeAction(hiker_user_id));
    //HikerEntryMotivation
    dispatch(getHikerEntryMotivationAction(hiker_user_id));
    await history.push(
      {
        pathname: '/user/hiker-info/',
      },
      {
        hikerData: hiker,
      }
    );
  };

  const handlePaginationNext = async(data) =>{
    if( data.currentPage < data.totalPages){      
      await data.currentPage++
      let page = data.currentPage;      
      let options = {
        page,
        size:10
      }
    dispatch(getHikerListSysAction(options));

    }
  }
  const handlePaginationPrev = async(data) =>{
    if( data.currentPage > 1){
      await data.currentPage--
      let page = data.currentPage;  
      let options = {
        page,
        size:10
      }
      await dispatch(getHikerListSysAction(options));
    }
  }
  return (
    <>
      <div className="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">Caminantes</h1>

        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">
              Caminantes registrados
            </h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table
                className="table table-bordered table-striped"
                id="dataTable"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th scope="col">Rut</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Télefono</th>
                    <th scope="col">Edad</th>
                    <th scope="col">Grupo</th>
                    <th scope="col">Opciones</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th scope="col">Rut</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Télefono</th>
                    <th scope="col">Edad</th>
                    <th scope="col">Grupo</th>
                    <th scope="col">Opciones</th>
                  </tr>
                </tfoot>
                <tbody>
                  {hikerList.data.map((hiker) => {
                    const phone = getPhone(hiker.hiker_user_phone);
                    const edad = getAge(hiker.hiker_user_born_date);
                    return (
                      <tr key={hiker.hiker_user_id}>
                        <th  scope="row">{hiker.hiker_user_rut}</th>
                        <th>
                          {hiker.hiker_user_name +
                            ' ' +
                            hiker.hiker_user_lastname}
                        </th>
                        <th>{phone}</th>
                        <th>{edad} años</th>
                        <th>{hiker.group_name}</th>
                        <th>
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => hikerlInfo(hiker)}
                          >
                            Ver
                          </button>
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* table 2 */}

              <Container>
                <Row >
                  <Col style={{display:'flex', justifyContent: 'flex-en', alignItems:'center'}}>
                    <button onClick={()=>handlePaginationPrev(hikerList.meta)}  style={{background:'transparent', border:0}} >< IoIosArrowBack /></button>
                    <div className="float-left"> {hikerList.meta.currentPage} / {hikerList.meta.totalPages}</div>
                    <button onClick={()=>handlePaginationNext(hikerList.meta)} style={{background:'transparent', border:0}}>< IoIosArrowForward /></button>
                  </Col>
                  
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
