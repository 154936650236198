import React from "react";
import Modal from "react-modal";
import Loader from "react-loader-spinner";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

export const ConfirmModal = ({open, onConfirm, onCancel, title, msg}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <Modal
      isOpen={open}
      onRequestClose={closeModal}
      contentLabel={title || "Confirmar"}
      className="Modal"
      overlayClassName="Overlay"
      ariaHideApp={false}
    >
      <div>
        <div className="card">
          <div className="card-body">
            <div className="mb-4 p-3">
              <div className="py-3 mb-4" style={{ width: 300 }}>
                <h4 className="m-0 text-primary ">
                  {msg || "¿Estas seguro que desea eliminar al usuario?"}
                </h4>
              </div>
              <div className="col-lg-12">
                <button
                  className="btn btn-outline-primary m-2"
                  onClick={onConfirm}
                >
                  Si
                </button>
                <button className="btn btn-outline-danger " onClick={onCancel}>
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
