import {
  AUTHENTICATED,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_ERROR,
  START_TIMEOUT,
  START_TIMEOUT_SUCCESS,
  START_TIMEOUT_ERROR,
} from "../types";

export function authenticatedAction(auth) {
  return (dispatch) => {
    dispatch(authenticated());
    try {
      dispatch(authenticatedSuccess(auth));
    } catch (error) {
      dispatch(authenticatedError(error));
    }
  };
}

const authenticated = () => ({
  type: AUTHENTICATED,
  payload: true,
});

const authenticatedSuccess = (auth) => ({
  type: AUTHENTICATED_SUCCESS,
  payload: auth,
});

const authenticatedError = (status) => ({
  type: AUTHENTICATED_ERROR,
  payload: status,
});

export function timeOutAction() {
  return (dispatch) => {
    dispatch(timeOut());
    try {
      setTimeout(() => {
        dispatch(timeOutSuccess());
      }, parseInt(process.env.REACT_APP_SESSIONTIMEOUT));
    } catch (error) {
      dispatch(timeOutError(error));
    }
  };
}

const timeOut = () => ({
  type: START_TIMEOUT,
  payload: true,
});

const timeOutSuccess = (auth) => ({
  type: START_TIMEOUT_SUCCESS,
  payload: true,
});

const timeOutError = (status) => ({
  type: START_TIMEOUT_ERROR,
  payload: status,
});
