import React, { useState, useEffect } from "react";
import { formatLuxon } from "../../helpers/basicFunctions";
import { getHikerDetailsCard } from "../../services/cards.service";

export const CardsGroupDetailsHeader = (props) => {
  const { info } = props;
  const hiker_user_id = props.hiker_user_id;
  const [data, setData] = useState({});

  useEffect(() => {
    async function fetchData() {
      if (hiker_user_id) {
        getHikerDetailsCard(hiker_user_id)
          .then((resp) => {
            setData(resp.data);
          })
          .catch((e) => console.log(e));
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <div className="row">
        {/* Progreso de Grupo */}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Progreso del Grupo
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {info?.progres_program || 0} %
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-percentage fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Semana de grupo */}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-success shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Semana de grupo
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {info?.week_program || 0}
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-calendar fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Integrante */}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-info shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                    Integrantes de grupo
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {info?.members || 0}
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-users fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Menta mensual */}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-warning shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                    Finalización programa:
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {formatLuxon(info?.dateEnd) || "--/--/--"}
                  </div>
                </div>

                <div className="col-auto">
                  <i className="fas fa-flag-checkered fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
