import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { calculateAge } from "../../../helpers/dateHelper";
import { DataTableComponent } from '../../../components/ListTable/DataTableComponent';
import { animateScroll as scroll } from "react-scroll";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Container, Row, Col } from "reactstrap";
import { axiosHiker } from "../../../config/axios";
import {getAllHikersUsers} from "../../../services/hikerUser.service";

import { TableHikerList } from "../../../components/ListTable/TableHikerList";
import { Button } from "react-bootstrap";
import { AiFillDelete } from 'react-icons/ai'
import SweetAlert from "react-bootstrap-sweetalert";
import { ConfirmModal } from "../../../components/Modal/ConfirmModal";

export const AdministrarHikers = () => {
  const user = useSelector((state) => state);
  const [listHikers, setListHikers] = useState(null);
  const [page, setPage] = useState(1);
  const [changePage, setChangePage] = useState(false);
  const [count, setCount] = useState(1);
  const [deletingHikerStatus, setDeletingHikerStatus] = useState();
  const [hikersSelected, setHikersSelected] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const currentDate = new Date();
  // Calculate the date 24 months earlier
  const sixMonthsEarlier = new Date(currentDate);
  sixMonthsEarlier.setMonth(currentDate.getMonth() - 24);
  const [fromExportDate, setFromExportDate] = useState(sixMonthsEarlier.toISOString().split('T')[0]);
  const [toExportDate, setToExportDate] = useState(new Date());
  const [hikerToDelete, setHikerToDelete] = useState();
  const getHikersListSys = (options = null) => {
    let size = options ? options.size : 5;
    return axiosHiker.get(
      `/get-userlist-sys?page=1&size=10&sortBy=updated_at&order=0`
    );
  };

  const onHikerDelete = async () => {
    try {
      await axiosHiker.delete(
        `/${hikerToDelete}`
      );
      const hikers = await getAllHikersUsers()
      setListHikers(hikers)
      setDeletingHikerStatus('success')
    } catch (error) {
      setDeletingHikerStatus('error')
    } finally {
      setHikerToDelete()
    }
  }

  const table_columns = [
    {
      name: "Rut",
      selector: row => (row.hiker_user_rut),
      sortable: true,
      grow: 2
    },
    {
      name: "Nombre",
      selector: row => (row.hiker_user_name + " " + row.hiker_user_lastname),
      sortable: true,
    },
    {
      name: "Edad",
      selector: row => (calculateAge(row.hiker_user_born_date) >= 2 ? calculateAge(row.hiker_user_born_date) + " Años" : "Edad no registrada"),
      sortable: true,
      hide: "md"
    },
    {
      name: "Grupo",
      selector: row => (row["group.group_name"] == null ? row["group.group_name"] = "Sin grupo" : row["group.group_name"]),
      sortable: true,
      hide: "sm"
    },
    {
      name: "Acciones",
      button: true,
      cell: row =>
          <>
            <Link
                    to={{
                      pathname: `/user/hiker-details`,
                      state: { data: row },
                    }}
                    className="btn btn-primary"
                  >
                    Ver{" "}
                  </Link>
            <Button className="ml-1" variant="danger" onClick={() => setHikerToDelete(row.hiker_user_id)}>
              <AiFillDelete />{" "}
          </Button>
          </>
    }
  ];

  useEffect(() => {
    scroll.scrollToTop();

    getAllHikersUsers().then((resp) => {
      setListHikers(resp);
    },);

    // getHikersListSys().then((resp) => {
    //   console.log("pag hikers")
    //   console.log
    //   setListHikers(resp);
    // });
  }, []);
  const handlePaginationNext = (data) => {
    if (count < data.totalPages && !changePage) {
      setPage(true);
      setCount(count + 1);
      let path = `/get-userlist-sys?page=${
        count + 1
      }&size=10&sortBy=updated_at&order=0`;
      axiosHiker.get(path).then((resp) => {
        setPage(false);
        setListHikers(resp);
      });
    }
  };
  const handlePaginationPrev = async (data) => {
    if (count > 1 && !changePage) {
      setPage(true);
      setCount(count - 1);
      let path = `/get-userlist-sys?page=${
        count - 1
      }&size=10&sortBy=updated_at&order=0`;
      axiosHiker.get(path).then((resp) => {
        setPage(false);
        setListHikers(resp);
      });
    }
  };

  const handleRowSelected = async (items) => {
    const ids = (items.selectedRows || [])?.map(h => h.hiker_user_id)
    console.log('items', ids);
    setHikersSelected(ids)
    
  }

  const exportHikers = async () => {
    setExportLoading(true);
    axiosHiker
      .post("/export", {
        ids: hikersSelected,
        dateFrom: fromExportDate,
        dateTo: toExportDate
      }, {
        responseType: 'blob', // Important: Set the response type to blob
      })
      .then((response) => {
        // Create a temporary URL for the downloaded file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'hikers.xlsx'); // Replace with the desired file name
        document.body.appendChild(link);
        link.click();
        

      })
      .catch((e) => console.error(e))
      .finally(() => setExportLoading(false));
  };
  
  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Caminantes</h1>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            {" "}
            Caminantes registrados
          </h6>
        </div>
        <div className="card-body">
          {!changePage ? (
            <div className="table-responsive">
              {listHikers ? (
                <div>
                  <div className="d-flex">
                    <Button onClick={exportHikers} disabled={!hikersSelected?.length || exportLoading}>Exportar</Button>
                    <input
                        className="form-control ml-2"
                        style={{maxWidth: '150px'}}
                        id="exportDateFrom"
                        type="date"
                        value={fromExportDate}
                        onChange={(e) => setFromExportDate(e.target.value)}
                      />
                    <input
                        className="form-control ml-2"
                        style={{maxWidth: '150px'}}
                        id="exportDateTo"
                        type="date"
                        value={toExportDate}
                        onChange={(e) => setToExportDate(e.target.value)}
                      />
                    </div>
                  <DataTableComponent

                    data={listHikers.data}
                    columns={table_columns}
                    handleRowSelected={handleRowSelected}
                    selectableRows={true}
                  />
                  {/* *** OLD TABLE ****
                  
                  <TableHikerList
                    dataList={listHikers}
                    fields={["Rut", "Nombre", "Edad", "Grupo", "Acciones"]}
                  /> */}

                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-en",
                      alignItems: "center",
                    }}
                  >
                    <button
                      onClick={() => handlePaginationPrev(listHikers.data.meta)}
                      style={{ background: "transparent", border: 0 }}
                    >
                      <IoIosArrowBack />
                    </button>
                    <div className="float-left">
                      {count} / {listHikers.data.meta.totalPages}
                    </div>
                    <button
                      onClick={() => handlePaginationNext(listHikers.data.meta)}
                      style={{ background: "transparent", border: 0 }}
                    >
                      <IoIosArrowForward />
                    </button>
                  </div> */}
                </div>
              ) : (
                <div>
                  <div className="card">
                    <div className="card-header text-info"></div>
                    <div className="card-body">
                      <h5 className="card-title text-info">
                        <Loader
                          type="Puff"
                          color="#00BFFF"
                          height={40}
                          width={40}
                        />
                        Cargando...
                      </h5>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="card">
                <div className="card-header text-info"></div>
                <div className="card-body">
                  <h5 className="card-title text-info">
                    <Loader
                      type="Puff"
                      color="#00BFFF"
                      height={40}
                      width={40}
                    />
                    Cargando...
                  </h5>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ConfirmModal
        open={!!hikerToDelete}
        onCancel={() => setHikerToDelete()}
        onConfirm={onHikerDelete}
        title={"Eliminar caminante"}
        msg={"¿Está seguro que desea eliminar al caminante?"}
      />
      {deletingHikerStatus && (
        <SweetAlert
          type={deletingHikerStatus}
          onConfirm={() => setDeletingHikerStatus()}
          title={
            deletingHikerStatus === "success"
              ? "Usuario eliminado correctamente"
              : "Error al eliminar caminante"
          }
        />
      )}
    </div>
  );
};
