import React, {useEffect, useState} from "react";
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { axiosUser } from '../../../config/axios';
import Loader from "react-loader-spinner";
import { DataTableComponent } from '../../../components/ListTable/DataTableComponent';

import {
  loadGroupHeaderAction,
  loadGroupNextHikesAction,
} from "../../../redux/actions/groupAction";
import { loadUserToEditAction } from "../../../redux/actions/userActions";
import { Button } from "react-bootstrap";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import SweetAlert from "react-bootstrap-sweetalert";
import { ConfirmModal } from "../../../components/Modal/ConfirmModal";
import EditProfessionalModal from "../EditProfessionalModal/EditProfessionalModal";

export const ProffesionalList = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const [professionals, setProfessionals] = useState(null);
  const [deletingProfessionalStatus, setDeletingProfessionalStatus] = useState();
  const [professionalToDelete, setProfessionalToDelete] = useState();
  const [professionalToEdit, setProfessionalToEdit] = useState();

  const sys_user_id = useSelector((state) => state.user.user.sys_user_id);

  const getListKine = async () => {
    const professionalsResponse = await axiosUser.get(`/findall-kine?ignore_sys_id=`+sys_user_id);
    setProfessionals(professionalsResponse.data);
  };

  
  const onHideEditProfessional = async () => {
    setProfessionalToEdit()
  }
  
  const onUpdatedEditProfessional = async () => {
    setProfessionalToEdit()
    getListKine()
  }

  const onProfessionalDelete = async () => {
    try {
      await axiosUser.delete(
        `/${professionalToDelete}`
      );
      await getListKine()
      setDeletingProfessionalStatus('success')
    } catch (error) {
      setDeletingProfessionalStatus('error')
    } finally {
      setProfessionalToDelete()
    }
  }

  useEffect(() => {
    getListKine()
  }, []);

  const table_columns = [
    {
      name: "Rut",
      selector: row => (row.sys_user_rut),
      sortable: true,
      grow: 2
    },
    {
      name: "Nombre",
      selector: row => (row.sys_user_name + " " + row.sys_user_lastname),
      sortable: true,
    },
    {
      name: "Teléfono",
      selector: row => (row.sys_user_phone == "" || row.sys_user_phone == null ? "No definido" : row.sys_user_phone),
      sortable: true
    },
    {
      name: "Correo",
      selector: row => (row.sys_user_email),
      sortable: true
    },
    {
      name: "Rol",
      selector: row => (row.rol_sys_user.rol_sys_name),
      sortable: true,
      hide: "sm"
    },
    {
      name: "Acciones",
      selector: row => 
      <div>
        <Button className="ml-1" variant="danger" onClick={() => setProfessionalToDelete(row.sys_user_id)}>
          <AiFillDelete />{" "}
        </Button>
        <Button className="ml-1" onClick={() => setProfessionalToEdit(row)}>
          <AiFillEdit />{" "}
        </Button>
      </div>,
      sortable: true,
      hide: "sm"
    },
    // {
    //   name: "Estado",
    //   selector: row => (row.sys_user_active ? "Habilitado": "No Habilitado"),
    //   sortable: true,
    //   hide: "sm"
    // },
    //  {
    //    name: "Acciones",
    //    button: true,
    //    cell: row =>
    //        <>
    //          <Link
    //                  to={{
    //                    pathname: `/user/hiker-details`,
    //                    state: row
    //                  }}
    //                  className="btn btn-primary"
    //                >
    //                  Ver{" "}
    //                </Link>
    //        </>
    //  }
  ];

  const proffesionalInfo = async (userData) => {
    dispatch(loadGroupHeaderAction({ user_id: userData.sys_user_id }));
    dispatch(loadGroupNextHikesAction({ user_id: userData.sys_user_id }));
    dispatch(loadUserToEditAction(userData));
    await history.push(
      {
        pathname: "/user/proffesional-info/",
      },
      {
        userData: userData,
      }
    );
  };
  
  return (
    <>
      <div className="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">Kinesiólogos</h1>

        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Kinesiólogos</h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="table-responsive">
                {professionals ? (
                  <DataTableComponent
                    data={professionals}
                    columns={table_columns}
                  />
                ) : (
                  <div>
                    <div className="card">
                      <div className="card-header text-info"></div>
                      <div className="card-body">
                        <h5 className="card-title text-info">
                          <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={40}
                            width={40}
                          />
                          Cargando...
                        </h5>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        open={!!professionalToDelete}
        onCancel={() => setProfessionalToDelete()}
        onConfirm={onProfessionalDelete}
        title={"Eliminar Profesional"}
        msg={"¿Está seguro que desea eliminar al profesional?"}
      />
      {deletingProfessionalStatus && (
        <SweetAlert
          type={deletingProfessionalStatus}
          onConfirm={() => setDeletingProfessionalStatus()}
          title={
            deletingProfessionalStatus === "success"
              ? "Profesional eliminado correctamente"
              : "Error al eliminar profesional"
          }
        />
      )}
      {professionalToEdit &&
        <EditProfessionalModal
          open={!!professionalToEdit}
          professional={professionalToEdit}
          onHide={onHideEditProfessional}
          onUpdated={onUpdatedEditProfessional}
        />
      }
    </>
  );
};
