import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { profileUrl } from '../../config/axios';
import undraw_profile from "../../assets/images/icons/undraw_profile.svg";
import { useSelector } from "react-redux";
import { cleanUserAction } from "../../redux/actions/userActions";

const defaultState = {
  open: false,
};

export const HomeNavbar = () => {
  const node = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    document.addEventListener("mousedown", toggle);
    return () => {
      document.removeEventListener("mousedown", toggle);
    };
  }, []);

  const toggle = (e) => {
    if (node.current.contains(e.target)) {
      setState({
        open: true,
      });
      return;
    }
    setState({
      open: false,
    });
  };

  const redirectToProfile = () => {
    setState({
      open: !state.open,
    });
  };
  const exit = () => {
    setState({
      open: !state.open,
    });
    dispatch(cleanUserAction());
  };
  return (
    <>
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
        >
          <i className="fa fa-bars"></i>
        </button>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown no-arrow d-sm-none">
            <a
              className="nav-link dropdown-toggle"
              id="searchDropdown"
              role="button"
              href="/#"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-search fa-fw"></i>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
              aria-labelledby="searchDropdown"
            >
              <form className="form-inline mr-auto w-100 navbar-search">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control bg-light border-0 small"
                    placeholder="Search for..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="button">
                      <i className="fas fa-search fa-sm"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>

          <div className="topbar-divider d-none d-sm-block"></div>
          <li className="nav-item dropdown no-arrow" ref={node}>
            <div
              className="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"

              // onClick={toggle.bind(this)}
            >
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                {user.sys_user_name + " " + user.sys_user_lastname}
              </span>
              <img
                alt=""
                className="img-profile rounded-circle"
                src={undraw_profile}
              />
            </div>

            <div
              className={
                "dropdown-menu dropdown-menu-right shadow animated--grow-in collapse" +
                (state.open ? " in" : "")
              }
              aria-labelledby="userDropdown"
            >
              <Link
                className="dropdown-item"
                to="/user/profile"
                onClick={redirectToProfile}
              >
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                Perfil
              </Link>
              <div className="dropdown-divider"></div>
              <Link
                to="#"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#logoutModal"
                // to="/courses?sort=name"
                onClick={() => exit()}
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Cerrar sesión
              </Link>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
};
