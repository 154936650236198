import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { InfoCaminatas } from "./InfoCaminatas";
import Modal from "react-modal";
import { Button, Modal as ModalBS, Form } from 'react-bootstrap';
import SweetAlert from "react-bootstrap-sweetalert";
import Select from 'react-select';
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { getAllHikersListSys, getHikersByUser } from "../../../../../services/hikerUser.service";
import { updateHikerGroup } from "../../../../../services/hikerUser.service";
import { getGroupListMembers, updateGroupDetailsService } from "../../../../../services/group.service";
import { getGroupDetailcard } from "../../../../../services/cards.service";
import { CardsGroupDetailsHeader } from "../../../../../components/CardsDetails/CardsGroupDetailsHeader";
import { getGroup } from "../../../../../services/group.service";
import { getGroupName } from "../../../../../services/group.service";
import {getUsersService} from "../../../../../services/users.service"
import { CardWalksInfo } from "../../../../../components/CardsDetails/CardWalksInfo";
import { NewCaminata } from "../../../../../components/Caminatas/NewCaminata";
import { stateGroup } from "../../../../../services/group.service";


function ListMembers(props) {

  const group_id = props.group_id;
  const [members, setMembers] = useState([]);
  const [memberBackup, setMemberBackup] = useState([]);
  const [resp, setResp] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [editMembers, setEditMembers] = useState(false);
  const [open3, setIsOpenModal3] = useState(false);
  const [option, setOption] = useState([]);
  const [membersToAdd, setMembersToAdd] = useState([]);
  const [membersToRemove, setMembersToRemove] = useState([]);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const toggleSweetAlert = (toggle) => {
    setDisplayAlert(toggle)
  }

  const handleEditMembers = (toggle) => {

    if(!toggle) {
      setMembers(memberBackup);
      setMembersToAdd([]);
      setMembersToRemove([]);
    }

    setEditMembers(toggle);
  }

  const selectMembers = (select) => {
    let data = [];
    select.map((e) => {
      data = [...data, e.value];
    });

    setMembersToAdd(data);

  };

  const addNewMembers = () => {
    
    closeModal3();
    for (let i = 0; i < membersToAdd.length; i++) {
      setMembers(members => [...members, membersToAdd[i]]);
    }

  }

  const openModal3 = () => {
    setIsOpenModal3(true);
  };
  const closeModal3 = () => {
    setIsOpenModal3(false);
  };

  const removeMember = (index, member) => {

    setMembersToRemove(membersToRemove => [...membersToRemove, member]);
    let removeMembers = [...members]; // copying the old members array
    if (index > -1) {
      removeMembers.splice(index, 1);
    }
    setMembers(removeMembers);
  }

  const saveNewMembers = () => {

    if(membersToAdd.length > 0){
      membersToAdd.map((e) => {

        let data = {'hiker_user_id': e.hiker_user_id, 'group_id': group_id}

        updateHikerGroup(data).then((resp) => {
        })
        .catch((err) => {
          console.log("update group error intern");
          console.log(err);
        });
      });
    }
    

    //members to remove
    if(membersToRemove.length > 0){
      membersToRemove.map((e) => {

        let data = {'hiker_user_id': e.hiker_user_id, 'group_id': null}

        updateHikerGroup(data).then((resp) => {
          console.log("succesfully removed from group");
          console.log(resp);
        })
        .catch((err) => {
          console.log("update group error intern");
          console.log(err);
        });
      });
    }


    
    // si se guardan los datos correctamente
    // hacemos backup del ultimo estado.
    //setMemberBackup(members);

    //Luego cambiamos el estado de los botones.
    handleEditMembers(false);
    
    getGroupListMembers(group_id)
    .then((resp) => {
      setMembers(resp.data);
      setMemberBackup(resp.data);
      setResp(true);
      setRefreshKey(oldKey => oldKey +1);
    })
    .catch((e) => {
      console.log(e);
    });

    setAlertMessage({"type": 'success', 
                             "title": "Atención", 
                             "message": "Datos guardados exitosamente!"});
    toggleSweetAlert(true);


  }

  useEffect(() => {
    getGroupListMembers(group_id)
      .then((resp) => {
        setMembers(resp.data);
        setMemberBackup(resp.data);
        setResp(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [refreshKey]);

  //Set select options
  useEffect(() => {
    getHikersByUser()
      .then((resp) => {
        let option = [];
        let group = "";
        resp.data.map((e) => {


            if(e.group_group_id === null){
              group = "Sin Grupo";

            }else {

              group = "Grupo ya asignado"

            }

            if(e.group_group_id != group_id){
              option.push({
                value: e,
                label: `(${group}) - ${e.hiker_user_name} ${e.hiker_user_lastname}  ${e.hiker_user_rut}`,
              });
            }

        });

        setOption(option);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  async function getUserList(group_id) {

    return await getGroupName(group_id);
  }

  function calculateAge(birthday) {
    const birthday_arr = birthday.split("-");
    const birthday_date = new Date(
      birthday_arr[0],
      birthday_arr[1] - 1,
      birthday_arr[2]
    );
    const ageDifMs = Date.now() - birthday_date.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);

  }
  return (
    <div>
      {displayAlert ?
        <SweetAlert
          type={alertMessage.type}
          title={alertMessage.title}
          onConfirm={opt => toggleSweetAlert(false)}
          // onCancel={this.onCancel}
        >
          {alertMessage.message}
        </SweetAlert>
      : null}
          <ModalBS show={open3} onHide={closeModal3}>
          <Form>
          <ModalBS.Header closeButton>
            <ModalBS.Title>Nuevos integrantes</ModalBS.Title>
          </ModalBS.Header>
          <ModalBS.Body>
            <label htmlFor="SelectParticipantes">
              Seleccionar participantes:
            </label>
            <Select
              isMulti
              options={option}
              closeMenuOnSelect={false}
              placeholder="Selecciona aquí"
              noOptionsMessage= {(text) => "No hay más participantes para añadir"}
              onChange={(selected) => selectMembers(selected)}
            />
          </ModalBS.Body>
          <ModalBS.Footer>
            <Button variant="primary" type="button" onClick={param => addNewMembers()}>
              Añadir
            </Button>
            <Button variant="danger" onClick={closeModal3}>
              Cancelar
            </Button>
          </ModalBS.Footer>
          </Form>
        </ModalBS>  
      {/* Table */}
      <div>
        <label>
          Integrantes: <span>{members.length}</span>
        </label>
        {members.length > 0 ?
        <div style={{ overflow: "auto", height: 400 }}>
        <table
          className="table table-bordered table-striped"
          id="dataTable"
          width="100%"
          cellSpacing="0"
          
        >
          <thead>
            <tr>
              {editMembers ? 
                <th>Opciones</th>  
              : null}
              <th>Participante</th>
              <th>Rut</th>
              <th>Edad</th>
              {!editMembers ?
              <th>Opciones</th>  
              : null 
              }
            </tr>
          </thead>
            {members.map((member) => (
                        <tbody key={member.hiker_user_id}>
                          <tr>
                            {editMembers ? 
                              <th>
                                <button className="btn btn-danger btn-sm"
                                        onClick={params => removeMember(members.indexOf(member), member)}>
                                  Remover
                                </button>
                              </th> 
                                :null
                            }
                            <th>
                              {member.hiker_user_name} {member.hiker_user_lastname}
                            </th>
                            <th>{member.hiker_user_rut}</th>
                            {member?.hiker_user_born_date ? (
                              <th>{calculateAge(member.hiker_user_born_date)} Años</th>
                            ) : (
                              <th>Edad no registrada</th>
                            )}
                             {!editMembers ? 
                            <th>
                              <Link
                                to={{
                                  pathname: `/user/hiker-details`,
                                  state: { data: member },
                                }}
                                className="btn btn-primary"
                              >
                                Ver{" "}
                              </Link>
                            </th>
                             :
                             null
                             }
                          </tr>
                        </tbody>
          ))}
        </table>
        </div> 
        : 
        <div>
           <p>Grupo sin integrantes</p>
        </div>
        }
      </div>
      {/* buttons */}
      <div style={{marginTop: "8px"}}>
        {!editMembers ? 
        <button 
            className="btn btn-primary ml-1"
            onClick={param => handleEditMembers(true)}
          >
            Editar integrantes
        </button>
        :
        <div>
          <button 
              className="btn btn-danger ml-1"
              onClick={param => handleEditMembers(false)}
            >
              Cancelar
          </button>
          <button 
              className="btn btn-warning ml-1"
              onClick={param => setIsOpenModal3(true)}
            >
              Añadir integrantes
          </button>
          <button 
              className="btn btn-success ml-1"
              onClick={param => saveNewMembers()}
            >
              Guardar cambios
          </button>
        </div>
        }
        </div>
    </div>
    
  );
}

export const GroupDetails = () => {
  const user = useSelector((state) => state.user.user);
  const history = useHistory();
  const location = useLocation();
  const { group_id } = location.state?.groupInfo || "";
  const [render, setRender] = useState(null);
  const [cardInfo, setCardInfo] = useState({});
  const [refreshKey, setRefreshKey] = useState(0);
  const [groupDetail, setGroupDetail] = useState(null);
  const [open1, setIsOpenModal1] = useState(false);
  const [open2, setIsOpenModal2] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [leaderOpts, setLeaderOpts] = useState(null);  
  const [leaderSelected, setLeaderSelected] = useState(null);
  const [displayOptions, setDisplayOptions] = useState(false);
  const [displayInputs, setDisplayInputs] = useState(true);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const toggleSweetAlert = (toggle) => {
    setDisplayAlert(toggle)
  }

  const handleDisplayEdit = (toggle) => {

    setDisplayOptions(toggle);
    setDisplayInputs(!toggle);
  }

  const callEndPointGroupDetails = () => {
    if (group_id) {
      getGroup(group_id)
        .then((resp) => {
          setGroupDetail(resp.data);
          setGroupName(resp.data.group_name);
          setLeaderSelected(resp.data.sys_users);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const callEndpointSysUsers = () => {
    getAllHikersListSys()
      .then((resp) => {
        console.log('resp', resp);
        setLeaderOpts(resp.data);
      })
  };

  const groupNameChange = (value) => {
    setGroupName(value);
  };

  const leaderChange = (selectedOpt) => {
    setLeaderSelected(selectedOpt)
  };

  const updateGroupDetails = () => {
    // TODO: check this to update N leaders of groups instead of only one
    // now leaderSelected is a list of users
    if(JSON.stringify(groupDetail.sys_users) != JSON.stringify(leaderSelected) || groupName != groupDetail.group_name) {

        var data = {"group_id": groupDetail.group_id, 
                    "group_name": groupName, 
                    "sys_users": leaderSelected}
        
        updateGroupDetailsService(data).then((resp) => {
            setAlertMessage({"type": 'success', 
                             "title": "Atención", 
                             "message": "Datos guardados exitosamente!"});
            //update groupDetail array                
            groupDetail.group_name = groupName;
            groupDetail.sys_users = leaderSelected;        
            // groupDetail.sys_user_sys_user_id = leaderSelected.sys_user_id;
            toggleSweetAlert(true);
            handleDisplayEdit(false);

        }).catch((err) => {
          console.log("error", err);
           setAlertMessage({"type": 'error', 
                            "title": "Atención", 
                            "message": "No se han podido actualizar los datos"});
           toggleSweetAlert(true);
        })

        

    }else {
      setAlertMessage({"type": 'warning', 
                       "title": "Atención", 
                       "message": "Tienes que modificar los campos antes de guardar"});
      toggleSweetAlert(true);
    }
    

  };

  useEffect(() => {
    callEndpointSysUsers();
    callEndPointGroupDetails();
    getGroupDetailcard(group_id)
      .then((resp) => {
        setCardInfo(resp.data);
        console.log("Card info");
        console.log(resp.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const openModal1 = (render) => {
    setIsOpenModal1(true);
  };
  const closeModal1 = () => {
    setIsOpenModal1(false);
  };
  const openModal2 = (render) => {
    setIsOpenModal2(true);
  };
  const closeModal2 = () => {
    setIsOpenModal2(false);
  };

  const finish = () => {
    setProcessing(true);
    const payload = {
      group_state_state: 4,
    };
    stateGroup(group_id, payload)
      .then((resp) => {
        console.log("FINISH GROUP", resp);
        setProcessing(true);
        if (resp) {
          setSuccess(true);
          setTimeout(() => {
            setIsOpenModal1(false);
            setSuccess(false);
            callEndPointGroupDetails();
            window.location.reload();
          }, 3000);
        }
      })
      .catch((e) => {
        console.log(e);
      });

  };
  return (
    <div className="container-fluid">
      {displayAlert ?
        <SweetAlert
          type={alertMessage.type}
          title={alertMessage.title}
          onConfirm={opt => toggleSweetAlert(false)}
          // onCancel={this.onCancel}
        >
          {alertMessage.message}
        </SweetAlert>
      : null}
      <Modal
        isOpen={open1}
        onRequestClose={closeModal1}
        contentdiv="Asistencia Modal"
        ariaHideApp={false}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div role="form" className="user card shadow">
          {success ? (
            <div className="card">
              <div className="card-header text-info"></div>
              <div className="card-body">
                <IoIosCheckmarkCircleOutline
                  className="text-info"
                  style={{ fontSize: 50 }}
                />
                <h5 className="card-title text-info">
                  Datos guardados con éxito{" "}
                </h5>
              </div>
            </div>
          ) : (
            <div style={{ width: 400, overflow: "overlay" }}>
              {!processing ? (
                <div className=" mb-4">
                  <div className="btn-modal-close"></div>
                  <div className="card-header py-3">
                    <h5 className="m-0 font-weight-bold text-danger">
                      Atención{" "}
                    </h5>
                  </div>

                  <div className="card-body">
                    <p> ¿Está seguro que desea finalizar el grupo?</p>

                    <div className="row">
                      <div className="col">
                        <button
                          className="btn btn-primary float-right"
                          onClick={() => finish()}
                        >
                          Si
                        </button>
                      </div>
                      <div className="col col-lg-2">
                        <button
                          className="btn btn-danger float-right"
                          onClick={() => closeModal1()}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="card">
                    <div className="card-header text-info"></div>
                    <div className="card-body">
                      <h5 className="card-title text-info">
                        <Loader
                          type="Puff"
                          color="#00BFFF"
                          height={40}
                          width={40}
                        />
                        Procesando...
                      </h5>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
      {/* <ModalBS show={open2} onHide={closeModal2}>
          <Form>
          <ModalBS.Header closeButton>
          </ModalBS.Header>
          <ModalBS.Body>
          <NewCaminata open={true} group_id={group_id} />
          </ModalBS.Body>
          </Form>
        </ModalBS> */}
      {/* <Modal
        isOpen={open2}
        onRequestClose={closeModal2}
        contentLabel="Register Modal"
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
      >
        <NewCaminata open={true} group_id={group_id} />
      </Modal> */}
              
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        {groupDetail?.group_state_programs[0]?.group_state_state === 4 ? (
          <h1 className="h3 mb-0 text-gray-800">Grupo finalizado</h1>
        ) : (
          <h1 className="h3 mb-0 text-gray-800">Estado de Grupo</h1>
        )}
      </div>
      <CardsGroupDetailsHeader info={cardInfo} />
      {/* <NewCaminata /> */}
      {groupDetail ? (
        <div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h4 className="m-0 font-weight-bold text-primary">
                    Ficha de Grupo
                  </h4>
                </div>

                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <div className="col-md-12 mb-4">
                        <label htmlFor="exampleFormControlInput1">
                          Nombre de Grupo:
                        </label>
                        <input type="hidden" value={groupDetail?.group_id}/>
                        <input
                          className="form-control form-control-solid"
                          type="text"
                          onChange={e => groupNameChange(e.target.value)}
                          value={groupName}
                          disabled={displayInputs}
                        />
                      </div>

                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="exampleFormControlInput1">
                            Lideres de grupo:
                          </label>
                          {user.rol_sys_user_rol_sys_user_id == 5 ? (
                          <Select
                            isMulti
                            name="sys_users"
                            options={leaderOpts}
                            value={leaderSelected}
                            getOptionLabel={(option) => option.sys_user_name + " " + option.sys_user_lastname}
                            getOptionValue={(option) => option.sys_user_id}
                            onChange={leaderChange}
                            isDisabled={displayInputs}
                          />) : (
                          <input 
                            type="text"
                            value={`${groupDetail?.sys_user.sys_user_name} ${groupDetail?.sys_user.sys_user_lastname}`}
                            className="form-control form-control-solid"
                            disabled/>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div
                        className="mb-3"
                        // style={{ overflow: "auto", height: 400 }}
                      >
                        <ListMembers group_id={group_id} />
                      </div>
                    </div>
                  </div>
                    <div className="d-flex justify-content-end">
                       {displayOptions ? 
                        <div>
                          <button 
                            className="btn btn-success mr-1"
                            onClick={() => updateGroupDetails()}
                          >
                            Guardar cambios
                          </button>
                          <button 
                            className="btn btn-danger mr-1"
                            onClick={() => handleDisplayEdit(false)}
                          >
                            Cancelar edición
                          </button>
                        </div>
                      : 
                        <> 
                        {user.rol_sys_user_rol_sys_user_id === 5 ? (
                          <button 
                          className="btn btn-primary mr-1"
                          onClick={() => handleDisplayEdit(true)}
                          >
                            Editar Ficha
                          </button>
                        ): 
                        null}
                        </>
                       
                       }
   
                      {groupDetail?.group_state_programs[0]?.group_state_state ===
                      4 ? (
                        ""
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={() => openModal1()}
                        >
                          Finalizar grupo
                        </button>
                      )}
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card shadow mb-4">
                <div className="card-header py-3 mb-4">
                  <h4 className="m-0 font-weight-bold text-primary ">
                    {" "}
                    Caminatas
                  </h4>
                </div>
                <div className="col-lg-12">
                  {groupDetail?.group_state_programs[0]?.group_state_state ===
                  4 ? (
                    ""
                  ) : (
                    <>
                      {/* <button
                        className="float-right mx-auto btn btn-primary"
                        onClick={() => openModal2("AgendarCaminata")}
                      >
                        Agendar nueva caminata
                      </button> */}
                    </>
                  )}
                </div>
                <CardWalksInfo info={cardInfo} />
                {/* <InfoCaminatas
                  group_id={group_id}
                  group_state={
                    groupDetail?.group_state_programs[0]?.group_state_state
                  }
                /> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="card">
            <div className="card-header text-info"></div>
            <div className="card-body">
              <h5 className="card-title text-info">
                <Loader type="Puff" color="#00BFFF" height={40} width={40} />
                Cargando...
              </h5>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
