import React from "react";
import { useSelector } from "react-redux";
import {
  stringNumberWithDots,
  stringDateToFullDateWithHour,
} from "../../../../helpers/basicFunctions";

export const GroupHeader = () => {
  const group = useSelector((state) => state.group.groupHeaders);

  return (
    <>
      {/* Grupos a cargo */}
      <div className="col-xl-4 col-md-6 mb-4">
        <div className="card border-left-success shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                  Grupos a cargo
                </div>
                <div className="h5 mb-0 font-weight-bold text-gray-800">
                  {group.groups_on_charge}
                </div>
              </div>
              <div className="col-auto">
                <i className="fas fa-users fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Proxima caminata */}
      {/* <div className="col-xl-4 col-md-6 mb-4">
        <div className="card border-left-info shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                  Proxima caminata
                </div>
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                      {stringDateToFullDateWithHour(group.walks_date_ini)}
                    </div>
                    <div className="h5 mb-0 mr-3 font-weight-bold text-black-400">
                      Grupo {group.group_name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <i className="fas fa-walking fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Pasos totales caminados */}
      <div className="col-xl-4 col-md-6 mb-4">
        <div className="card border-left-primary shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                  Pasos totales Registrados
                </div>
                <div className="h5 mb-0 font-weight-bold text-gray-800">
                  {stringNumberWithDots(group.total_walks_by_sys_user)}
                </div>
              </div>
              <div className="col-auto">
                <i className="fas fa-shoe-prints fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
