import React, { useRef, useEffect } from "react";

// import { Alert } from "reactstrap";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import Loader from "react-loader-spinner";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { Events, scrollSpy, scroller } from "react-scroll";
import "animate.css";

//components|
import { HikerData } from "./components/HikerData";
import { HikerNewCheckHealth } from "./components/HikerNewCheckHealth";
import { HikerNewPhysicalCondition } from "./components/HikerNewPhysicalCondition";
import { HikerNewLifeQuality } from "./components/HikerNewLifeQuality";
import { HikerNewEntryMotivation } from "./components/HikerNewEntryMotivation";

export const NewHiker = () => {
  const history = useHistory();

  const hikerDataRef = useRef();
  const newCheckHealthRef = useRef();
  const newPhysicalConditionRef = useRef();
  const newLifeQualityRef = useRef();
  const newEntryMotivationRef = useRef();
  const [state, setState] = React.useState({
    loadingCreateUser: false,
    createIsSuccess: true,
  });

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [createIsSuccess, setCreateIsSuccess] = React.useState(false);

  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function (to, element) {
      console.log("end", arguments);
    });

    scrollSpy.update();
  }, []);
  const ScrollerTo = async (el) => {
    setState({ signError: true });
    scroller.scrollTo(el, {
      duration: 1000,
      delay: 0,
      smooth: true,
    });
    setTimeout(() => {
      setState({ signError: false });
    }, 1000);
  };
  const registerBtn = async () => {
    const hikerData = await hikerDataRef.current.fieldsHandler();
    if (!hikerData) {
      ScrollerTo("divHikerData");
      return;
    }
    openModal();
  };
  const openModal = async () => {
    setIsOpen(true);
    setCreateIsSuccess(false);
    setState({
      waitingMessage: "creando usuario",
    });
    const saveDatahiker = await hikerDataRef.current.saveData();

    let allowSaveSurveys = false;
    if (saveDatahiker.status === 200) {
      setCreateIsSuccess(true);
      setState({
        successMessage: saveDatahiker.data.message,
        passwordString: saveDatahiker.data.password,
      });
      allowSaveSurveys = true;
    } else {
      setCreateIsSuccess(true);
      return setState({
        errorCreateUser: true,
        errorString: `Error ${saveDatahiker.status} ${saveDatahiker.data.message}`,
      });
    }
    if (allowSaveSurveys) {
      if (newCheckHealthRef.current.fieldsHandler()) {
        const newCheckHealResponse = await newCheckHealthRef.current.saveData(
          saveDatahiker.data.hiker_user_id
        );
        console.log("newCheckHealResponse=========", newCheckHealResponse);
        if (newPhysicalConditionRef.current.fieldsHandler()) {
          await newPhysicalConditionRef.current.saveData(
            newCheckHealResponse.check_health_id
          );
        }
      }
      await newLifeQualityRef.current.saveData(
        saveDatahiker.data.hiker_user_id
      );
      if (newEntryMotivationRef.current.fieldsHandler()) {
        await newEntryMotivationRef.current.saveData(
          saveDatahiker.data.hiker_user_id
        );
      }
    }
    setTimeout(() => {
      setCreateIsSuccess(false);
      history.push("/user/home");
    }, 2000);
  };

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800">Nuevo Caminante</h1>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          contentLabel="Register Modal"
          className="Modal"
          overlayClassName="Overlay"
          ariaHideApp={false}
        >
          {!createIsSuccess ? (
            <div>
              <div className="card">
                <div className="card-header text-info"></div>
                <div className="card-body">
                  <h5 className="card-title text-info">
                    <Loader
                      type="Puff"
                      color="#00BFFF"
                      height={40}
                      width={40}
                      // timeout={3000} //3 secs
                    />
                    {state.waitingMessage}!
                  </h5>
                </div>
                {/* <h3> Su contraseña es {state.passwordString} </h3> */}
              </div>
            </div>
          ) : (
            <div>
              {state.errorCreateUser ? (
                <div className="card">
                  <div className="card-header text-danger">Advertencia!</div>
                  <div className="card-body">
                    <h5 className="card-title text-danger">Error</h5>
                    <p className="card-text"> {state.errorString}</p>
                    <div className="btn btn-primary" onClick={closeModal}>
                      Volver
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card">
                  <div className="card-header text-info"></div>
                  <div className="card-body text-success">
                    <h5 className="card-title text-success">
                      {state.successMessage}!
                    </h5>
                    <IoIosCheckmarkCircleOutline style={{ fontSize: 50 }} />
                    {/* <div className="btn btn-primary" onClick={closeModal}>
                      Volver
                    </div> */}
                  </div>
                  {/* <h3> Su contraseña es {state.passwordString} </h3> */}
                </div>
              )}
            </div>
          )}
        </Modal>
        <div className="row " id="divHikerData">
          <HikerData ref={hikerDataRef} />
        </div>
        <div className="row" name="divHikerNewCheckHealth">
          <HikerNewCheckHealth ref={newCheckHealthRef} />
        </div>
        <div className="row" name="divHikerNewPhysicalCondition">
          <HikerNewPhysicalCondition ref={newPhysicalConditionRef} />
        </div>
        <div className="row" name="divHikerNewLifeQuality">
          <HikerNewLifeQuality ref={newLifeQualityRef} />
        </div>
        <div className="row" name="divHikerNewEntryMotivation">
          <HikerNewEntryMotivation ref={newEntryMotivationRef} />
        </div>
        <div className="row float-right" style={{ height: 200 }}>
          <div className="col-md-12">
            <button
              onClick={registerBtn}
              className={
                !state.signError
                  ? `btn btn-primary mb-5`
                  : `btn btn-danger animate__animated animate__headShake`
              }
              type="submit"
            >
              Guardar cambios
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
