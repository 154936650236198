import md5 from "md5";
import { lazy } from "react";

const actionEvent = ({ type, payload }) => ({
  type,
  payload,
});

const lazyComponent = (name, importer) =>
  lazy(async () => {
    const component = await importer;
    return { default: component[name] };
  });

const encrypt = ({ username, password }) => {
  const pass = md5(password);
  const text = `${username}:${pass}`;

  return window.btoa(unescape(encodeURIComponent(text)));
};

const isDev = () =>
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test";

const formatDateToHTMLDateTimeLocal = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};
export { actionEvent, lazyComponent, encrypt, isDev, formatDateToHTMLDateTimeLocal };
