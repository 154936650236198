import React from 'react';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';

//Components
import { GroupHeader } from './components/GroupHeader';
import { EditProffesional } from './components/EditProffesional';
import { GroupInCharge } from './components/GroupInCharge';
import { GroupNextHikes } from './components/GroupNextHikes';

export const ProffesionalInfo = () => {
  const user = useSelector((state) => state.user.user);
  const group = useSelector((state) => state.group);
  const { loadingGroupHeaders, errorGroupHeaders } = group;
  const { loadingGroupNextHikes } = group;
  const { loading } = user;
  return (
    <>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
        </div>
        {/* Group Header */}
        <div className="row">
          {loadingGroupHeaders ? (
            <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              // timeout={3000} //3 secs
            />
          ) : errorGroupHeaders === null ? (
            <GroupHeader />
          ) : (
            'Error al cargar desde la base de datos'
          )}
        </div>
        {/* Ficha Profesional */}
        {loading ? (
          <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            // timeout={3000} //3 secs
          />
        ) : (
          <div className="row">
            <EditProffesional />
          </div>
        )}

        {/* Grupos a cargo */}
        <h2 className="h4 mb-0 text-gray-800 mb-2">Grupos a cargo:</h2>
        <div className="row">
          <GroupInCharge />
        </div>
        {/* Proximas Caminatas */}
        {loadingGroupNextHikes ? (
          <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            // timeout={3000} //3 secs
          />
        ) : (
          <>
             {/* <GroupNextHikes /> */}
          </>
        )}
      </div>
    </>
  );
};
