import axios from "axios";
import dotenv from "dotenv";

dotenv.config();

const base_url = process.env.REACT_APP_AXIOS_LOGIN;

export const axiosLogin = axios.create({
  // baseURL: process.env.REACT_APP_AXIOS_LOGIN,
  baseURL: base_url,
});

export const axiosUser = axios.create({
  baseURL: `${base_url}sys-user/`,
});

export const axiosHiker = axios.create({
  baseURL: `${base_url}hiker-user/`,
});

export const axiosGroup = axios.create({
  baseURL: `${base_url}group/`,
});

export const axiosNews = axios.create({
  baseURL: `${base_url}news/`,
});

export const axiosWalks = axios.create({
  baseURL: `${base_url}walks/`,
})

export const axiosCards = axios.create({
  baseURL: `${base_url}cards/`,
})

export const axiosCalendar = axios.create({
  baseURL: `${base_url}calendar/`,
})

export const axiosReports = axios.create({
  baseURL: `${base_url}reports/`,
})

export const profileUrl = `${base_url}api/public/profiles`;
