import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Alert } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  validateEmail,
  getPhone,
  getAge,
} from "../../../../helpers/basicFunctions";

import { updateKineService } from "../../../../services/users.service";

export const EditProffesional = () => {
  const user = useSelector((state) => state.user.userToEdit);
  const phone = getPhone(user.sys_user_phone);
  const edad = getAge(user.sys_user_born_date);
  const email = user.sys_user_email;

  const active = user.sys_user_active === 1 ? "Si" : "No";
  const [mail, setMail] = useState(email);
  const [telefono, setTelefono] = useState(phone);
  const [habilitado, setHabilitado] = useState(active);
  const [updateAlert, setUpdateAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");

  const formatEmail = (e) => {
    if (!validateEmail(e.target.value)) {
      setMail("");
      setMessageAlert("Debe ingresar un correo valido");
      setAlert(true);
    }
    setTimeout(() => {
      setAlert(false);
    }, 10000);
  };
  const submitHandler = () => {
    const habilitadoInt = habilitado === "Si" ? 1 : 0;

    let data = {
      sys_user_rut: user.sys_user_rut,
      sys_user_email: mail,
      sys_user_phone: telefono,
      sys_user_active: habilitadoInt,
    };
    if (mail === "") {
      setMessageAlert("Al menos debe rellenar el correo!");
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 10000);
    } else {
      updateKineService(data)
        .then((res) => {
          setUpdateAlert(true);
          setTimeout(() => {
            setUpdateAlert(false);
          }, 3000);
        })
        .catch((err) => {
          if (err.response.data.message) {
            setErrorMessage(err.response.data.message);
          } else {
            setErrorMessage("Hubo un error al actualizar los datos!");
          }
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 3000);
        });
    }
  };
  const onConfirm = () => {
    setUpdateAlert(false);
    setErrorAlert(false);
  };

  const onConfirmError = () => {
    setUpdateAlert(false);
    setErrorAlert(false);
  };
  return (
    <>
      {errorAlert ? (
        <SweetAlert
          warning
          title="Ocurrio un problema!"
          onConfirm={onConfirmError}
          // onCancel={this.onCancel}
          timeout={3000}
        >
          {errorMessage}
        </SweetAlert>
      ) : null}
      {updateAlert ? (
        <SweetAlert
          success
          title="Actualización exitosa!"
          onConfirm={onConfirm}
          // onCancel={this.onCancel}
          timeout={3000}
        >
          Sus datos se han actualizado correctamente!
        </SweetAlert>
      ) : null}
      {alert ? (
        <Alert
          role="alert"
          className="position-absolute center alertCloseSesion mt-2"
          color="danger"
        >
          {messageAlert}
        </Alert>
      ) : null}
      <div className="col-lg-12">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">
              Ficha Profesional
            </h6>
          </div>
          <div className="card-body">
            <form>
              <div className="row">
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">Nombre</label>
                    <input
                      className="form-control form-control-solid"
                      id="nombreInput"
                      type="text"
                      placeholder={user.sys_user_name}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">Apellido</label>
                    <input
                      className="form-control form-control-solid"
                      id="apellidoInput"
                      type="text"
                      placeholder={user.sys_user_lastname}
                      disabled
                    />
                    <small id="nameInput" className="form-text text-muted">
                      Alerta: Debe ingresar apellido
                    </small>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">Edad</label>
                    <input
                      className="form-control form-control-solid"
                      id="edadInput"
                      type="number"
                      placeholder={edad}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">Correo</label>
                    <input
                      className="form-control form-control-solid"
                      id="CorreoInput"
                      type="text"
                      value={mail}
                      onChange={(e) => setMail(e.target.value)}
                      onBlur={(e) => formatEmail(e)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">Télefono</label>
                    <div>
                      <input
                        className="form-control form-control-phone-ext"
                        id="TelefonoExtInput"
                        type="number"
                        placeholder="+569"
                        disabled
                      />
                      <input
                        className="form-control form-control-phone-number"
                        id="TelefonoNumInput"
                        type="number"
                        value={telefono}
                        onChange={(e) => setTelefono(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1">Habilitado</label>
                    <select
                      name=""
                      id=""
                      value={habilitado}
                      className="form-control"
                      onChange={(e) => setHabilitado(e.target.value)}
                    >
                      <option default value="Si">
                        Si
                      </option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row float-right mx-auto">
                <div className="col-md-12">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={submitHandler}
                  >
                    Editar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
