import React, { forwardRef, useState, useImperativeHandle } from "react";
import { postNewCheckHealth } from "../../../../services/hikerUser.service";
import { formatDate } from "../../../../helpers/dateHelper";

export const HikerNewCheckHealth = forwardRef((props, ref) => {
  const { data, option } = props;
  const [alert, setAlert] = useState(false);

  const [quest_1, setquest_1] = useState(data?.quest_1 || 0);
  const [quest_2, setquest_2] = useState(data?.quest_2 || 0);
  const [quest_3, setquest_3] = useState(data?.quest_3 || 0);
  const [quest_4, setquest_4] = useState(data?.quest_4 || 0);
  const [quest_5, setquest_5] = useState(data?.quest_5 || 0);
  const [quest_6, setquest_6] = useState(data?.quest_6 || 0);
  const [quest_7, setquest_7] = useState(data?.quest_7 || 0);
  const [quest_8, setquest_8] = useState(data?.quest_8 || 0);
  const [quest_9_doc_auth, setquest_9_doc_auth] = useState(
    data?.quest_9_doc_auth || 0
  );
  const [quest_4_comment, setquest_4_comment] = useState(
    data?.quest_4_comment || ""
  );
  const [quest_9_doc_name, setquest_9_doc_name] = useState(
    data?.quest_9_doc_name || ""
  );
  const [quest_9_date_ath, setquest_9_date_ath] = useState(
    data?.quest_9_date_ath || ""
  );

  useImperativeHandle(ref, () => ({
    fieldsHandler() {
      console.log("quest_9_date_ath", quest_9_date_ath);
      if (!quest_9_date_ath) {
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 10000);
        return false;
      } else {
        return true;
      }
    },
    async saveData(hiker_user_id) {
      const data = {
        quest_1: quest_1,
        quest_2: quest_2,
        quest_3: quest_3,
        quest_4: quest_4,
        quest_4_comment: quest_4_comment,
        quest_5: quest_5,
        quest_6: quest_6,
        quest_7: quest_7,
        quest_8: quest_8,
        quest_9_doc_auth: quest_9_doc_auth,
        quest_9_doc_name: quest_9_doc_name,
        quest_9_date_ath: new Date(formatDate(quest_9_date_ath)),
        health_comments: "",
        hiker_user_user_id: hiker_user_id,
      };
      try {
        const newCheckHealth = await postNewCheckHealth(data);
        return newCheckHealth.data;
      } catch (error) {
        return false;
      }
    },
  }));
  return (
    <>
      {/* Chequeo salud compatible  */}
      <div className="col-lg-12 text-left">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">
              Chequeo salud compatible
            </h6>
          </div>
          <div className="card-body">
            {alert ? (
              <div className="alert alert-danger" role="alert">
                Debe rellenar todos los campos
              </div>
            ) : null}

            <li>
              ¿Le duele el pecho estando en reposo?
              <div className="form-check">
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-salud-check-preg1"
                    id="radio-ch-salud-preg1-1"
                    value="check-salud-preg1-option1"
                    onClick={(e) => setquest_1(1)}
                    checked={quest_1 === 1 ? true : false}
                    readOnly
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg1-1"
                  >
                    Si
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-salud-check-preg1"
                    id="radio-ch-salud-preg1-2"
                    value="check-salud-preg1-option2"
                    onChange={() => setquest_1(0)}
                    checked={quest_1 === 0 ? true : false}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg1-2"
                  >
                    No
                  </label>
                </div>
              </div>
            </li>
            <li>
              ¿Ha tenido un infarto en los últimos seis meses?
              <div className="form-check">
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-salud-check-preg2"
                    id="radio-ch-salud-preg2-1"
                    value="check-salud-preg2-option1"
                    onClick={(e) => setquest_2(1)}
                    checked={quest_2 === 1 ? true : false}
                    readOnly
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg2-1"
                  >
                    Si
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-salud-check-preg2"
                    id="radio-ch-salud-preg2-2"
                    value="check-salud-preg2-option2"
                    onChange={() => setquest_2(0)}
                    checked={quest_2 === 0 ? true : false}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg2-2"
                  >
                    No
                  </label>
                </div>
              </div>
            </li>
            <li>
              ¿Se ha desmayado o ha perdido la conciencia en los últimos seis
              meses?
              <div className="form-check">
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-check-preg3"
                    id="radio-ch-salud-preg3-1"
                    value="check-salud-preg3-option1"
                    onClick={(e) => setquest_3(1)}
                    checked={quest_3 === 1 ? true : false}
                    readOnly
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg3-1"
                  >
                    Si
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-check-preg3"
                    id="radio-ch-salud-preg3-2"
                    value="check-salud-preg3-option2"
                    onChange={() => setquest_3(0)}
                    checked={quest_3 === 0 ? true : false}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg3-2"
                  >
                    No
                  </label>
                </div>
              </div>
            </li>
            <li>
              ¿Se ha caído en los últimos 12 meses?
              <div className="form-check">
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-check-preg4"
                    id="radio-ch-salud-preg4-1"
                    value="check-salud-preg4-option1"
                    onClick={(e) => setquest_4(1)}
                    checked={quest_4 === 1 ? true : false}
                    readOnly
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg4-1"
                  >
                    Si
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-check-preg4"
                    id="radio-ch-salud-preg4-2"
                    value="check-salud-preg4-option2"
                    onChange={() => setquest_4(0)}
                    checked={quest_4 === 0 ? true : false}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg4-2"
                  >
                    No
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1">
                  Causa de caida:
                </label>
                <input
                  className="form-control form-control-solid w-100"
                  id="exampleFormControlInput1"
                  type="text"
                  placeholder=""
                  value={quest_4 === 0 ? "" : quest_4_comment}
                  onChange={(e) => setquest_4_comment(e.target.value)}
                  disabled={
                    option?.disable ? true : quest_4 === 1 ? false : true
                  }
                />
              </div>
            </li>
            <li>
              ¿Se ha quebrado algún hueso de la pierna en el último mes?
              <div className="form-check">
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-check-preg5"
                    id="radio-ch-salud-preg5-1"
                    value="check-salud-preg5-option1"
                    onClick={(e) => setquest_5(1)}
                    checked={quest_5 === 1 ? true : false}
                    readOnly
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg5-1"
                  >
                    Si
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-check-preg5"
                    id="radio-ch-salud-preg5-2"
                    value="check-salud-preg5-option2"
                    onChange={() => setquest_5(0)}
                    checked={quest_5 === 0 ? true : false}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg5-2"
                  >
                    No
                  </label>
                </div>
              </div>
            </li>
            <li>
              ¿Se queda sin aliento al realizar las tareas cotidianas en la
              casa, como vestirse?
              <div className="form-check">
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-check-preg6"
                    id="radio-ch-salud-preg6-1"
                    value="check-salud-preg6-option1"
                    onClick={(e) => setquest_6(1)}
                    checked={quest_6 === 1 ? true : false}
                    readOnly
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg6-1"
                  >
                    Si
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-check-preg6"
                    id="radio-ch-salud-preg6-2"
                    value="check-salud-preg6-option2"
                    onChange={() => setquest_6(0)}
                    checked={quest_6 === 0 ? true : false}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg6-2"
                  >
                    No
                  </label>
                </div>
              </div>
            </li>
            <li>
              ¿Padece alguna enfermedad articular o muscular que le impida hacer
              ejercicio físico?
              <div className="form-check">
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-check-preg7"
                    id="radio-ch-salud-preg7-1"
                    value="check-salud-preg7-option1"
                    onClick={(e) => setquest_7(1)}
                    checked={quest_7 === 1 ? true : false}
                    readOnly
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg7-1"
                  >
                    Si
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-check-preg7"
                    id="radio-ch-salud-preg7-2"
                    value="check-salud-preg7-option2"
                    onChange={() => setquest_7(0)}
                    checked={quest_7 === 0 ? true : false}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg7-2"
                  >
                    No
                  </label>
                </div>
              </div>
            </li>
            <li>
              ¿Le ha dicho algún profesional de la salud que limite el ejercicio
              físico?
              <div className="form-check">
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-check-preg8"
                    id="radio-ch-salud-preg8-1"
                    value="check-salud-preg8-option1"
                    onClick={(e) => setquest_8(1)}
                    checked={quest_8 === 1 ? true : false}
                    readOnly
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg8-1"
                  >
                    Si
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-check-preg8"
                    id="radio-ch-salud-preg8-2"
                    value="check-salud-preg8-option2"
                    onChange={() => setquest_8(0)}
                    checked={quest_8 === 0 ? true : false}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg8-2"
                  >
                    No
                  </label>
                </div>
              </div>
            </li>
            <li>
              ¿Tiene autorización médica para realizar la actividad?
              <div className="form-check">
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-check-preg9"
                    id="radio-ch-salud-preg9-1"
                    value="check-salud-preg9-option1"
                    onClick={(e) => setquest_9_doc_auth(1)}
                    checked={quest_9_doc_auth === 1 ? true : false}
                    readOnly
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg9-1"
                  >
                    Si
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    disabled={option?.disable}
                    className="form-check-input"
                    type="radio"
                    name="radio-check-preg9"
                    id="radio-ch-salud-preg9-2"
                    value="check-salud-preg9-option2"
                    onChange={() => setquest_9_doc_auth(0)}
                    checked={quest_9_doc_auth === 0 ? true : false}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radio-ch-salud-preg9-2"
                  >
                    No
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1">
                  Nombre médico autorizador:
                </label>
                <input
                  className="form-control form-control-solid w-100"
                  id="exampleFormControlInput1"
                  type="text"
                  placeholder=""
                  value={quest_9_doc_auth === 0 ? "" : quest_9_doc_name}
                  onChange={(e) => setquest_9_doc_name(e.target.value)}
                  disabled={
                    option?.disable
                      ? true
                      : quest_9_doc_auth === 1
                      ? false
                      : true
                  }
                />
              </div>
              <div className="mb-3 was-validated">
                <label htmlFor="exampleFormControlInput1">
                  Fecha autorización:
                </label>
                <input
                  disabled={option?.disable}
                  className={`form-control form-control-solid w-50 ${
                    option?.disable ? "" : "is-invalid"
                  }`}
                  id="exampleFormControlInput1"
                  type="date"
                  placeholder=""
                  value={quest_9_date_ath}
                  onChange={(e) => setquest_9_date_ath(e.target.value)}
                />
              </div>
            </li>
            <p>
              *En caso de contestar afirmativo en alguna de las preguntas, el
              participante deberá presentar autorización médica.
            </p>
          </div>
        </div>
      </div>
    </>
  );
});
