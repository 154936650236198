import React, { forwardRef, useState, useImperativeHandle } from "react";
import { postNewEntryMotivation } from "../../../../services/hikerUser.service";

export const HikerNewEntryMotivation = forwardRef((props, ref) => {
  const { data, option } = props;
  console.log(" ola", data);
  const [check_qs_1, setcheck_qs_1] = useState(data?.check_qs_1 || 0);
  const [check_qs_2, setcheck_qs_2] = useState(data?.check_qs_2 || 0);
  const [check_qs_3, setcheck_qs_3] = useState(data?.check_qs_3 || 0);
  const [check_qs_4, setcheck_qs_4] = useState(data?.check_qs_4 || 0);
  const [check_qs_5, setcheck_qs_5] = useState(data?.check_qs_5 || 0);
  const [check_qs_6, setcheck_qs_6] = useState(data?.check_qs_6 || 0);
  const [check_qs_7, setcheck_qs_7] = useState(data?.check_qs_7 || 0);
  const [check_qs_7_comment, setcheck_qs_7_comment] = useState(
    data?.check_qs_7_comment || ""
  );

  const [alert, setAlert] = useState(false);
  useImperativeHandle(ref, () => ({
    fieldsHandler() {
      if (
        check_qs_1 ||
        check_qs_2 ||
        check_qs_3 ||
        check_qs_4 ||
        check_qs_5 ||
        check_qs_6 ||
        check_qs_7 ||
        check_qs_7_comment
      ) {
        return true;
      }
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 10000);
      return false;
    },
    async saveData(hiker_user_id) {
      const data = {
        check_qs_1: check_qs_1,
        check_qs_2: check_qs_2,
        check_qs_3: check_qs_3,
        check_qs_4: check_qs_4,
        check_qs_5: check_qs_5,
        check_qs_6: check_qs_6,
        check_qs_7: check_qs_7,
        check_qs_7_comment: check_qs_7_comment,
        hiker_user_user_id: hiker_user_id,
      };
      try {
        const response = await postNewEntryMotivation(data);
        return response;
      } catch (error) {
        return error;
      }
    },
  }));
  return (
    <>
      {/*  Motivación de entrada */}
      <div className="col-lg-12">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">
              Motivación de entrada
            </h6>
          </div>
          <div className="card-body">
            {alert ? (
              <div className="alert alert-danger" role="alert">
                Debe rellenar todos los campos
              </div>
            ) : null}
            <p className="font-italic">
              ¿Cuál de las siguientes razones me motivan para empezar este
              taller?
            </p>
            <div className="form-check">
              <input
                disabled={option?.disable}
                className="form-check-input"
                id="flexCheckDefault"
                type="checkbox"
                value=""
                onChange={(e) => setcheck_qs_1(check_qs_1 === 1 ? 0 : 1)}
                checked={check_qs_1 === 1 ? true : false}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Para estar en mejor forma física
              </label>
            </div>
            <div className="form-check">
              <input
                disabled={option?.disable}
                className="form-check-input"
                id="flexCheckDefault"
                type="checkbox"
                value=""
                onChange={(e) => setcheck_qs_2(check_qs_2 === 1 ? 0 : 1)}
                checked={check_qs_2 === 1 ? true : false}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Para para prevenir futuros problemas de salud
              </label>
            </div>
            <div className="form-check">
              <input
                disabled={option?.disable}
                className="form-check-input"
                id="flexCheckDefault"
                type="checkbox"
                value=""
                onChange={(e) => setcheck_qs_3(check_qs_3 === 1 ? 0 : 1)}
                checked={check_qs_3 === 1 ? true : false}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Para reducir el stress
              </label>
            </div>
            <div className="form-check">
              <input
                disabled={option?.disable}
                className="form-check-input"
                id="flexCheckDefault"
                type="checkbox"
                value=""
                onChange={(e) => setcheck_qs_4(check_qs_4 === 1 ? 0 : 1)}
                checked={check_qs_4 === 1 ? true : false}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Para manejar una condición crónica como cardiopatía o diabetes
              </label>
            </div>
            <div className="form-check">
              <input
                disabled={option?.disable}
                className="form-check-input"
                id="flexCheckDefault"
                type="checkbox"
                value=""
                onChange={(e) => setcheck_qs_5(check_qs_5 === 1 ? 0 : 1)}
                checked={check_qs_5 === 1 ? true : false}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Para compartir mas tiempo con amigos
              </label>
            </div>
            <div className="form-check">
              <input
                disabled={option?.disable}
                className="form-check-input"
                id="flexCheckDefault"
                type="checkbox"
                value=""
                onChange={(e) => setcheck_qs_6(check_qs_6 === 1 ? 0 : 1)}
                checked={check_qs_6 === 1 ? true : false}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Para conocer gente nueva
              </label>
            </div>
            <div className="form-check">
              <input
                disabled={option?.disable}
                className="form-check-input"
                id="flexCheckDefault"
                type="checkbox"
                value=""
                onChange={(e) => setcheck_qs_7(check_qs_7 === 1 ? 0 : 1)}
                checked={check_qs_7 === 1 ? true : false}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Otra razón{" "}
                <input
                  type="text"
                  value={check_qs_7 === 0 ? "" : check_qs_7_comment}
                  onChange={(e) => setcheck_qs_7_comment(e.target.value)}
                  disabled={
                    option?.disable
                      ? option?.disable
                      : check_qs_7 === 1
                      ? false
                      : true
                  }
                />
              </label>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
});
