import React, { useMemo, useState, useCallback } from "react";
import Datatable from "react-data-table-component";
import { FilterComponent } from './FilterComponent';

export const DataTableComponent = props => {


  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  var filteredItems = useState(props.data);

    filteredItems = props.data.filter(
      item =>
        JSON.stringify(item)
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) !== -1
    );

   const subHeaderComponentMemo = useMemo(() => {

     const handleClear = () => {

         setResetPaginationToggle(!resetPaginationToggle);
         setFilterText("");
       
     };

     return (
       <FilterComponent
         onFilter={e => setFilterText(e.target.value)}
         onClear={handleClear}
         filterText={filterText}
       />
     );
   }, [resetPaginationToggle]);

  return (
    <Datatable
      columns={props.columns}
      data={filteredItems}
      selectableRows={props.selectableRows || false}
      striped
      onSelectedRowsChange={props.handleRowSelected}
      noDataComponent={"No se encontraron resultados"}
      paginationComponentOptions={{rowsPerPageText: 'Filas por página:', rangeSeparatorText: 'de'}}
      pagination
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
    />
  );
};

